import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

export function useGetSlaText() {
  const intl = useIntl();

  return (hours: number) => getSlaText(intl, hours);
}

export function getSlaText(
  intl: IntlShape,
  hours: Maybe<number>,
): Maybe<string> {
  if (hours === null || hours === undefined) {
    return null;
  }

  const isPast = hours <= 0;
  const absHours = Math.abs(hours);
  const days = Math.floor(absHours / 24);

  if (!isPast) {
    if (days > 1) {
      return intl.formatMessage({ defaultMessage: '{days} days' }, { days });
    }

    if (days === 1) {
      return intl.formatMessage({ defaultMessage: 'Tomorrow' });
    }

    if (days <= 0) {
      return intl.formatMessage({ defaultMessage: 'Today' });
    }
  }

  if (hours === 0) {
    return intl.formatMessage({ defaultMessage: 'Now' });
  }

  if (days <= 0) {
    return intl.formatMessage(
      {
        defaultMessage:
          '{hours} {hours, plural, one {hour} other {hours}} past',
      },
      { hours: absHours },
    );
  }

  return intl.formatMessage(
    { defaultMessage: '{days} {days, plural, one {day} other {days}} past' },
    { days },
  );
}
