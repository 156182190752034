{{#unless flags.carePlanVisitFlowImprovements}}
{{> header val="Care Gaps Identified:" }}
{{> free_text_space }}
{{/unless}}

{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

<p>Pt is/is not eligible for HTN/T2D (scale only or glucometer)/CHF RPM. Patient agrees/disagrees to participate in RPM. Will pend RPM order to provider</p>
{{> free_text_space }}

{{#ifContains programTypes "RPM"}}
{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>
{{/ifContains}}

{{> subheader val="Care Plan Communication preference" }}
{{> free_text_space }}

{{> subheader val="Patient Goal" }}
{{> free_text_space }}
