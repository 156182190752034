import type { Dispatch, SetStateAction } from 'react';

import { createCtx } from '@/shared/hooks/createCtx';

import type { FilterValues } from '../../../tabs/EplsTab/Filters';
import type { ProspectivePatientsFilter } from '../../enrollment.queries';

export const [usePetFiltersContext, PetFiltersContextProvider] = createCtx<{
  eligibleCandidatesTabFilters: Nullable<ProspectivePatientsFilter>;
  setEligibleCandidatesTabFilters: Dispatch<
    SetStateAction<Nullable<ProspectivePatientsFilter>>
  >;
  eplsTabFilters: FilterValues;
  setEplsTabFilters: Dispatch<SetStateAction<FilterValues>>;
  pendingEnrolmentsTabFilters: Nullable<ProspectivePatientsFilter>;
  setPendingEnrolmentsTabFilters: Dispatch<
    SetStateAction<Nullable<ProspectivePatientsFilter>>
  >;
  allPatientsTabFilters: Nullable<ProspectivePatientsFilter>;
  setAllPatientsTabFilters: Dispatch<
    SetStateAction<Nullable<ProspectivePatientsFilter>>
  >;
}>('PetFiltersContext');
