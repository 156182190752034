import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useCcmConditionOptions } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import ArrowUpRight from '@/shared/assets/svgs/arrow-up-right.svg?react';
import { Form } from '@/shared/common/Form';
import { RatingScaleField } from '@/shared/common/RatingScaleField';
import { useFlags } from '@/shared/hooks';
import { convertCcmConditionToPmsCondition } from '@/shared/patient/conditions.utils';
import { Link } from '@/shared/tempo/atom/Link';
import { color } from '@/shared/tempo/theme';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import type { RouteParam } from '@/shared/types/route.types';

import { RemovableRow } from '../../RemovableRow';
import { CCM_CONDITION_RESOURCES, useCcmConditionI18n } from '../../conditions';
import type { FormFields } from '../../formConfig';
import { CCM_PROGNOSIS_OPTIONS, useCcmPrognosisI18n } from '../../prognosis';
import {
  careGuide,
  conditionSelect,
  inlineCareGuide,
  ratingField,
  selfManagementGoalsTitle,
  treatmentText,
} from './GoalsAndInterventions.css';
import { PrognosisAndPlan } from './PrognosisAndPlan';
import { usePatientConditionData } from './hooks/usePatientConditionData.hook';
import { getConditionPrognosis } from './prognosisUtils';

type Props = {
  index: number;
  onRemove: () => void;
  manualAutoSave: () => void;
  form: UseFormReturn<FormFields>;
  isRemovable: boolean;
  numOfConditions: number;
};

export function ConditionSection({
  index,
  form,
  isRemovable,
  onRemove,
  numOfConditions,
  manualAutoSave,
}: Props) {
  const intl = useIntl();
  const { carePlanConditionImprovements } = useFlags();
  const ccmConditionI18n = useCcmConditionI18n();
  const prognosisI18n = useCcmPrognosisI18n();
  const conditionOptions = useCcmConditionOptions();
  const selectedCondition = form.watch(
    `goalsAndInterventions.${index}.condition`,
  );
  const treatmentGoal = form.watch(
    `goalsAndInterventions.${index}.treatmentGoal`,
  );

  const { patientId }: RouteParam = useParams();
  const patientConditionData = usePatientConditionData(patientId);

  function triggerPopulatedConditionValidations() {
    for (let i = 0; i < numOfConditions; i++) {
      if (form.getValues(`goalsAndInterventions.${i}.condition`)) {
        form.trigger(`goalsAndInterventions.${i}.condition`);
      }
    }
  }

  const conditionLabel = intl.formatMessage(
    {
      defaultMessage: 'Condition {count}',
    },
    { count: index + 1 },
  );

  return (
    <>
      <RemovableRow
        onChange={() => manualAutoSave()}
        onRemove={() => {
          onRemove();
          // Trigger validation for uniqueness check
          triggerPopulatedConditionValidations();
        }}
        hasError={
          !!form.formState.errors.goalsAndInterventions?.[index]?.condition
        }
        enabled={isRemovable}
      >
        <Form.DeprecatedSelect
          selectClass={conditionSelect}
          required
          size={11}
          onChange={(condition: CcmCondition) => {
            if (condition) {
              form.setValue(
                `goalsAndInterventions.${index}.treatmentGoal`,
                CCM_CONDITION_RESOURCES[condition].goal,
              );

              const defaultPrognosis = getConditionPrognosis(
                convertCcmConditionToPmsCondition(condition),
                patientConditionData.data,
              );
              form.setValue(
                `goalsAndInterventions.${index}.prognosis`,
                defaultPrognosis,
              );

              // Trigger validation for uniqueness check
              triggerPopulatedConditionValidations();
            }
          }}
          label={conditionLabel}
          name={`goalsAndInterventions.${index}.condition`}
        >
          <option disabled selected value={undefined}>
            {intl.formatMessage({ defaultMessage: 'Select one' })}
          </option>
          {conditionOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.DeprecatedSelect>
      </RemovableRow>
      {!carePlanConditionImprovements && selectedCondition && (
        <Form.GridItem size={11} className={careGuide.container}>
          <Link.Native
            className={careGuide.link}
            href={CCM_CONDITION_RESOURCES[selectedCondition].link}
            target="_blank"
          >
            {intl.formatMessage(
              { defaultMessage: '{condition} Care Guide' },
              { condition: ccmConditionI18n[selectedCondition] },
            )}
            <ArrowUpRight fill={color.Theme.Light.Primary} />
          </Link.Native>
        </Form.GridItem>
      )}
      {/* This is moved into PrognosisAndPlan if carePlanConditionImprovements is true */}
      {!carePlanConditionImprovements && (
        <Form.Row>
          <Form.DeprecatedSelect
            required
            size={11}
            label={intl.formatMessage({
              defaultMessage: 'Prognosis',
            })}
            name={`goalsAndInterventions.${index}.prognosis`}
          >
            <option disabled selected value={undefined}>
              {intl.formatMessage({ defaultMessage: 'Select one' })}
            </option>
            {CCM_PROGNOSIS_OPTIONS.map((prognosis) => (
              <option key={prognosis} value={prognosis}>
                {prognosisI18n[prognosis]}
              </option>
            ))}
          </Form.DeprecatedSelect>
        </Form.Row>
      )}
      {carePlanConditionImprovements ? (
        <div className={treatmentText}>
          <FormattedMessage
            defaultMessage="Treatment Goal: {treatmentGoal}."
            values={{ treatmentGoal }}
          />
          {carePlanConditionImprovements && selectedCondition && (
            <Link.Native
              className={inlineCareGuide.link}
              href={CCM_CONDITION_RESOURCES[selectedCondition].link}
              target="_blank"
            >
              {intl.formatMessage(
                { defaultMessage: 'See {condition} Care Guide' },
                { condition: ccmConditionI18n[selectedCondition] },
              )}
              <ArrowUpRight
                fill={color.Theme.Light.Primary}
                className={inlineCareGuide.icon}
              />
            </Link.Native>
          )}
        </div>
      ) : (
        <Form.Row>
          <Form.TextField
            required
            size={11}
            label={intl.formatMessage({
              defaultMessage: 'Treatment Goal',
            })}
            name={`goalsAndInterventions.${index}.treatmentGoal`}
          />
        </Form.Row>
      )}

      {carePlanConditionImprovements && selectedCondition ? (
        <PrognosisAndPlan index={index} condition={selectedCondition} />
      ) : (
        <>
          <div className={selfManagementGoalsTitle}>
            <FormattedMessage defaultMessage="Self-Management Goals" />
          </div>
          <Form.Row>
            <Form.TextField
              required
              size={11}
              label={intl.formatMessage({
                defaultMessage: 'Patient self-defined health goal',
              })}
              name={`goalsAndInterventions.${index}.healthGoal`}
            />
          </Form.Row>
          <Form.Row>
            <Form.TextArea
              required
              size={11}
              rows={3}
              label={intl.formatMessage({
                defaultMessage: 'Patient self-defined action steps',
              })}
              name={`goalsAndInterventions.${index}.actionSteps`}
            />
          </Form.Row>
          <Form.Row>
            <RatingScaleField
              classes={ratingField}
              size={11}
              label={intl.formatMessage({
                defaultMessage:
                  'On a scale of 1 to 10, how sure are you that you can follow this action plan?',
              })}
              help={intl.formatMessage({
                defaultMessage:
                  'If confidence is less than 7, explore ways to revise the plan so the patient feels more confident.',
              })}
              name={`goalsAndInterventions.${index}.confidence`}
            />
          </Form.Row>
          <Form.Row>
            <Form.TextArea
              required
              size={11}
              rows={3}
              label={intl.formatMessage({
                defaultMessage: 'Coordination of Care/Resources',
              })}
              name={`goalsAndInterventions.${index}.coordination`}
            />
          </Form.Row>
        </>
      )}
    </>
  );
}
