import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { Condition } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { convertToCcmCondition } from '@/shared/patient/conditions.utils';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import type { GoalsAndInterventions } from '../../formConfig';
import type { usePatientConditionData } from './hooks/usePatientConditionData.hook';

const HighSeverityConditions: Condition[] = [
  Condition.HEART_FAILURE,
  Condition.ISCHEMIC_HEART_DISEASE,
  Condition.CHRONIC_KIDNEY_DISEASE,
  Condition.COPD,
];

const HighSeverityCcmConditions: CcmCondition[] = HighSeverityConditions.map(
  (c) => convertToCcmCondition(c),
).filter((c): c is CcmCondition => c !== null);

const RestOfConditions: Condition[] = [
  Condition.TYPE_2_DIABETES,
  Condition.HYPERTENSION,
  Condition.AFIB_AND_AFL,
  Condition.PERIPHERAL_ARTERY_DISEASE,
  Condition.MORBID_OBESITY,
  Condition.OBSTRUCTIVE_SLEEP_APNEA,

  // Lower severity -- not treating these any differently right now
  Condition.ASTHMA,
  Condition.OSTEOARTHRITIS,
  Condition.HYPERLIPIDEMIA,
  Condition.HYPOTHYROIDISM,
];

const OrderedConditions = [...HighSeverityConditions, ...RestOfConditions];

export type PatientConditionData = ReturnType<
  typeof usePatientConditionData
>['data'];

export function getConditionPrognosis(
  condition: Condition,
  {
    a1c,
    avgDiastolic,
    avgSystolic,
    totalCholesterol,
    bmi,
  }: PatientConditionData,
  defaultPrognosis = CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED,
) {
  switch (condition) {
    case Condition.TYPE_2_DIABETES:
      return a1c && a1c < 7 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
    case Condition.HYPERTENSION:
      return avgSystolic &&
        avgSystolic < 130 &&
        avgDiastolic &&
        avgDiastolic < 80
        ? CCMPrognosis.GOOD
        : CCMPrognosis.FAIR;
    case Condition.HYPERLIPIDEMIA:
      return totalCholesterol && totalCholesterol < 200
        ? CCMPrognosis.GOOD
        : CCMPrognosis.FAIR;
    case Condition.MORBID_OBESITY:
      return bmi && bmi < 25 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
    default:
      return defaultPrognosis;
  }
}

export function sortConditions(
  conditions: {
    condition: Condition;
    prognosis: CCMPrognosis;
  }[],
) {
  return conditions.sort((a, b) => {
    // High severity conditions first
    if (
      HighSeverityConditions.includes(a.condition) &&
      !HighSeverityConditions.includes(b.condition)
    ) {
      return -1;
    }
    if (
      HighSeverityConditions.includes(b.condition) &&
      !HighSeverityConditions.includes(a.condition)
    ) {
      return 1;
    }

    // Then unstable conditions
    if (
      a.prognosis === CCMPrognosis.FAIR &&
      b.prognosis !== CCMPrognosis.FAIR
    ) {
      return -1;
    }
    if (
      b.prognosis === CCMPrognosis.FAIR &&
      a.prognosis !== CCMPrognosis.FAIR
    ) {
      return 1;
    }

    // Then sort by order of conditions
    return rankingSort(a.condition, b.condition);
  });
}

export function rankingSort(a: Condition, b: Condition) {
  return OrderedConditions.indexOf(a) - OrderedConditions.indexOf(b);
}

export function showGoalSetting(
  condition: CcmCondition,
  prognosis: CCMPrognosis,
  allConditions: GoalsAndInterventions[],
  index: number,
) {
  // Always show goal setting for high severity conditions
  if (HighSeverityCcmConditions.includes(condition)) {
    return true;
  }

  // Always show goal setting if prognosis is unstable
  if (prognosis === CCMPrognosis.FAIR) {
    return true;
  }

  // Show goal setting if <=1 condition with higher priority has goal setting
  const higherConditions = allConditions.slice(0, index);
  const higherConditionsWithGoalSetting = higherConditions.filter(
    (c) => c.acceptsCare,
  );
  return higherConditionsWithGoalSetting.length <= 1;
}
