{{! This field template is exactly the same amongst all Condition Programs (Except for CHF) as of right now }}
{{! Therefore it is stored in shared to be re-used }}
{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>

{{> subheader val="Problem List" }}
{{> problem_list }}
<br/>

{{#if flags.autopopulateEncounterLabs}}
{{> header val="Lab Results" }}
{{> latest_labs }}
<br/>
{{else}}
{{> subheader val="Labs" }}
{{> free_text_space }}
{{/if}}

{{> subheader val="Medication allergies" }}
{{> med_allergies }}
