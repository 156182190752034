import cx from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  ModalParams,
  ModalTypes,
} from '@/pages/adminPanel/patient-profile/RoutedModals';
import { EllipseIcon } from '@/shared/assets/icons/Ellipse';
import {
  type PatientDetails,
  PatientStatusEnum,
  type ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  getProgramStatusRecord,
  isParticipatingInProgramType,
} from '@/shared/patient/programs.utils';
import { getProgramTypeI18n } from '@/shared/patient/useProgramI18n';
import { Badge } from '@/shared/tempo/atom/Badge';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import {
  badge as badgeCss,
  dot,
  label,
  marginRightXxs,
} from './ProgramStatusTag.css';
import {
  getConditionBadges,
  getVariant,
  isSupportedProspectiveStatus,
  useTooltipContent,
} from './programStatusTag.utils';

type Props = {
  program: ProgramType;
  patientDetails: PatientDetails;
  className?: string;
  view?: 'default' | 'admin';
};

export function ProgramStatusTag({
  program,
  className,
  patientDetails,
  view = 'default',
}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const isAdminView = view === 'admin';
  const tooltip = useTooltipContent(program, patientDetails, isAdminView);
  const programStatus = getProgramStatusRecord(patientDetails, program)?.status;

  const showProspectiveProgram =
    isAdminView && isSupportedProspectiveStatus(programStatus);

  const { isParticipating, isDisenrolled, conditions } =
    isParticipatingInProgramType(patientDetails, program);

  if (programStatus === PatientStatusEnum.NOT_ELIGIBLE) {
    return null;
  }
  // We need this because:
  // 1. We don't support per program disenrollment yet and still derive disenrollment status
  // 2. We need to show the tag for prospective programs in admin view
  if (!isParticipating && !isDisenrolled && !showProspectiveProgram) {
    return null;
  }

  const programTypeI18n = getProgramTypeI18n(intl);
  const programName = programTypeI18n[program];
  const badges = getConditionBadges(intl, program, conditions);
  const variant = getVariant(program, patientDetails, isAdminView);

  // TODO: Remove this when showMultiPrograms is rolled out.
  // Allow status change for disenrollment/reenrollment
  const handleModalOpen = () => {
    if (patientDetails?.name) {
      const modalParams = new URLSearchParams({
        [ModalParams.Modal]: ModalTypes.StatusChange,
        [ModalParams.PatientId]: patientDetails.name,
      });
      history.push({ search: modalParams.toString() });
    }
  };
  const showNonProspectiveModal =
    programStatus === PatientStatusEnum.ENROLLED || isDisenrolled;

  return (
    <Tooltip size="small" content={tooltip}>
      <Tag
        className={showNonProspectiveModal ? className : undefined}
        variant={variant}
        onClick={showNonProspectiveModal ? handleModalOpen : undefined}
      >
        {variant !== 'default' && (
          <Tag.Icon>
            <EllipseIcon className={dot[variant]} />
          </Tag.Icon>
        )}
        <span
          className={cx(label[variant], {
            [marginRightXxs]: !!badges.length,
          })}
        >
          {programName}
        </span>
        {badges.map((badge) => (
          <Badge variant={variant} key={badge} className={badgeCss}>
            {badge}
          </Badge>
        ))}
      </Tag>
    </Tooltip>
  );
}
