/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufEmpty from "../../../../google/protobuf/empty.pb"
import * as GoogleProtobufField_mask from "../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "../../../../google/protobuf/wrappers.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum TaskTypeDefaultAssigneeType {
  DEFAULT_ASSIGNEE_TYPE_UNSPECIFIED = "DEFAULT_ASSIGNEE_TYPE_UNSPECIFIED",
  TEAM = "TEAM",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum TaskTaskTypeDeprecated {
  TASK_TYPE_DEPRECATED_UNSPECIFIED = "TASK_TYPE_DEPRECATED_UNSPECIFIED",
  ESCALATION = "ESCALATION",
  INDIRECT_REVIEW = "INDIRECT_REVIEW",
  OTHER = "OTHER",
  REMINDER = "REMINDER",
  TASK_TYPE_UNSPECIFIED = "TASK_TYPE_UNSPECIFIED",
}

export enum TaskState {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  OPENED = "OPENED",
  CLOSED = "CLOSED",
}

export enum TaskChangeNotificationResponseChangeType {
  CHANGE_TYPE_UNSPECIFIED = "CHANGE_TYPE_UNSPECIFIED",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export type Team = {
  name?: string
  teamName?: string
}

export type TaskType = {
  name?: string
  typeName?: string
  taskQueue?: TaskQueueRef
  isVisible?: boolean
  active?: boolean
  defaultAssigneeType?: TaskTypeDefaultAssigneeType
  slaHours?: GoogleProtobufWrappers.Int32Value
}

export type TaskTypeRef = {
  name?: string
  typeName?: string
}

export type TaskQueueRef = {
  name?: string
  queueName?: string
}

export type TaskQueue = {
  name?: string
  queueName?: string
  taskTypes?: TaskTypeRef[]
  teams?: Team[]
  teamIds?: string[]
}

export type CreateTaskQueueRequest = {
  taskQueue?: TaskQueue
}

export type UpdateTaskQueueRequest = {
  taskQueue?: TaskQueue
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type DeleteTaskQueueRequest = {
  name?: string
}

export type CreateTaskTypeRequest = {
  taskType?: TaskType
}

export type UpdateTaskTypeRequest = {
  taskType?: TaskType
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type DeleteTaskTypeRequest = {
  name?: string
}

export type CreateTeamRequest = {
  team?: Team
}

export type UpdateTeamRequest = {
  team?: Team
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type DeleteTeamRequest = {
  name?: string
}

export type ListTaskQueuesRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListTaskQueuesResponse = {
  taskQueues?: TaskQueue[]
  nextPageToken?: string
  totalSize?: number
}

export type ListTaskTypesRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListTaskTypesResponse = {
  taskTypes?: TaskType[]
  nextPageToken?: string
  totalSize?: number
}

export type ListTeamsRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListTeamsResponse = {
  teams?: Team[]
  nextPageToken?: string
  totalSize?: number
}

export type Task = {
  name?: string
  uid?: string
  type?: TaskTaskTypeDeprecated
  assignee?: CareProvider
  priority?: number
  description?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  state?: TaskState
  comments?: Comment[]
  patientId?: GoogleProtobufWrappers.StringValue
  creator?: CareProvider
  etag?: string
  taskType?: TaskType
  taskQueues?: TaskQueue[]
  taskTypeId?: string
  taskQueueIds?: string[]
  noteId?: GoogleProtobufWrappers.Int32Value
  scheduledTime?: GoogleProtobufTimestamp.Timestamp
  hoursUntilSla?: GoogleProtobufWrappers.Int32Value
  weightedScore?: GoogleProtobufWrappers.Int32Value
  weightValues?: WeightValues
}

export type WeightValues = {
  urgencyWeight?: number
  slaWeight?: number
  ownPatientWeight?: number
  acuityWeight?: number
  slaStartTime?: GoogleProtobufTimestamp.Timestamp
}

export type Comment = {
  name?: string
  uid?: string
  body?: string
  author?: CareProvider
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  taskId?: string
  etag?: string
}

export type CareProvider = {
  name?: string
  uid?: string
  givenName?: string
  familyName?: string
  role?: string
}

export type ListTasksRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
  follower?: string
  primaryHealthSystemIds?: number[]
  scheduledTimeThreshold?: GoogleProtobufTimestamp.Timestamp
  timeZone?: string
}

export type ListTasksResponse = {
  tasks?: Task[]
  nextPageToken?: string
  totalSize?: number
}

export type CreateTaskRequest = {
  task?: Task
}

export type UpdateTaskRequest = {
  task?: Task
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type GetTaskRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type OpenTaskRequest = {
  name?: string
  etag?: string
}

export type CloseTaskRequest = {
  name?: string
  etag?: string
}

export type ListCommentsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type ListCommentsResponse = {
  comments?: Comment[]
  nextPageToken?: string
  totalSize?: number
}

export type CreateCommentRequest = {
  parent?: string
  comment?: Comment
}

export type UpdateCommentRequest = {
  comment?: Comment
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type GetCommentRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type TaskChangeNotificationRequest = {
}

export type TaskChangeNotificationResponse = {
  task?: Task
  type?: TaskChangeNotificationResponseChangeType
  preChangeTask?: Task
}

export type CountTasksByTypeRequest = {
  primaryHealthSystemIds?: number[]
  assigned?: GoogleProtobufWrappers.BoolValue
  scheduledTimeThreshold?: GoogleProtobufTimestamp.Timestamp
}

export type CountTasksByTypeResponse = {
  assigned?: {[key: string]: string}
  team?: {[key: string]: string}
  following?: {[key: string]: string}
  resolved?: {[key: string]: string}
  allTeam?: {[key: string]: string}
}

export type ListTasksWeightedRequest = {
  pageSize?: number
  pageToken?: string
  orderBy?: string
  assigned?: boolean
  providerId?: string
  primaryHealthSystemIds?: number[]
  scheduledTimeThreshold?: GoogleProtobufTimestamp.Timestamp
  timeZone?: string
  taskTypeIds?: string[]
}

export class TaskService {
  static CreateTask(req: CreateTaskRequest, initReq?: fm.InitReq): Promise<Task> {
    return fm.fetchReq<CreateTaskRequest, Task>(`/rpm/v1/task`, {...initReq, method: "POST", body: JSON.stringify(req["task"], fm.replacer)})
  }
  static OpenTask(req: OpenTaskRequest, initReq?: fm.InitReq): Promise<Task> {
    return fm.fetchReq<OpenTaskRequest, Task>(`/rpm/v1/${req["name"]}:open`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CloseTask(req: CloseTaskRequest, initReq?: fm.InitReq): Promise<Task> {
    return fm.fetchReq<CloseTaskRequest, Task>(`/rpm/v1/${req["name"]}:close`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpdateTask(req: UpdateTaskRequest, initReq?: fm.InitReq): Promise<Task> {
    return fm.fetchReq<UpdateTaskRequest, Task>(`/rpm/v1/${req["task"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req, fm.replacer)})
  }
  static GetTask(req: GetTaskRequest, initReq?: fm.InitReq): Promise<Task> {
    return fm.fetchReq<GetTaskRequest, Task>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListTasks(req: ListTasksRequest, initReq?: fm.InitReq): Promise<ListTasksResponse> {
    return fm.fetchReq<ListTasksRequest, ListTasksResponse>(`/rpm/v1/task?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static CreateComment(req: CreateCommentRequest, initReq?: fm.InitReq): Promise<Comment> {
    return fm.fetchReq<CreateCommentRequest, Comment>(`/rpm/v1/${req["parent"]}/comment`, {...initReq, method: "POST", body: JSON.stringify(req["comment"], fm.replacer)})
  }
  static UpdateComment(req: UpdateCommentRequest, initReq?: fm.InitReq): Promise<Comment> {
    return fm.fetchReq<UpdateCommentRequest, Comment>(`/rpm/v1/${req["comment"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["comment"], fm.replacer)})
  }
  static ListComments(req: ListCommentsRequest, initReq?: fm.InitReq): Promise<ListCommentsResponse> {
    return fm.fetchReq<ListCommentsRequest, ListCommentsResponse>(`/rpm/v1/${req["parent"]}/comment?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ChangeNotifications(req: TaskChangeNotificationRequest, entityNotifier?: fm.NotifyStreamEntityArrival<TaskChangeNotificationResponse>, initReq?: fm.InitReq): Promise<void> {
    return fm.fetchStreamingRequest<TaskChangeNotificationRequest, TaskChangeNotificationResponse>(`/rpm/v1/task:changeNotifications?${fm.renderURLSearchParams(req, [])}`, entityNotifier, {...initReq, method: "GET"})
  }
  static CreateTaskQueue(req: CreateTaskQueueRequest, initReq?: fm.InitReq): Promise<TaskQueue> {
    return fm.fetchReq<CreateTaskQueueRequest, TaskQueue>(`/rpm/v1/task/queue`, {...initReq, method: "POST", body: JSON.stringify(req["task_queue"], fm.replacer)})
  }
  static UpdateTaskQueue(req: UpdateTaskQueueRequest, initReq?: fm.InitReq): Promise<TaskQueue> {
    return fm.fetchReq<UpdateTaskQueueRequest, TaskQueue>(`/rpm/v1/${req["taskQueue"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["task_queue"], fm.replacer)})
  }
  static DeleteTaskQueue(req: DeleteTaskQueueRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteTaskQueueRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "DELETE"})
  }
  static ListTaskQueues(req: ListTaskQueuesRequest, initReq?: fm.InitReq): Promise<ListTaskQueuesResponse> {
    return fm.fetchReq<ListTaskQueuesRequest, ListTaskQueuesResponse>(`/rpm/v1/task/queue?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListTaskTypes(req: ListTaskTypesRequest, initReq?: fm.InitReq): Promise<ListTaskTypesResponse> {
    return fm.fetchReq<ListTaskTypesRequest, ListTaskTypesResponse>(`/rpm/v1/task/type?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static CreateTaskType(req: CreateTaskTypeRequest, initReq?: fm.InitReq): Promise<TaskType> {
    return fm.fetchReq<CreateTaskTypeRequest, TaskType>(`/rpm/v1/task/type`, {...initReq, method: "POST", body: JSON.stringify(req["task_type"], fm.replacer)})
  }
  static UpdateTaskType(req: UpdateTaskTypeRequest, initReq?: fm.InitReq): Promise<TaskType> {
    return fm.fetchReq<UpdateTaskTypeRequest, TaskType>(`/rpm/v1/${req["taskType"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["task_type"], fm.replacer)})
  }
  static DeleteTaskType(req: DeleteTaskTypeRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteTaskTypeRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "DELETE"})
  }
  static CreateTeam(req: CreateTeamRequest, initReq?: fm.InitReq): Promise<Team> {
    return fm.fetchReq<CreateTeamRequest, Team>(`/rpm/v1/team`, {...initReq, method: "POST", body: JSON.stringify(req["team"], fm.replacer)})
  }
  static UpdateTeam(req: UpdateTeamRequest, initReq?: fm.InitReq): Promise<Team> {
    return fm.fetchReq<UpdateTeamRequest, Team>(`/rpm/v1/${req["team"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["team"], fm.replacer)})
  }
  static DeleteTeam(req: DeleteTeamRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteTeamRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "DELETE"})
  }
  static ListTeams(req: ListTeamsRequest, initReq?: fm.InitReq): Promise<ListTeamsResponse> {
    return fm.fetchReq<ListTeamsRequest, ListTeamsResponse>(`/rpm/v1/team?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static CountTasksByType(req: CountTasksByTypeRequest, initReq?: fm.InitReq): Promise<CountTasksByTypeResponse> {
    return fm.fetchReq<CountTasksByTypeRequest, CountTasksByTypeResponse>(`/rpm/v1/task:countTasksByType?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListProviderTasksWeighted(req: ListTasksWeightedRequest, initReq?: fm.InitReq): Promise<ListTasksResponse> {
    return fm.fetchReq<ListTasksWeightedRequest, ListTasksResponse>(`/rpm/v1/task:listProviderTasksWeighted?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListTeamTasksWeighted(req: ListTasksWeightedRequest, initReq?: fm.InitReq): Promise<ListTasksResponse> {
    return fm.fetchReq<ListTasksWeightedRequest, ListTasksResponse>(`/rpm/v1/task:listTeamTasksWeighted?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListFollowedTasksWeighted(req: ListTasksWeightedRequest, initReq?: fm.InitReq): Promise<ListTasksResponse> {
    return fm.fetchReq<ListTasksWeightedRequest, ListTasksResponse>(`/rpm/v1/task:listFollowedTasksWeighted?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListCombinedTasksWeighted(req: ListTasksWeightedRequest, initReq?: fm.InitReq): Promise<ListTasksResponse> {
    return fm.fetchReq<ListTasksWeightedRequest, ListTasksResponse>(`/rpm/v1/task:listCombinedTasksWeighted?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}
