import isEqual from 'lodash/isEqual';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { MultiselectDropdown } from '@/shared/common/MultiselectDropdown';
import { ALL_VALUE } from '@/shared/common/MultiselectDropdown/getFilteredItemValues';
import { usePatientStatusMap } from '@/shared/patient/patientStatus';
import { PatientStatus } from '@/shared/types/patient.types';

import { EnrollmentStatusFilter } from '../filters';

const ALL_PROSPECTIVE_STATUSES = [
  PatientStatus.Eligible,
  PatientStatus.NotEligible,
  PatientStatus.NeedsReview,
  PatientStatus.WaitingForProvider,
  PatientStatus.Approved,
  PatientStatus.ProviderDeclined,
  PatientStatus.OrderPended,
  PatientStatus.ReadyToEnroll,
  PatientStatus.PatientDeclined,
  PatientStatus.Duplicate,
  PatientStatus.Deleted,
];

export function EnrollmentStatusDisplayOrFilter({
  status,
  onStatus,
  label = <FormattedMessage defaultMessage="Enrollment status" />,
}: {
  status?: PatientStatus | PatientStatus[] | '';
  onStatus: (status: PatientStatus | PatientStatus[] | '') => void;
  label?: ReactNode;
}) {
  const statusMap = usePatientStatusMap();
  const { pathname } = useLocation();
  if (Array.isArray(status) || pathname.endsWith('/eligible-candidates')) {
    return (
      <MultiselectDropdown
        controlled
        filter={dropdownStatus(status)}
        setFilter={(filter) => {
          if (filter.length === 0 || filter[0] === ALL_VALUE) {
            onStatus(ALL_PROSPECTIVE_STATUSES);
          } else {
            // Can safely cast to PatientStatus since the item values are all PatientStatuses
            onStatus(filter as PatientStatus[]);
          }
        }}
        items={ALL_PROSPECTIVE_STATUSES.map((s) => ({
          label: statusMap[s] || '---',
          value: s,
        }))}
        allLabel={
          <FormattedMessage defaultMessage="All Prospective Patients" />
        }
        dropdownLabel={<FormattedMessage defaultMessage="Enrollment status" />}
        maxLabelLength={20}
        isEnrollment
      />
    );
  }

  return (
    <EnrollmentStatusFilter status={status} onStatus={onStatus} label={label} />
  );
}

function dropdownStatus(
  status: PatientStatus | PatientStatus[] | '' | undefined,
): string[] {
  if (status === '' || status === undefined) {
    return [ALL_VALUE];
  }
  if (!Array.isArray(status)) {
    return [status];
  }
  // Spread to not mutate the original arrays when sorting
  if (isEqual([...status].sort(), [...ALL_PROSPECTIVE_STATUSES].sort())) {
    return [ALL_VALUE];
  }
  return status;
}
