import { isFuture, isValid, parse, subYears } from 'date-fns';

import { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';

import { type PreventativeCareOption } from '../preventativeCareOptions';

export function getDefaultPreventativeCareStatus(
  isNotApplicable: boolean,
  date?: string,
  name?: PreventativeCareOption['name'],
): PreventativeCareStatus | undefined {
  if (isNotApplicable) {
    return undefined;
  }

  if (!date) {
    return undefined;
  }

  // Parse the date in MM/DD/YYYY format
  const vaccineDate = parse(date, 'MM/dd/yyyy', new Date());

  if (!isValid(vaccineDate)) {
    return undefined;
  }

  if (isFuture(vaccineDate)) {
    return PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
  }

  const today = new Date();

  // Apply specific rules based on preventative care type
  switch (name) {
    case 'influenza': {
      const currentMonth = today.getMonth(); // 0-based (0-11)

      // Check if we're in flu season (September through March)
      const isInFluSeason = currentMonth >= 8 || currentMonth <= 2;

      if (!isInFluSeason) {
        return undefined;
      }

      // If we are in flu season, check if the vaccine is from this season
      const currentYear = today.getFullYear();
      // If we're in January-March, the flu season started in the previous year
      const fluSeasonStartYear =
        currentMonth <= 2 ? currentYear - 1 : currentYear;
      const fluSeasonStart = new Date(fluSeasonStartYear, 8, 1); // September 1st

      return vaccineDate >= fluSeasonStart && vaccineDate <= today
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'tdap': {
      const tenYearsAgo = subYears(today, 10);
      return vaccineDate >= tenYearsAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'colonoscopy': {
      const tenYearsAgo = subYears(today, 10);
      return vaccineDate >= tenYearsAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'breastCancerScreening': {
      const twoYearsAgo = subYears(today, 2);
      return vaccineDate >= twoYearsAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'diabeticEyeExam': {
      const oneYearAgo = subYears(today, 1);
      return vaccineDate >= oneYearAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    // For other preventative care types (pneumovax, shingrix)
    // they are considered up to date if they have a valid past date
    default:
      return PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE;
  }
}
