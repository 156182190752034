import cx from 'classnames';
import { useState } from 'react';

import { flexContainer } from '@/shared/jsStyle/flex.css';

import { CardSection } from '../TaskCard/CardSection';
import { Comment } from '../TaskCard/Comment';
import { CommentInputControls } from '../TaskCard/CommentInputControls';
import { CommentList } from '../TaskCard/CommentList';
import { taskDetailInputSection } from '../TaskCard/TaskCard.css';
import { TaskCardCompactContent } from '../TaskCard/TaskCardCompactContent';
import { TaskCardContextProvider } from '../TaskCard/taskCardContext';
import { isResolved } from '../TaskCard/utils';
import type { Task } from '../types';
import { isSchedulableTask } from '../utils';
import { stickyCard, taskDetailContainer } from './TaskDetail.css';

type Props = {
  task: Task;
};

export function TaskDetail({ task }: Props) {
  const [isEditingComment, setIsEditingComment] = useState(false);

  return (
    <div className={taskDetailContainer}>
      <TaskCardContextProvider
        value={{
          isHovering: true,
          isDetailView: true,
          isEditingComment,
          setIsEditingComment,
        }}
      >
        <div>
          <div className={stickyCard.top}>
            <TaskCardCompactContent
              task={task}
              hideSnippet
              showScheduleDatePicker={isSchedulableTask(task)}
            />
          </div>
          <CardSection>
            <CardSection.LeftColumn />
            <CardSection.RightColumn>
              <Comment taskOrComment={task} canEdit={!isResolved(task)} />
            </CardSection.RightColumn>
          </CardSection>
          <CommentList
            task={task}
            splitMostRecent={!isResolved(task)}
            collapsible={false}
          />
        </div>
        {!isResolved(task) && (
          <div className={stickyCard.bottom}>
            <CardSection
              className={cx(flexContainer.column, taskDetailInputSection)}
            >
              <CommentInputControls task={task} isDisabled={false} />
            </CardSection>
          </div>
        )}
      </TaskCardContextProvider>
    </div>
  );
}
