import type { ReactElement } from 'react';
import { TransitionGroup } from 'react-transition-group';

import { NotificationBadge } from '@/shared/tempo/atom/NotificationBadge';

import type { Task } from '../types';
import { TaskCardTransition } from './TaskCardTransition';
import { sectionContainer, sectionTitle } from './TaskList.css';
import type { ParentView } from './types';

type Props = {
  tasks: Task[];
  parentView: ParentView;
  title?: string | ReactElement;
  badgeVariant?: 'default' | 'muted';
  onOpenTaskDetail?: (task: Task) => void;
  activeTaskId?: string;
  isCompact?: boolean;
  TaskCardComponent: (props: {
    task: Task;
    parentView: ParentView;
    isActive?: boolean;
    onOpenTaskDetail?: (task: Task) => void;
  }) => JSX.Element;
};

export function TaskGroup({
  tasks,
  parentView,
  title,
  badgeVariant,
  onOpenTaskDetail,
  activeTaskId,
  isCompact,
  TaskCardComponent,
}: Props) {
  return (
    <div className={sectionContainer}>
      {title && (
        <h2 className={sectionTitle}>
          {title}
          <NotificationBadge
            count={tasks.length > 99 ? '99+' : tasks.length}
            variant={badgeVariant}
          />
        </h2>
      )}
      <TransitionGroup component={null}>
        {tasks.map((task) => (
          <TaskCardTransition key={task.uid} compact={isCompact}>
            <TaskCardComponent
              task={task}
              parentView={parentView}
              onOpenTaskDetail={onOpenTaskDetail}
              isActive={task.uid === activeTaskId}
            />
          </TaskCardTransition>
        ))}
      </TransitionGroup>
    </div>
  );
}
