import { useFlags } from '@/shared/hooks';

import type { Task } from '../../types';
import { isResolved } from '../utils';
import { useGetSlaText } from './getSlaText';
import { tagText } from './slaTag.css';

type Props = {
  task: Task;
};

export function SlaTag({ task }: Props) {
  const { tasksWeightedSorting } = useFlags();
  const getSlaText = useGetSlaText();

  if (!tasksWeightedSorting || !task.hoursUntilSla || isResolved(task)) {
    return null;
  }

  const isPast = task.hoursUntilSla <= 0;

  return (
    <span className={isPast ? tagText.past : tagText.future}>
      {getSlaText(task.hoursUntilSla)}
    </span>
  );
}
