import cx from 'classnames';

import { ProgramStatusTag } from '@/shared/common/ProgramStatusTag';
import { TempRpmProgramStatusTag } from '@/shared/common/ProgramStatusTag/TempRpmProgramStatusTag';
import {
  type PatientDetails,
  PatientStatusEnum,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  isDisenrolledFromAllPrograms,
  isParticipatingInProgramType,
} from '@/shared/patient/programs.utils';

import { NoProgramsTag } from './NoProgramsTag';
import { tag, tagsContainer } from './ProgramStatusTags.css';

export type View = 'default' | 'admin';

type Props = {
  className?: string;
  patientDetails: PatientDetails;
  view?: View;
};

export function ProgramStatusTags({
  patientDetails,
  className,
  view = 'default',
}: Props) {
  if (noProgramsToDisplay(patientDetails, view)) {
    return (
      <div className={cx(tagsContainer, className)}>
        <NoProgramsTag view={view} />
      </div>
    );
  }

  return (
    <div className={cx(tagsContainer, className)}>
      {/* Use top level status until RPM program status is stable */}
      <TempRpmProgramStatusTag
        className={tag}
        view={view}
        program={ProgramType.RPM}
        patientDetails={patientDetails}
      />
      <ProgramStatusTag
        className={tag}
        view={view}
        program={ProgramType.CCM}
        patientDetails={patientDetails}
      />
      <ProgramStatusTag
        className={tag}
        view={view}
        program={ProgramType.APCM}
        patientDetails={patientDetails}
      />
    </div>
  );
}

function noProgramsToDisplay(patientDetails: PatientDetails, view: View) {
  if (view === 'admin') {
    return !patientDetails.programStatuses?.find((ps) =>
      [
        PatientStatusEnum.ENROLLED,
        PatientStatusEnum.DISENROLLED,
        PatientStatusEnum.ELIGIBLE,
        PatientStatusEnum.READY_TO_ENROLL,
        PatientStatusEnum.NEEDS_REVIEW,
        PatientStatusEnum.PROVIDER_DECLINED,
        PatientStatusEnum.PATIENT_DECLINED,
      ].includes(ps.status as PatientStatusEnum),
    );
  }

  const { isParticipating: isParticipatingRpm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.RPM,
  );
  const { isParticipating: isParticipatingCcm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.CCM,
  );
  const { isParticipating: isParticipatingApcm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.APCM,
  );

  return (
    ![isParticipatingRpm, isParticipatingCcm, isParticipatingApcm].some(
      Boolean,
    ) && !isDisenrolledFromAllPrograms(patientDetails)
  );
}
