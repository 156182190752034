import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { TypeOfEncounter } from '../Notes.types';

// this is used for display of encounter types e.g. in the note preview
export const TYPE_OF_ENCOUNTER_FORMATTED_MESSAGES: Record<
  TypeOfEncounter,
  ReactNode
> = {
  [TypeOfEncounter.PATIENT_ENROLLMENT]: (
    <FormattedMessage defaultMessage="In-person enrollment" />
  ),
  [TypeOfEncounter.VIRTUAL_ENROLLMENT]: (
    <FormattedMessage defaultMessage="Virtual enrollment" />
  ),
  // Using more general "Clinical visit" in the note preview since NPs sometimes
  // work as RNs
  [TypeOfEncounter.NP_VISIT]: (
    <FormattedMessage defaultMessage="Clinical visit" />
  ),
  [TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED]: (
    <FormattedMessage defaultMessage="RN visit" />
  ),
  // Using more general "Clinical visit" in the note preview since NPs sometimes
  // work as RNs
  [TypeOfEncounter.INITIAL_NP_VISIT]: (
    <FormattedMessage defaultMessage="Initial clinical visit" />
  ),
  [TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED]: (
    <FormattedMessage defaultMessage="Initial RN visit" />
  ),
  [TypeOfEncounter.ALERT_DOCUMENTATION]: (
    <FormattedMessage defaultMessage="Alert documentation" />
  ),
  [TypeOfEncounter.RESULTS_FOLLOW_UP]: (
    <FormattedMessage defaultMessage="Results follow-up" />
  ),
  [TypeOfEncounter.PATIENT_INBOUND]: (
    <FormattedMessage defaultMessage="Patient inbound" />
  ),
  [TypeOfEncounter.INITIAL_CN_VISIT]: (
    <FormattedMessage defaultMessage="Initial clinical visit" />
  ),
  [TypeOfEncounter.CN_VISIT]: (
    <FormattedMessage defaultMessage="Clinical visit" />
  ),
  [TypeOfEncounter.PATIENT_FOLLOW_UP]: (
    <FormattedMessage defaultMessage="Patient follow-up" />
  ),
  [TypeOfEncounter.ENROLLER_VISIT]: (
    <FormattedMessage defaultMessage="Enroller visit" />
  ),
  [TypeOfEncounter.PS_VISIT]: <FormattedMessage defaultMessage="PS visit" />,
  [TypeOfEncounter.CCM_CARE_PLAN]: (
    <FormattedMessage defaultMessage="CCM Care Plan" />
  ),
  [TypeOfEncounter.CCM_VISIT]: (
    <FormattedMessage defaultMessage="Regular CCM visit" />
  ),
  [TypeOfEncounter.APCM_CARE_PLAN]: (
    <FormattedMessage defaultMessage="APCM Care Plan" />
  ),
  [TypeOfEncounter.APCM_VISIT]: (
    <FormattedMessage defaultMessage="Regular APCM visit" />
  ),
  [TypeOfEncounter.WELCOME_CALL]: (
    <FormattedMessage defaultMessage="Welcome call" />
  ),
  [TypeOfEncounter.DISENROLLMENT]: (
    <FormattedMessage defaultMessage="Disenrollment" />
  ),
  [TypeOfEncounter.CN_CHF_VISIT]: (
    <FormattedMessage defaultMessage="CN CHF visit" />
  ),
  [TypeOfEncounter.INITIAL_CN_CHF_VISIT]: (
    <FormattedMessage defaultMessage="Initial CN CHF visit" />
  ),
  [TypeOfEncounter.DISCHARGE_FOLLOWUP]: (
    <FormattedMessage defaultMessage="Discharge follow-up" />
  ),
  // deprecated but still mapping i18n here
  [TypeOfEncounter.FOURTY_EIGHT_HOUR_FOLLOW_UP_DEPRECATED]: (
    <FormattedMessage defaultMessage="48-hour follow up" />
  ),
  [TypeOfEncounter.NP_VISIT_DEPRECATED]: (
    <FormattedMessage defaultMessage="NP visit" />
  ),
  [TypeOfEncounter.RN_VISIT_DEPRECATED]: (
    <FormattedMessage defaultMessage="RN visit" />
  ),
  [TypeOfEncounter.ASYNC_REVIEW]: (
    <FormattedMessage defaultMessage="Medication review" />
  ),
  [TypeOfEncounter.TITRATION_OUTREACH]: (
    <FormattedMessage defaultMessage="Titration outreach" />
  ),
  [TypeOfEncounter.ENROLLMENT_CALL]: (
    <FormattedMessage defaultMessage="Enrollment call" />
  ),
  [TypeOfEncounter.CN_TITRATION_OUTREACH]: (
    <FormattedMessage defaultMessage="Titration outreach" />
  ),
};

// this is used to control the text on the encounter type radio labels. most of them are
// unchanged from TYPE_OF_ENCOUNTER_FORMATTED_MESSAGES, but there are a few that we want
// to adjust.
export const TYPE_OF_ENCOUNTER_LABELS: Record<TypeOfEncounter, ReactNode> = {
  ...TYPE_OF_ENCOUNTER_FORMATTED_MESSAGES,
  [TypeOfEncounter.INITIAL_NP_VISIT]: (
    <FormattedMessage defaultMessage="Initial NP visit" />
  ),
  [TypeOfEncounter.NP_VISIT]: (
    <FormattedMessage defaultMessage="Regular NP visit" />
  ),
  [TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED]: (
    <FormattedMessage defaultMessage="Regular RN visit" />
  ),
  [TypeOfEncounter.INITIAL_CN_VISIT]: (
    <FormattedMessage defaultMessage="Initial CN visit" />
  ),
  [TypeOfEncounter.CN_VISIT]: (
    <FormattedMessage defaultMessage="Regular CN visit" />
  ),
};

export const PATIENT_FORMATTED_MESSAGES = {
  patient_no_show: <FormattedMessage defaultMessage="Patient no show" />,
  patient_outreach_follow_up: (
    <FormattedMessage defaultMessage="Patient outreach follow up" />
  ),
  // TODO: Can remove sms and phone_number once we migrate all draft notes to delete these properties
  sms: <FormattedMessage defaultMessage="SMS" />,
  phone_call: <FormattedMessage defaultMessage="Phone call" />,
};

export const ENCOUNTER_TYPE_FORMATTED_MESSAGES = {
  type_of_encounter: (
    <FormattedMessage defaultMessage="Cadence Encounter Type" />
  ),
  additional_information: (
    <FormattedMessage defaultMessage="Additional information" />
  ),
};

export const ENCOUNTER_TYPE_TITLE =
  ENCOUNTER_TYPE_FORMATTED_MESSAGES.type_of_encounter;
