import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  emptyNewMedProtocols,
  emptyNewMedSuggestionContainer,
  newMedActionsContainer,
} from '@/components/AsyncTitration/EmptyNewMedSuggestion.css';
import { UndoAction } from '@/components/AsyncTitration/SuggestedTitration/Actions';
import { RejectButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/RejectButton';
import { editButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/actions.css';
import { NotTitratingReasonSelect } from '@/components/AsyncTitration/SuggestedTitration/NotTitratingReasonSelect';
import {
  notTitrating,
  protocols,
} from '@/components/AsyncTitration/SuggestedTitration/SuggestedTitration.css';
import { SuggestedTitrationMode } from '@/components/AsyncTitration/SuggestedTitration/types';
import { usePatientMedicationsContext } from '@/pages/patients/PatientMedications/PatientMedicationsContext';
import { SuggestNewMedicationModal } from '@/pages/patients/PatientMedications/modals/SuggestNewMedicationModal';
import {
  AsyncTitrationAsyncTitrationStatus,
  type AsyncTitrationMedicationWasNotTitratedReason,
  type NewMedSuggestion,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  useRevertAsyncTitrationForNote,
  useUpdateAsyncTitration,
} from '@/shared/hooks/queries/medication.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { color } from '@/shared/tempo/theme';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import GanttIcon from '../../shared/assets/svgs/gantt.svg?react';
import PlusIcon from '../../shared/assets/svgs/plus.svg?react';

type Props = {
  emptyNewMedSuggestion: NewMedSuggestion;
  existingReferencedMedIds: Maybe<string>[];
  patientId: string;
};

export function EmptyNewMedSuggestion({
  emptyNewMedSuggestion,
  existingReferencedMedIds,
  patientId,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const [isSuggestNewMedModalOpen, setIsSuggestNewMedModalOpen] =
    useState(false);
  const [mode, setMode] = useState(SuggestedTitrationMode.Default);
  const { noteId } = usePatientMedicationsContext();
  const newTitrationId = emptyNewMedSuggestion.asyncTitrations?.findLast(
    (titration) => titration.status === AsyncTitrationAsyncTitrationStatus.NEW,
  )?.id;
  const rejectedTitrationId = emptyNewMedSuggestion.asyncTitrations?.findLast(
    (titration) =>
      titration.status ===
        AsyncTitrationAsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW &&
      titration.initialReviewNoteId === noteId,
  )?.id;
  const titrationId = rejectedTitrationId ?? newTitrationId;
  const isNewTitration = titrationId === newTitrationId;
  const isRejectedTitration = titrationId === rejectedTitrationId;

  const updateAsyncTitration = useUpdateAsyncTitration(
    patientId,
    titrationId ?? '',
  );
  const revertAsyncTitrationForNote = useRevertAsyncTitrationForNote(patientId);

  if (!titrationId) {
    return null;
  }
  const isSaving = updateAsyncTitration.isLoading;

  function onDeclineReasonSelected(
    reason: AsyncTitrationMedicationWasNotTitratedReason,
  ) {
    updateAsyncTitration.mutate(
      {
        status: AsyncTitrationAsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW,
        medicationWasNotTitratedReason: reason,
        initialReviewNoteId: noteId ?? 0,
      },
      {
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: `Failed to suggest new medication: {message}`,
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  function onUndoInitialReview() {
    revertAsyncTitrationForNote.mutate(
      { noteId: noteId ?? undefined, asyncTitrationId: titrationId },
      {
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: `Failed to undo titration decision: {message}`,
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  return (
    <div className={emptyNewMedSuggestionContainer}>
      <div className={emptyNewMedProtocols}>
        <div className={protocols.title}>
          <GanttIcon
            stroke={color.Theme.Light['Base Font Subtle']}
            fill={color.Theme.Light['Base Font Subtle']}
          />
          <span>
            <FormattedMessage defaultMessage="Protocols" />
          </span>
        </div>
        <span className={protocols.disclaimer}>
          <FormattedMessage defaultMessage="Patient has reached max dosage for existing medications. Consider starting a new medication." />
        </span>
      </div>
      {isNewTitration && (
        <>
          <div className={newMedActionsContainer}>
            <RejectButton
              onReject={() =>
                setMode((m) =>
                  m === SuggestedTitrationMode.Default
                    ? SuggestedTitrationMode.NotTitrating
                    : SuggestedTitrationMode.Default,
                )
              }
              mode={mode}
              isDisabled={isSaving}
            >
              <FormattedMessage defaultMessage="Decline new med" />
            </RejectButton>
            <Button
              variant="secondary"
              className={editButton.button.default}
              onPress={() => setIsSuggestNewMedModalOpen(true)}
              isDisabled={isSaving}
            >
              <Button.Icon>
                <PlusIcon />
              </Button.Icon>
              <FormattedMessage defaultMessage="Start new med" />
            </Button>
          </div>
          {mode === SuggestedTitrationMode.NotTitrating && (
            <div className={notTitrating.container}>
              <NotTitratingReasonSelect
                onChange={onDeclineReasonSelected}
                isDisabled={isSaving}
              />
            </div>
          )}
          {isSuggestNewMedModalOpen && (
            <SuggestNewMedicationModal
              patientId={patientId}
              onClose={() => setIsSuggestNewMedModalOpen(false)}
              existingReferencedMedIds={existingReferencedMedIds}
              asyncTitrationId={titrationId}
            />
          )}
        </>
      )}
      {isRejectedTitration && (
        <div className={newMedActionsContainer}>
          <UndoAction
            variant="rejected"
            onUndo={onUndoInitialReview}
            readOnly={false}
            isProcessing={isSaving}
            rejectedMessage={
              <FormattedMessage defaultMessage="Declined new medication" />
            }
          />
        </div>
      )}
    </div>
  );
}
