import { useIntl } from 'react-intl';

import { AsyncTitrationAsyncTitrationType } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useCreateManualAsyncTitration } from '@/shared/hooks/queries/medication.queries';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { usePatientMedicationsContext } from '../../PatientMedicationsContext';
import { useReferenceMedications } from '../../referenceMedications.queries';
import { useHasChf } from '../../utils/useHasChf';
import { useHasHtn } from '../../utils/useHasHtn';
import type { MedicationFormProps } from '../MedicationForm';
import { MedicationForm } from '../MedicationForm';
import type { MedPayload } from '../editMedicationFormConfig';
import { TitrationFormType } from '../formTypeEnum';
import { getRxNorm } from '../medClassUtil';

type Props = MedicationFormProps & {
  onSuccess: () => void;
};

export function SuggestTitrationForm({
  onSuccess,
  initValues,
  ...rest
}: Props) {
  const { onClose } = rest;

  const intl = useIntl();
  const { toaster } = useToaster();
  const { patientId, noteId } = usePatientMedicationsContext();
  const { data: referenceMedications } = useReferenceMedications();
  const hasChf = useHasChf(patientId);
  const hasHtn = useHasHtn(patientId);

  const createManualAsyncTitration = useCreateManualAsyncTitration(patientId, {
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: () => {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to propose titration',
        }),
      );
    },
  });

  function handleSubmit(payload: MedPayload) {
    const rxnorm = getRxNorm(
      referenceMedications?.referenceMedications,
      payload.rxnormId,
    );

    createManualAsyncTitration.mutate({
      editedDoseQuantities: payload.doseQuantities,
      editedFrequencies: payload.frequencies,
      editedRxcui: rxnorm?.rxcui,
      editedStartDate: payload.startDate,
      editedEndDate: payload.endDate,
      editedMaxToleratedDoseAchieved: payload.maxToleratedDoseAchieved,
      editedNote: payload.reason,
      initialReviewNoteId: noteId ?? 0,
      type: AsyncTitrationAsyncTitrationType.UP_TITRATION,
      inputPatientId: patientId,
      inputTitratableMedicationChangeId: initValues?.name ?? '',
    });
  }

  return (
    <MedicationForm
      {...rest}
      initValues={initValues}
      hasChf={hasChf}
      hasHtn={hasHtn}
      titrationFormType={TitrationFormType.Titrate}
      isProcessing={createManualAsyncTitration.isLoading}
      onSubmit={handleSubmit}
    />
  );
}
