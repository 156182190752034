import { FormattedMessage } from 'react-intl';

import { RichTextEditorWidget } from '@/shared/common/@deprecated/SchemaDrivenForm/widgets';

import type {
  CarePlanInputs,
  EncounterModuleInstance,
} from '../../Notes.types';
import { EncounterModuleId } from '../../Notes.types';
import { useEncounterModule } from '../../note.queries';
import { NotesSchemaDrivenForm } from '../@deprecated/NotesSchemaDrivenForm';

type Props = {
  initialValues?: CarePlanInputs;
  onChange: (inputs: EncounterModuleInstance) => void;
  shouldShowValidation: boolean;
  carePlanKey: string;
};

export function CarePlanForm({
  carePlanKey,
  initialValues,
  onChange,
  shouldShowValidation,
}: Props) {
  const { encounterModule, isLoading } = useEncounterModule(
    EncounterModuleId.CarePlan,
  );

  if (!encounterModule || isLoading) {
    return null;
  }

  return (
    <NotesSchemaDrivenForm
      key={carePlanKey}
      initialFormData={initialValues}
      schema={encounterModule.schema}
      onChange={(inputs: CarePlanInputs) => {
        onChange({
          encounter_module_id: encounterModule.id,
          inputs,
        });
      }}
      shouldShowValidation={shouldShowValidation}
      uiSchema={{
        care_plan_body: {
          'ui:label': CARE_PLAN_BODY_LABEL,
          'ui:description': (
            <FormattedMessage defaultMessage="Paste the full contents from the Care Plan PDF here after reviewing with patient." />
          ),
          // Using ui:field here because ui:widget doesn't get applied since property has type: "object"
          'ui:field': RichTextEditorWidget,
        },
      }}
    />
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const CARE_PLAN_BODY_LABEL = (
  <FormattedMessage defaultMessage="Cadence Comprehensive Care Plan" />
);
