import { convertProgramConditionsToConditionList } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import {
  type Condition as GrpcCondition,
  ProgramStatus,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { convertPtConditionPerProgramToPrograms } from '@/shared/hooks/queries/patients-grpc/converters';
import { usePatientPrograms } from '@/shared/hooks/queries/program-condition.queries';
import type { Condition } from '@/shared/types/clinicalprofile.types';
import { type Patient } from '@/shared/types/patient.types';

// Returns conditions for RPM only
export function useProgramConditions(patient: Patient): Condition[] {
  const { data: ptConditionsPerProgram } = usePatientPrograms(
    patient.id || '',
    !!patient.id,
  );
  const programs = convertPtConditionPerProgramToPrograms(
    ptConditionsPerProgram,
  );
  const [conditions] = convertProgramConditionsToConditionList(
    programs,
    patient.status,
  );

  return conditions;
}

// Returns conditions for a patient of all program types (RPM, CCM, etc.)
export function useAllProgramConditions(
  patientId: string,
  enabled = true,
): {
  conditions: GrpcCondition[];
  isLoading: boolean;
} {
  const { data: ptConditionsPerProgram, isLoading } = usePatientPrograms(
    patientId,
    !!patientId && enabled,
  );
  const programs = convertPtConditionPerProgramToPrograms(
    ptConditionsPerProgram,
  );
  const allConditions = programs
    .filter((program) => program.program_status === ProgramStatus.ENROLLED)
    .flatMap((program) => program.conditions)
    .map((c) => c.condition_type);

  return { conditions: allConditions, isLoading };
}
