import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { type FormFields, getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import { SafetyChoice, SafetyFrequencyChoice } from './safety.types';
import * as styles from './shared.css';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
  hasGap: (fields: FormFields) => boolean;
};

function SafetyFrequencyRadioGroup({
  name,
  label,
}: {
  name: string;
  label: ReactElement;
}) {
  return (
    <Form.RadioGroup
      name={name}
      label={label}
      orientation="horizontal"
      size={11}
      required
    >
      <Form.Radio value={SafetyFrequencyChoice.Never}>
        <FormattedMessage defaultMessage="Never" />
      </Form.Radio>
      <Form.Radio value={SafetyFrequencyChoice.Rarely}>
        <FormattedMessage defaultMessage="Rarely" />
      </Form.Radio>
      <Form.Radio value={SafetyFrequencyChoice.Sometimes}>
        <FormattedMessage defaultMessage="Sometimes" />
      </Form.Radio>
      <Form.Radio value={SafetyFrequencyChoice.FairlyOften}>
        <FormattedMessage defaultMessage="Fairly often" />
      </Form.Radio>
      <Form.Radio value={SafetyFrequencyChoice.Frequently}>
        <FormattedMessage defaultMessage="Frequently" />
      </Form.Radio>
    </Form.RadioGroup>
  );
}

export function Safety({
  form,
  createGoalWithMeasure,
  hasGap,
}: Props): ReactElement {
  const intl = useIntl();

  const safetyChoice = form.watch('safetyChoice') as SafetyChoice | undefined;
  const showNotes = safetyChoice === SafetyChoice.Yes;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('safety');

  const hasGapVal = hasGap(form.getValues());
  useEffect(() => {
    form.setValue(checkedFieldName, hasGapVal, {
      shouldValidate: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGapVal]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="safetyChoice"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Safety" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="Because we care about the safety of our patients, we'd like to know if you are experiencing any physical or verbal harm. Has anyone in your immediate circle been hurting you physically, or hurting you verbally in any way like threatening you or insulting you?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
          onChange={(value) => {
            if (value === SafetyChoice.No) {
              form.setValue('physicalHarmFrequency', undefined, {
                shouldValidate: true,
              });
              form.setValue('threatFrequency', undefined, {
                shouldValidate: true,
              });
              form.setValue('verbalAbuseFrequency', undefined, {
                shouldValidate: true,
              });
              form.setValue('insultFrequency', undefined, {
                shouldValidate: true,
              });
            }
          }}
        >
          <Form.Radio value={SafetyChoice.No}>
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
          <Form.Radio value={SafetyChoice.Yes}>
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
        </Form.RadioGroup>

        {showNotes && (
          <div className={styles.detailContainer}>
            <SafetyFrequencyRadioGroup
              name="physicalHarmFrequency"
              label={
                <FormattedMessage defaultMessage="How often does anyone physically hurt you?" />
              }
            />

            <SafetyFrequencyRadioGroup
              name="threatFrequency"
              label={
                <FormattedMessage defaultMessage="How often does anyone threaten you with harm?" />
              }
            />

            <SafetyFrequencyRadioGroup
              name="verbalAbuseFrequency"
              label={
                <FormattedMessage defaultMessage="How often does anyone scream at or curse at you?" />
              }
            />

            <SafetyFrequencyRadioGroup
              name="insultFrequency"
              label={
                <FormattedMessage defaultMessage="How often does anyone insult you or talk you down?" />
              }
            />
          </div>
        )}

        {hasGapVal && (
          <div className={styles.gapMessage}>
            <FormattedMessage defaultMessage="Patient has a safety need according to CMS guidelines" />
          </div>
        )}
      </div>

      {showNotes && (
        <div className={styles.rightColumn}>
          <GapWidget
            fieldName={descriptionFieldName}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter notes about issues with safety',
            })}
            showGoalButton
            onCreateGoal={createGoalWithMeasure}
            measure={GoalMeasure.Safety}
            form={form}
            requireDescription={hasGapVal}
          />
        </div>
      )}
    </>
  );
}
