{{> header val="HPI" }}
{{> patient_summary }}

<br/>
<p>Pt was discharged from (hospital name) on (date) for (diagnosis).</p>
<br/>
<p>Confirm the patient's location- ie home, rehab, assisted living, etc.</p>
<p>Confirm patient's State</p>
<p>Confirm patient's ordering provider</p>
<br/>

{{> subheader val="Medication allergies" }}
{{> med_allergies }}

{{> subheader val="Problem List" }}
{{> problem_list }}

<br/>
<p>Any new diagnoses added at discharge?</p>
<p>Medication List and Cadence Med Module updated - Yes/No</p>
<p>Has any follow up testing been ordered- Yes/No</p>
<p>Were you able to get your medications filled?</p>
<p>Do you have any questions about your medications?</p>
<p>Any pain or discomfort?</p>
<p>Any outpatient services, if so, are you scheduled? Which Specialists? When?</p>
<p>If HH was ordered, did they contact you?</p>
<p>Were you able to schedule your follow up appointments? If so, when and where? (If not RN, to send task to APCM coordinator to schedule office visit)</p>
<p>Are there any additional resources (transportation, DME) you might need during your recovery?</p>
<p>What support systems do you have?</p>
<p>-Document what education was provided and who was educated (patient, family member, caregiver, etc.)</p>
<p>Do you have any questions about your condition or discharge instructions?</p>
<br/>

{{> subheader val="Follow up" }}
<ul>
    <li>Date and time for next follow-up with Cadence clinician:</li>
    <li>Date and time for next appointment with PCP (should be within 14 days of discharge):</li>
    <li>Date and time for next appointment with Specialists (as applicable):</li>
</ul>
