import { LabGroup } from '@/shared/generated/grpc/go/pms/pkg/patient/labs/labs.pb';
import { usePatientClinicalProfile } from '@/shared/hooks/queries/clinical-profile.queries';
import { usePatientLabsAndGroups } from '@/shared/hooks/queries/labs.queries';

import { getLatestLabGroupsWithDatesAndLabs } from '../../../../../Labs/labs.utils';

export function usePatientConditionData(patientId: string) {
  const { data: clinicalProfile, isLoading: isLoadingClinicalProfile } =
    usePatientClinicalProfile(patientId);

  const { data: patientLabs, isLoading: isLoadingLabs } =
    useCarePlanLabData(patientId);

  return {
    isLoading: isLoadingClinicalProfile || isLoadingLabs,
    data: {
      a1c: clinicalProfile?.a1c,
      avgDiastolic: clinicalProfile?.avgDiastolic,
      avgSystolic: clinicalProfile?.avgSystolic,
      bmi: clinicalProfile?.bmi,
      totalCholesterol: patientLabs?.totalCholesterol,
    },
  };
}

function useCarePlanLabData(patientId: string) {
  const { data: patientLabsAndGroups, isLoading: isLoadingLabsAndGroups } =
    usePatientLabsAndGroups(patientId);

  const labGroups = patientLabsAndGroups?.labGroups;
  const latesLabsPerGroup = getLatestLabGroupsWithDatesAndLabs(labGroups || []);

  const lipidsLab = latesLabsPerGroup[LabGroup.LIPIDS];
  const tcLab = lipidsLab?.labs.find(
    (lab) => lab.referenceLabName === 'Cholesterol, Total',
  );
  const totalCholesterol = tcLab?.value ? Number(tcLab.value) : undefined;

  return {
    isLoading: isLoadingLabsAndGroups,
    data: { totalCholesterol },
  };
}
