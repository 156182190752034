{{> header val="Recent Vital Trends" }}
{{> vitals_list }}

{{> header val="Product Support" }}
<ul>
  <li>Questions related to enrollment and devices:</li>
  <li>Patient confirms their contact information, emergency contact is correct:</li>
</ul>

{{> header val="Problem List" }}
{{> problem_list }}
<br/>

{{> subheader val="Medication allergies" }}
{{> med_allergies }}

{{#if flags.autopopulateEncounterLabs}}
{{> header val="Lab Results" }}
{{> latest_labs }}
<br/>
{{else}}
{{> header val="Lab Results" }}
{{! T2D, and T2D + HTN Only Section }}
{{> subheader val="Last A1C, Basic Metabolic Panel (BMP) and Lipid Panel" }}
{{> free_text_space }}
{{! T2D, and T2D + HTN Only Section }}
{{/if}}

{{> subheader val="Studies: ECHO" }}
{{> free_text_space }}
