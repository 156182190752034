import { useFlags } from '@/shared/hooks';

import { TabKey } from '../TabKey';
import { AssigneeFilter } from './AssigneeFilter';
import { HealthSystemFilter } from './HealthSystemFilter';
import { OrderBy } from './OrderBy';
import { filterDropdownContainer } from './TaskFilters.css';

type Props = {
  tabKey: TabKey;
};

export function TaskFilters({ tabKey }: Props) {
  const { tasksWeightedSorting } = useFlags();

  return (
    <div className={filterDropdownContainer}>
      <HealthSystemFilter tabKey={tabKey} />
      {tabKey === TabKey.Team && <AssigneeFilter />}
      {!tasksWeightedSorting && <OrderBy />}
    </div>
  );
}
