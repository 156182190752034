import { convertToRaw } from 'draft-js';

import type { TNoteBodyRTF } from '@/shared/types/note.types';

import { convertHtmlToDraftJsEditorState } from '../../utils/noteConverters';
import { engine } from './engine';
import type { TemplateContext, TemplateDelegate } from './types';

export function draftJsFieldTemplate<T = TemplateContext>(
  template: TemplateSpecification,
): TemplateDelegate<TNoteBodyRTF, T> {
  return (context: T) => {
    const templateText = engine.template(template)(context);
    return convertToRaw(
      convertHtmlToDraftJsEditorState(templateText).getCurrentContent(),
    );
  };
}

export function textFieldTemplate(
  template: TemplateSpecification,
): TemplateDelegate<string, TemplateContext> {
  return (context: TemplateContext) => engine.template(template)(context);
}
