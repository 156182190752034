import { type ReactElement } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { GridItem } from '@/shared/common/Form/GridItem';
import { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';

import { ContextualGoalButton } from '../../../Goals/ContextualGoalButton';
import { type GoalMeasure } from '../../../Goals/goals.types';
import {
  type FormFields,
  type PreventativeCareName,
  getPreventativeCareFieldNames,
} from '../../formConfig';
import { toggleRowHelp } from '../carePlanSections.css';
import { actionRow, preventativeCareRow } from './PreventativeCareRow.css';
import { PreventativeCareStatusField } from './PreventativeCareStatus';
import { getDefaultPreventativeCareStatus } from './preventativeCare.utils';
import { PREVENTATIVE_CARE_GOAL_MEASURE_MAP } from './preventativeCareGoals';

export function PreventativeCareRowV2({
  name,
  label,
  help,
  form,
  createGoalWithMeasure,
  isNotApplicable = false,
}: {
  form: UseFormReturn<FormFields>;
  name: PreventativeCareName;
  label: string;
  help?: ReactElement;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
  isNotApplicable?: boolean;
}) {
  const intl = useIntl();
  const {
    date: dateFieldName,
    status: statusFieldName,
    nextSteps: nextStepsFieldName,
  } = getPreventativeCareFieldNames(name);

  const status = form.getValues(statusFieldName);
  const nextSteps = form.getValues(nextStepsFieldName);

  function setDefaultStatusFromDate(dateValue: string) {
    const newStatusValue = getDefaultPreventativeCareStatus(
      isNotApplicable,
      dateValue,
      name,
    );
    if (newStatusValue) {
      form.setValue(statusFieldName, newStatusValue, { shouldValidate: true });
    }
  }

  return (
    <>
      <Form.Row className={preventativeCareRow} rowSpacing={0}>
        <GridItem size={3}>
          <div>{label}</div>
          {help && <span className={toggleRowHelp}>{help}</span>}
        </GridItem>

        <GridItem size={6}>
          <PreventativeCareStatusField name={statusFieldName} />
        </GridItem>

        <Form.TextField
          size={3}
          name={dateFieldName}
          onBlur={(dateValue) => {
            if (
              status === undefined ||
              status ===
                PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UNSPECIFIED
            ) {
              setDefaultStatusFromDate(dateValue);
            }
          }}
          placeholder={
            status === PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_DECLINED
              ? intl.formatMessage({
                  defaultMessage: 'Enter reason declined',
                })
              : intl.formatMessage({
                  defaultMessage: 'MM / DD / YYYY',
                })
          }
        />
      </Form.Row>
      {status ===
        PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED && (
        <Form.Row rowSpacing={0} className={actionRow}>
          <GridItem size={3}> </GridItem>
          <Form.TextField
            size={9}
            name={nextStepsFieldName}
            label={intl.formatMessage({
              defaultMessage: 'Next steps',
            })}
            endAdornment={
              <ContextualGoalButton
                type="default"
                onPress={() =>
                  createGoalWithMeasure(
                    PREVENTATIVE_CARE_GOAL_MEASURE_MAP[name],
                    nextSteps as string,
                  )
                }
              />
            }
            required
          />
        </Form.Row>
      )}
    </>
  );
}
