import { parseISO } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { logger } from '@/logger';
import {
  type PatientDetails,
  type PatientProgramStatus,
  PatientStatusEnum,
  type Program,
  ProgramStatus,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type {
  ApcmCondition,
  CcmCondition,
  RpmCondition,
} from '@/shared/types/clinicalprofile.types';

import {
  getCcmConditionsByProgram,
  getRpmConditionsByProgram,
} from './conditions.utils';

type ParticipationConfig = {
  checkConsent?: boolean;
};

// TODO: This should get replaced to use the *_program_status tables
// once available
type ProgramParticipation<T extends ProgramType> = {
  isParticipating: boolean;
  isDisenrolled: boolean;
  consentDate: Nullable<Date>;
  conditions: T extends ProgramType.RPM
    ? RpmCondition[]
    : T extends ProgramType.CCM
      ? CcmCondition[]
      : T extends ProgramType.APCM
        ? ApcmCondition[]
        : never[];
};
export function isParticipatingInProgramType<T extends ProgramType>(
  patient: Maybe<PatientDetails>,
  programType: T,
  config: ParticipationConfig = {},
): ProgramParticipation<T> {
  const programs: Maybe<Program[]> = patient?.programs;
  const status: Maybe<PatientStatusEnum> = patient?.patient?.status;

  function parseConsentDate(consentDate: Maybe<string>) {
    return consentDate ? parseISO(consentDate) : null;
  }

  if (!patient || !programs?.length || !status) {
    return {
      isParticipating: false,
      conditions: [],
      consentDate: null,
      isDisenrolled: false,
    } as ProgramParticipation<T>;
  }

  let programStatus: ProgramStatus;
  if ([PatientStatusEnum.ENROLLED].includes(status)) {
    programStatus = ProgramStatus.ENROLLED;
  } else {
    programStatus = ProgramStatus.SELECTED;
  }

  const foundProgram = programs?.find(
    (program) =>
      program.programType === programType &&
      program.programStatus === programStatus,
  );

  const consentValidated = (consent: Maybe<string>) =>
    config.checkConsent ? !!consent : true;

  const isDisenrolled = isProgramDisenrolled(patient, programType);

  /* eslint-disable no-case-declarations */
  switch (programType) {
    case ProgramType.APCM:
      // TODO: potentially generalize (or separate) APCM/CCM Conditions
      const apcmConditions = getCcmConditionsByProgram(foundProgram);
      const apcmConsentDate = patient?.programStatuses?.find(
        (s) => s.programType === ProgramType.APCM && s.consentDate,
      )?.consentDate;
      return {
        // For APCM, can be participating even with no conditions
        isParticipating: !!foundProgram && consentValidated(apcmConsentDate),
        conditions: apcmConditions,
        consentDate: parseConsentDate(apcmConsentDate),
        isDisenrolled,
      } as ProgramParticipation<T>;
    case ProgramType.CCM:
      const ccmConditions = getCcmConditionsByProgram(foundProgram);
      return {
        isParticipating:
          !!foundProgram &&
          !isEmpty(ccmConditions) &&
          consentValidated(patient?.patient?.ccmConsentDate),
        conditions: ccmConditions,
        consentDate: parseConsentDate(patient?.patient?.ccmConsentDate),
        isDisenrolled,
      } as ProgramParticipation<T>;
    case ProgramType.RPM:
      const rpmConditions = getRpmConditionsByProgram(foundProgram);
      return {
        isParticipating:
          !!foundProgram &&
          !isEmpty(rpmConditions) &&
          consentValidated(patient?.patient?.rpmConsentDate),
        conditions: rpmConditions,
        consentDate: parseConsentDate(patient?.patient?.rpmConsentDate),
        isDisenrolled,
      } as ProgramParticipation<T>;

    default:
      logger.error(
        'Could not determine participation of unsupported program type.',
      );
      return {
        isParticipating: false,
        conditions: [],
        consentDate: null,
        isDisenrolled: false,
      } as ProgramParticipation<T>;
  }
  /* eslint-enable no-case-declarations */
}

export function getProgramStatusRecord(
  patient: PatientDetails,
  programType: ProgramType,
): Nullable<PatientProgramStatus> {
  return (
    patient.programStatuses?.find((ps) => ps.programType === programType) ||
    null
  );
}

export function isProspectiveProgramStatus(status: Maybe<PatientStatusEnum>) {
  return (
    !!status &&
    ![PatientStatusEnum.DISENROLLED, PatientStatusEnum.ENROLLED].includes(
      status,
    )
  );
}

/**
 * Patient is disenrolled if all patient programs are in disenrolled state
 */
export function isDisenrolledFromAllPrograms(patient: PatientDetails) {
  // TODO: Update this to check if each program is disenrolled once program-level
  // disenrollment is supported
  return patient.patient?.status === PatientStatusEnum.DISENROLLED;
}

function isProgramDisenrolled(
  patient: PatientDetails,
  programType: ProgramType,
) {
  // TODO: Handle per-program disenrollments better once implemented
  const topLevelDisenrolled =
    patient.patient?.status === PatientStatusEnum.DISENROLLED;
  const programDisenrolled =
    getProgramStatusRecord(patient, programType)?.status ===
    PatientStatusEnum.DISENROLLED;

  return topLevelDisenrolled && programDisenrolled;
}

export function hasPatientAnyRTEProgram(patient: PatientDetails) {
  return patient.programStatuses?.some(
    (ps) => ps.status === PatientStatusEnum.READY_TO_ENROLL,
  );
}

export function isPatientEnrolledInCCMOrAPCM(patient: PatientDetails) {
  return patient.programStatuses?.some(
    (ps) =>
      (ps.programType === ProgramType.CCM ||
        ps.programType === ProgramType.APCM) &&
      ps.status === PatientStatusEnum.ENROLLED,
  );
}
