import uniq from 'lodash/uniq';

import { useAllProgramConditions } from '@/apps/shared/useProgramConditions';
import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import type { Condition } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

import type { PatientConditionData } from '../prognosisUtils';
import {
  getConditionPrognosis,
  rankingSort,
  sortConditions,
} from '../prognosisUtils';
import { usePatientConditionData } from './usePatientConditionData.hook';

export type InitialCarePlanConditions = ReturnType<
  typeof useInitialCarePlanConditions
>['conditions'];

// useInitialCarePlanConditions returns the conditions that initially populate the care plan
export function useInitialCarePlanConditions(
  patientId: string,
  enabled = true,
) {
  const { conditions, isLoading: isLoadingConditions } =
    useAllProgramConditions(patientId, enabled);
  const { data: conditionData, isLoading: isLoadingConditionData } =
    usePatientConditionData(enabled ? patientId : '');

  // Get union of top 2 conditions when unknown stability is set to unstable,
  // and top 2 conditionswhen unknown stability is set to stable.
  // This way we are striking a balance between asking about unknown conditions
  // and asking about conditions we have info about.
  const prioritizedConditions = uniq([
    ...pickTopTwoConditions(conditions, conditionData, CCMPrognosis.FAIR),
    ...pickTopTwoConditions(conditions, conditionData, CCMPrognosis.GOOD),
  ]);

  // If the above yields < 3 conditions, and there are more conditions to show, show the next condition based solely on ranking
  const remainingConditions = conditions.filter(
    (c) => !prioritizedConditions.includes(c),
  );
  if (remainingConditions.length > 0) {
    const sortedRemainingConditions = remainingConditions.sort(rankingSort);

    prioritizedConditions.push(sortedRemainingConditions[0]);
  }

  return {
    conditions: prioritizedConditions.map((c) => ({
      condition: c,
      prognosis: getConditionPrognosis(c, conditionData),
    })),
    isLoading: isLoadingConditions || isLoadingConditionData,
  };
}

function pickTopTwoConditions(
  conditions: Condition[],
  conditionData: PatientConditionData,
  defaultPrognosis: CCMPrognosis,
) {
  const sortedConditions = sortConditions(
    conditions.map((condition) => ({
      condition,
      prognosis: getConditionPrognosis(
        condition,
        conditionData,
        defaultPrognosis,
      ),
    })),
  );
  return sortedConditions.slice(0, 2).map((c) => c.condition);
}
