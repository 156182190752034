// This file should be deprecated, try to avoid adding new function here
// move all general form payload parsing functions to `CommonForm/parseFormValueUtil`
import { format } from 'date-fns';

import type { PatientContact } from '@/shared/types/patient.types';

import { serializeProgramInfoFormValues } from '../ConsentTab/consentTabFormConfig';
import type {
  CreatePatientFormFields,
  EditPatientFormFields,
} from './formConfig';
import { PreferCaregiverContact } from './formConfig';
import {
  hasAddressRelatedFields,
  hasEmergencyContactRelatedFields,
  mapToPatientContact,
  parseAddress,
  parseCommunicationPreferencesFields,
  parseContacts,
  parseEmergencyContact,
} from './parseFormValueUtil';
import type {
  CreatePatientPayload,
  UpdatePatientPayload,
} from './patientForm.queries';

const parseAllContacts = (values: EditPatientFormFields): PatientContact[] => {
  const nonEmergencyContacts: PatientContact[] = mapToPatientContact(
    parseContacts(values),
  );
  if (hasEmergencyContactRelatedFields(values)) {
    return [...nonEmergencyContacts, parseEmergencyContact(values)];
  }

  return nonEmergencyContacts;
};

const parsePatientContactInfo = (values: EditPatientFormFields) => {
  let body = {};
  if (hasAddressRelatedFields(values)) {
    const addresses = {
      addresses: [{ ...parseAddress(values), primary: true }],
    };
    body = Object.assign(body, addresses);
  }

  const contacts = { contacts: parseAllContacts(values) };
  body = Object.assign(body, contacts);

  if (values.email) {
    body = Object.assign(body, { email: values.email.trim() });
  }

  if (values.race) {
    body = Object.assign(body, { race: values.race });
  }

  if (values.is_test) {
    body = Object.assign(body, { is_test: values.is_test });
  }

  return body;
};

const parsePatientFlags = (values: EditPatientFormFields) => ({
  is_test: values.is_test,
});

const parseEditPatientFields = (
  values: EditPatientFormFields,
  hasNewEnrollmentUiUpdates: boolean,
) => {
  const patientContactInfo = parsePatientContactInfo(values);
  const flags = parsePatientFlags(values);

  return {
    ...patientContactInfo,
    ...flags,
    ...parseCommunicationPreferencesFields(values),
    accommodations:
      values.accommodations.length > 0 ? values.accommodations : null,
    accommodations_other: values.accommodations_other || null,
    residence: values.residence || null,
    residence_other: values.residence_other || null,
    gender: values.gender,
    ...(!hasNewEnrollmentUiUpdates && serializeProgramInfoFormValues(values)),
    timezone: values.timezone,
    primary_language: values.primary_language,
    emergency_contact_disabled: hasNewEnrollmentUiUpdates
      ? // Persist actual value under new UI changes because now emergency contact is optional, so not filling out an emergency contact doesn't mean that there is no emergency contact
        values.emergency_contact_disabled || false
      : !hasEmergencyContactRelatedFields(values),
  };
};

const parseCreatePatientFields = (
  values: CreatePatientFormFields,
): CreatePatientPayload => {
  const trimmedFirstName = values.first_name.trim();
  const trimmedLastName = values.last_name.trim();
  const trimmedEmail = values.email?.trim();

  return {
    first_name: trimmedFirstName,
    last_name: trimmedLastName,
    dob: format(values.dob, 'yyyy-MM-dd'),
    is_test: values.is_test,
    has_caregiver: values.preferred_phone === PreferCaregiverContact,
    contacts: mapToPatientContact(parseContacts(values)),
    emergency_contact_disabled: false, // we never capture emergency contact on add
    email: trimmedEmail || null,
    health_system_id: values.health_system ? values.health_system.id : null,
  };
};

// aliasing for consistency in exported fn names
export const parseCreatePatientPayload = parseCreatePatientFields;

export const parseUpdatePatientPayload = (
  values: EditPatientFormFields,
  hasNewEnrollmentUiUpdates: boolean,
): UpdatePatientPayload => ({
  ...parseCreatePatientFields(values),
  ...parseEditPatientFields(values, hasNewEnrollmentUiUpdates),
});
