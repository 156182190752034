import { type FormFields } from '../../formConfig';
import { SafetyFrequencyChoice } from './safety.types';

const getScore = (frequency: SafetyFrequencyChoice): number => {
  switch (frequency) {
    case SafetyFrequencyChoice.Never:
      return 1;
    case SafetyFrequencyChoice.Rarely:
      return 2;
    case SafetyFrequencyChoice.Sometimes:
      return 3;
    case SafetyFrequencyChoice.Frequently:
      return 4;
    case SafetyFrequencyChoice.FairlyOften:
      return 5;
    default:
      return 1;
  }
};

function getSafetyScore(fields: FormFields) {
  return (
    getScore(fields.physicalHarmFrequency ?? SafetyFrequencyChoice.Never) +
    getScore(fields.threatFrequency ?? SafetyFrequencyChoice.Never) +
    getScore(fields.verbalAbuseFrequency ?? SafetyFrequencyChoice.Never) +
    getScore(fields.insultFrequency ?? SafetyFrequencyChoice.Never)
  );
}

export const hasSafetyGap = (fields: FormFields): boolean =>
  // Score of 11 or higher indicates a safety need
  getSafetyScore(fields) >= 11;
