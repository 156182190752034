import {
  endOfDay,
  formatISO,
  startOfDay,
  subWeeks,
  subYears,
} from 'date-fns/fp';
import flow from 'lodash/flow';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { SortState } from '@/shared/common/Table';
import { useTableSort } from '@/shared/common/Table';
import { getSortParams } from '@/shared/common/Table/sort/sort.utils';
import type { ProspectivePatientsSortField } from '@/shared/hooks/queries';
import type { PatientStatus } from '@/shared/types/patient.types';

import type { ProspectivePatientsFilter } from './enrollment.queries';
import { cleanProspectivePatientsFilter } from './enrollment.queries';

export function getStatusKey(
  statuses: PatientStatus | PatientStatus[] | typeof ALL_STATUS = ALL_STATUS,
) {
  return Array.isArray(statuses) ? statuses.sort().join(',') : statuses;
}

export const ALL_STATUS = '';

export const lastClinicAppointmentDateRangeInitFilter = {
  last_clinic_appt_from: flow([startOfDay, subYears(1), formatISO])(new Date()),
  last_clinic_appt_to: flow([endOfDay, formatISO])(new Date()),
};
export const nextAppointmentDateInitFilter = {
  appt_from: flow([startOfDay, formatISO])(new Date()),
  appt_to: flow([endOfDay, formatISO])(new Date()),
};

export const statusDateRangeInitFilter = {
  status_date_start: flow([startOfDay, subWeeks(6), formatISO])(new Date()),
  status_date_end: flow([endOfDay, formatISO])(new Date()),
};

/**
 * Hook to get the list of patients for the patient enrollment tracker.
 */
export function usePatients(
  patientFilterState: Nullable<ProspectivePatientsFilter>,
  setPatientFilter: Dispatch<
    SetStateAction<Nullable<ProspectivePatientsFilter>>
  >,
  initStatus: PatientStatus | PatientStatus[] | typeof ALL_STATUS = ALL_STATUS,
  initSort: SortState<ProspectivePatientsSortField>,
  initCurrFilters?: Partial<ProspectivePatientsFilter>,
) {
  useEffect(() => {
    if (patientFilterState === null) {
      setPatientFilter({
        status: initStatus,
        ...initCurrFilters,
      });
    }
    // Running effect only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [defaultFilters, setDefaultFilters] = useState<
    Partial<ProspectivePatientsFilter>
  >({});
  const patientFilter = { ...defaultFilters, ...patientFilterState };

  const { sortState: prevPatientSort, handleSort: onSortChange } =
    useTableSort<ProspectivePatientsSortField>(initSort);

  const patientSort = getSortParams(prevPatientSort, initSort);

  /**
   * Sets filters in the state. As available filters depend on status field,
   * we reinitialize filters on status changes to not have stale filters.
   */
  const onFilterChange =
    (key: keyof ProspectivePatientsFilter) =>
    (value: FilterChangeHandlerValue) => {
      setPatientFilter((currFilters) => ({
        // Status reason/date_start/date_end fields are used for various filter
        // inputs, so we need to reset these to not have invalid values on status
        // change.
        ...(key === 'status'
          ? omit(currFilters, [
              'status_reason',
              'status_date_start',
              'status_date_end',
            ])
          : currFilters),
        [key]: isString(value) ? value.trim() : value,
      }));
    };

  const { filterOutUnusedFilters, setUsedFilterFields } = useUsedFilterFields();

  const patientRequestFilterParams = {
    ...(patientSort.sortKey && { sort_by: patientSort.sortKey }),
    ...(patientSort.sortDir && { order_by: patientSort.sortDir }),
    ...cleanProspectivePatientsFilter(filterOutUnusedFilters(patientFilter)),
  };

  return {
    patientFilter,
    onFilterChange,
    onSortChange,
    patientSort,
    patientRequestFilterParams,
    setDefaultFilters,
    setUsedFilterFields,
  };
}

function useUsedFilterFields() {
  const [usedFilterFields, setUsedFilterFields] = useState<
    Array<keyof ProspectivePatientsFilter>
  >([]);
  return {
    setUsedFilterFields,
    filterOutUnusedFilters: useCallback(
      (filters: ProspectivePatientsFilter) =>
        usedFilterFields.length === 0
          ? filters
          : pickBy(filters, (value, key) =>
              usedFilterFields.includes(key as keyof ProspectivePatientsFilter),
            ),
      [usedFilterFields],
    ),
  };
}

export type FilterChangeHandler = (
  key: keyof ProspectivePatientsFilter,
) => (value: FilterChangeHandlerValue) => void;

type FilterChangeHandlerValue = string | string[] | boolean | number[];
