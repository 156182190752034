import { type UseFormReturn } from 'react-hook-form';

import { type GoalMeasure } from '../../../Goals/goals.types';
import type { FormFields } from '../../formConfig';
import { FoodSecurity } from './FoodSecurity';
import { LivingSituationForm } from './LivingSituationForm';
import { PhysicalActivity } from './PhysicalActivity';
import { Safety } from './Safety';
import { TransportationSecurity } from './TransportationSecurity';
import { Utilities } from './Utilities';
import { FoodFrequencyChoice } from './foodSecurity.types';
import { hasSafetyGap } from './safety.utils';
import { TransportationSecurityChoice } from './transportationSecurity.types';
import { UtilityStatus } from './utilities.types';

type PsychosocialAssessmentOption = {
  name: string;
  label: string;
  component: React.ComponentType<{
    form: UseFormReturn<FormFields>;
    createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
    hasGap: (fields: FormFields) => boolean;
  }>;
  hasGap: (fields: FormFields) => boolean;
};

export const PSYCHOSOCIAL_ASSESSMENT_OPTIONS: readonly PsychosocialAssessmentOption[] =
  [
    {
      name: 'livingSituation',
      // Using string so it can be used in the template
      label: 'Living Situation',
      component: LivingSituationForm,
      hasGap: (fields: FormFields) =>
        fields.housingStabilityDetail !== undefined ||
        (fields.housingConcerns?.length ?? 0) > 0,
    },
    {
      name: 'food',
      label: 'Food Security',
      component: FoodSecurity,
      hasGap: (fields: FormFields) =>
        fields.foodRunOutFrequency === FoodFrequencyChoice.Often ||
        fields.foodRunOutFrequency === FoodFrequencyChoice.Sometimes ||
        fields.foodWorryFrequency === FoodFrequencyChoice.Often ||
        fields.foodWorryFrequency === FoodFrequencyChoice.Sometimes,
    },
    {
      name: 'transportation',
      label: 'Transportation',
      component: TransportationSecurity,
      hasGap: (fields: FormFields) =>
        fields.transportationSecurityChoice ===
        TransportationSecurityChoice.Yes,
    },
    {
      name: 'utilities',
      label: 'Utilities',
      component: Utilities,
      hasGap: (fields: FormFields) =>
        fields.utilityStatus === UtilityStatus.AlreadyShutOff ||
        fields.utilityStatus === UtilityStatus.Threatened,
    },
    {
      name: 'safety',
      label: 'Safety',
      component: Safety,
      hasGap: hasSafetyGap,
    },
    {
      name: 'physicalActivity',
      label: 'Physical Activity',
      component: PhysicalActivity,
      hasGap: (fields: FormFields) => {
        if (
          fields.exerciseDaysPerWeek === undefined ||
          fields.exerciseMinutesPerDay === undefined
        ) {
          return false;
        }
        const totalMinutesPerWeek =
          fields.exerciseDaysPerWeek * fields.exerciseMinutesPerDay;

        return totalMinutesPerWeek < 150;
      },
    },
  ] as const;
