export type FeatureFlagSet = {
  showDiabetesAndChf: boolean;
  enforcePatientStatusTransitions: boolean;
  showSchedulingSuggestions: boolean;
  showPublishToEhr: boolean;
  showDateRangeFilterWithModal: boolean;
  prospectivePatientsNavigation: boolean;
  includeAiIdentified: boolean;
  disableDraftNoteValidation: boolean;
  showPatientEligibilityInformation: boolean;
  bloodGlucoseP1TrendLabelVeryHigh: string;
  showEplAdvancedProviderFilters: boolean;
  hidePublishToEhr: string[];
  showCumulativeTimeTracker: boolean;
  validatedAlerts: boolean;
  allowOnDemandSync: boolean;
  eplInclusiveLanguage: boolean;
  showHealthSystemListV2: boolean;
  showClaimsUi: boolean;
  showEnrollmentNoteToggle: boolean;
  showAutoGenerateEpl: boolean;
  enableHospitalHelpSmsEdit: boolean;
  petWithGrpc: boolean;
  eplInboxOrderPendingAutomation: boolean;
  eplInboxOrderPendingAutomationHistory: boolean;
  allowMultiSelectForClaims: boolean;
  vitalHealthIndicator: boolean;
  timeTrackingLateEdits: boolean;
  enableRpaChromeExtension: boolean;
  enableZendeskTicketsForEncounterNotes: boolean;
  showHealthSystemThreshold: boolean;
  showPetRpaLink: boolean;
  smsAppointmentsReminders: boolean;
  marketsAndOrders: boolean;
  showConsentUpload: boolean;
  showConsentUploadForEpicOnly: boolean;
  showMaxBpColumn: boolean;
  showInstanceConfigsPage: boolean;
  autopopulateEncounterProblemList: boolean;
  autopopulateEncounterLabs: boolean;
  enableTbdMedManagementDelegation: boolean;
  engagementStreaks: boolean;
  engagementBingo: boolean;
  autoSearchEpicCadenceProviderSer: boolean;
  newEnrollmentUiUpdates: boolean; // Post-release updates to the work featured flagged under showVirtualEnrollmentUi
  allowVirtualEnrollmentWithoutDevices: boolean; // Before Alom enrollment
  showPatientOutreachTab: boolean;
  disablePublishIfEpicProviderIdMissing: boolean;
  showPatientUtilityTab: boolean;
  displayAppointmentNoteInZendeskApp: boolean;
  autopopulateEncounterMedications: boolean;
  showConditionConfidenceFilter: boolean;
  hidePublishToCadenceButton: boolean;
  showAdminToolbox: boolean;
  showAssociationsUiWhenOrder: boolean;
  allowReplacingProcessedConsent: boolean;
  showNewMedicationTable: boolean;
  providerMarketAssociationTab: boolean;
  marketProviderAssociationTab: boolean;
  showTwilioFlexModal: boolean;
  clinicalNavigatorChfExperience: boolean;
  autoConsentGenerationOptOut: (string | number)[];
  showHealthSystemGoals: boolean;
  showDxWarningBanner: boolean;
  showGeneratedClaimsRevenueUnits: boolean;
  allowManualPatientRpmConditionSelection: string[];
  showSuggestProgramOption: boolean;
  structuredClinicalGoalReached: boolean;
  cardiologyProviderSupport: boolean;
  recommendCnVisitsForStableChf: boolean;
  allowManualPatientRpmConditionSelectionForAdminUser: boolean;
  useTwilioForCalls: boolean;
  showCareProviderCommunicationPreferences: boolean;
  showInstancesCampaignsTab: boolean;
  persistAlertResolutionOnAutosave: boolean;
  removeManualTimeTracking: boolean;
  addCallsToEncounter: boolean;
  showScheduleTray: boolean;
  hideNoShowAttempt: boolean;
  appointmentReminders: boolean;
  editTeamRoleMenu: boolean;
  updateAutosavedNotes: boolean;
  showStateLicensureTab: boolean;
  showHomePage: boolean;
  showCcmPreference: boolean;
  showApcmPreference: boolean;
  smartSchedulingInitialVisit: boolean;
  smartSchedulingRegularVisit: boolean;
  smartSchedulingInitialNpVisit: boolean;
  smartSchedulingRegularNpVisit: boolean;
  smartSchedulingRte: boolean;
  smartRescheduling: boolean;
  smartReassignment: boolean;
  multiMrnSupport: boolean;
  asyncTitrations: boolean;
  showNewEplTypes: boolean;
  suggestedTitration: boolean;
  allowPrimaryHospitalUpdates: boolean;
  ccmPatientOutreach: boolean;
  editProviderRole: boolean;
  downloadProviderSummary: boolean;
  showViewEmrAppBtn: boolean;
  submitAppEpl: boolean;
  grow673AllowOnDemandScopedSync: boolean;
  startEncounterFromAppointment: boolean;
  apcmConsents: boolean;
  apcmPatientOutreach: boolean;
  displayExternalCareTeamForPatient: boolean;
  gatherNonTitrationReasons: boolean;
  showApcmProgram: boolean;
  enableScheduledTasks: boolean;
  culturalAndLinguisticFactors: boolean;
  showPerProgramHistory: boolean;
  googleCalendarOauthCheck: boolean;
  showCarePlanSuggestions: boolean;
  allowProgramSelection: boolean;
  carePlanVisitFlowImprovements: boolean;
  carePlanConditionImprovements: boolean;
  showCreateAppointmentLink: boolean;
  showGoalsEncounter: boolean;
  showDischargeEncounter: boolean;
  manualAsyncTitrations: boolean;
  showMultiprogramTab: boolean;
  tasksWeightedSorting: boolean;
  tasksWeightedSortingDebug: boolean;
  careProviderMarketAssociationActiveToggle: boolean;
  useEndEarlyModuleTemplates: boolean;
} & PermanentFlags;

/*
 * These flags are meant to control behavior long-term beyond simply
 * controlling a release rollout.
 */
type PermanentFlags = {
  careModelVersion: CareModelVersion;
  forceClientUpdate: ClientForceUpdate;
  petIdentificationCodesetFilter: ProblemCodeset[];
  rpaAllowedHealthSystems: string[];
  defaultToMyPatientsView: boolean;
  enableManualReadyToEnrollStatusChange: boolean;
  manageChargePublicationPermission: boolean;
  allowResyncInTriageNotes: boolean;
  debugTools: boolean;
  allowChargesApprovedModification: boolean;
  checkProviderSummaryRecord: boolean;
  apcmEncounterTypes: boolean;
  clearReactQueryCacheOnNetworkError: boolean;
};

export enum ClientForceUpdate {
  None = 'none',
  Refresh = 'refresh',
  Logout = 'logout',
}

export enum CareModelVersion {
  V1 = '1.0',
  V2 = '2.0',
  V3 = '3.0',
}

export enum ProblemCodeset {
  UNSPECIFIED = 0,
  ICD10 = 1,
  SNOMED = 2,
}
