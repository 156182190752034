{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>

{{> header val="Product Support" }}
<ul>
  <li>Questions related to enrollment and devices: </li>
  <li>Patient confirms their contact information, emergency contact is correct: </li>
</ul>
<br/>

{{> header val="Problem List" }}
{{> problem_list }}
<br/>

{{#if flags.autopopulateEncounterLabs}}
{{> header val="Lab Results" }}
{{> latest_labs }}
<br/>
{{else}}
{{> header val="Lab Results" }}
{{! Hypertension Only Section }}
{{> subheader val="Last Basic Metabolic Panel (BMP) and Lipid Panel" }}
{{> free_text_space }}
{{! End hypertension Only Section }}
{{/if}}

{{> subheader val="Medication allergies" }}
{{> med_allergies }}

{{> subheader val="Patient preferred pharmacy" }}
{{> free_text_space }}
