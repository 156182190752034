import concat from 'lodash/concat';
import times from 'lodash/times';
import type { IntlShape } from 'react-intl';
import * as yup from 'yup';
import type { TestContext } from 'yup';
import type { InternalOptions } from 'yup/lib/types';

import type { FormConfig } from '@/shared/common/Form';
import type { FieldsMap } from '@/shared/common/Form/form.types';
import { validators } from '@/shared/common/Form/validations';
import { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type {
  CCMCarePlan,
  MedicationAllergy,
  Specialist,
  SurgicalHistory,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import type { ConditionEntry } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { type PatientDemographics } from '@/shared/hooks/usePatientDemographics';
import { convertToCcmCondition } from '@/shared/patient/conditions.utils';
import {
  type CcmCondition,
  Condition,
} from '@/shared/types/clinicalprofile.types';

import { CCM_CONDITION_RESOURCES } from './conditions';
import type { InitialCarePlanConditions } from './sections/GoalsAndInterventions/hooks/useInitialCarePlanConditions.hook';
import { getDefaultPreventativeCareStatus } from './sections/PreventativeCare/preventativeCare.utils';
import {
  HousingConcern,
  HousingConcernsChoice,
  HousingStabilityChoice,
  HousingStabilityDetail,
} from './sections/PsychosocialAssessment/livingSituation.types';
import {
  type DaysPerWeek,
  type MinutesPerDay,
} from './sections/PsychosocialAssessment/physicalActivity.types';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './sections/PsychosocialAssessment/psychoSocialAssessmentOptions';
import {
  SafetyChoice,
  SafetyFrequencyChoice,
} from './sections/PsychosocialAssessment/safety.types';
import { TransportationSecurityChoice } from './sections/PsychosocialAssessment/transportationSecurity.types';
import {
  UtilitySecurityChoice,
  UtilityStatus,
} from './sections/PsychosocialAssessment/utilities.types';
import { PREVENTATIVE_CARE_OPTIONS } from './sections/preventativeCareOptions';
import {
  FoodFrequencyChoice,
  FoodSecurityChoice,
} from './sections/reliableSourceOfFood.types';

export type FormFields = {
  tobaccoUse?: string;
  fastFood?: string;
  snacks?: string;
  desserts?: string;
  regularSoda?: string;
  sweetTea?: string;
  waterIntake?: string;
  saltIntake?: string;
  exercise?: string;
  ccmAppointment?: string;
  providerAppointment?: string;
  medsNotReviewed?: boolean;
  culturalCommunityResources?: string;
  culturalPreferences?: string;
  medicationAllergies?: MedicationAllergy[];
  surgicalHistories?: SurgicalHistory[];
  specialists?: Specialist[];
  emergencyVisits?: EmergencyVisit[];
  goalsAndInterventions?: GoalsAndInterventions[];
  housingStabilityChoice?: HousingStabilityChoice;
  housingStabilityDetail?: HousingStabilityDetail;
  housingConcernsChoice?: HousingConcernsChoice;
  housingConcerns?: HousingConcern[];
  livingSituationNotes?: string;
  foodSecurityChoice?: FoodSecurityChoice;
  foodWorryFrequency?: FoodFrequencyChoice;
  foodRunOutFrequency?: FoodFrequencyChoice;
  foodSecurityNotes?: string;
  transportationSecurityChoice?: TransportationSecurityChoice;
  transportationSecurityNotes?: string;
  utilitySecurityChoice?: UtilitySecurityChoice;
  utilityStatus?: UtilityStatus;
  utilitySecurityNotes?: string;
  safetyChoice?: SafetyChoice;
  safetyNotes?: string;
  physicalHarmFrequency?: SafetyFrequencyChoice;
  threatFrequency?: SafetyFrequencyChoice;
  verbalAbuseFrequency?: SafetyFrequencyChoice;
  insultFrequency?: SafetyFrequencyChoice;
  exerciseDaysPerWeek?: DaysPerWeek;
  exerciseMinutesPerDay?: MinutesPerDay;
  dietNotes?: string;
} & PreventativeCareFields &
  AssessmentFields;

type EmergencyVisit = {
  hospital?: string;
  // Must use empty string for empty date or picker will default to today
  date?: Date | '';
  relatedDiagnosis?: string;
};

export type GoalsAndInterventions = {
  condition?: CcmCondition;
  prognosis?: CCMPrognosis;
  treatmentGoal?: string;
  healthGoal?: string;
  actionSteps?: string;
  confidence?: number;
  coordination?: string;
  acceptsCare?: boolean;
  prognosisNotes?: string;
};

export const EMPTY_GOALS_SECTION: GoalsAndInterventions = {
  condition: undefined,
  prognosis: undefined,
  treatmentGoal: '',
  healthGoal: '',
  actionSteps: '',
  confidence: undefined,
  coordination: '',
};

export const EMPTY_ALLERGIES_SECTION: MedicationAllergy = {
  medication: '',
  reaction: '',
};

export const EMPTY_EMERGENCY_VISIT_SECTION: EmergencyVisit = {
  date: '',
  hospital: '',
  relatedDiagnosis: '',
};

export const EMPTY_PAST_SURGICAL_HISTORY_SECTION: SurgicalHistory = {
  details: '',
  diagnosis: '',
};

export const EMPTY_SPECIALISTS_SECTION: Specialist = {
  name: '',
  specialty: '',
  lastVisit: '',
  nextVisit: '',
};

type ExtractNames<T extends readonly { name: string }[]> = T[number]['name'];

export type PreventativeCareName = ExtractNames<
  typeof PREVENTATIVE_CARE_OPTIONS
>;
export type PreventativeCareFields = {
  [key in `preventativeCare-${PreventativeCareName}-checked`]?: boolean;
} & {
  [key in `preventativeCare-${PreventativeCareName}-date`]?: Date | null;
} & {
  [key in `preventativeCare-${PreventativeCareName}-nextSteps`]?: string | null;
} & {
  [key in `preventativeCare-${PreventativeCareName}-notApplicable`]?: boolean;
} & {
  [key in `preventativeCare-${PreventativeCareName}-status`]?: PreventativeCareStatus;
};

export type AssessmentName = ExtractNames<
  typeof PSYCHOSOCIAL_ASSESSMENT_OPTIONS
>;
export type AssessmentFields = {
  [key in `assessment-${AssessmentName}-checked`]?: boolean;
} & {
  [key in `assessment-${AssessmentName}-description`]?: string;
};

export function getFormConfig(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
  bestThreeConditions: Maybe<ConditionEntry[]>,
  shouldShowCarePlanV1Changes: boolean,
  patientDemographics?: PatientDemographics,
  initialConditions?: InitialCarePlanConditions,
): FormConfig {
  const { required, date, number, enumType } = validators(intl);

  return {
    fields: {
      tobaccoUse: {
        defaultValue: fieldValues.tobaccoUse ?? '',
        validation: required(yup.string()),
      },
      ...(shouldShowCarePlanV1Changes
        ? {
            dietNotes: {
              defaultValue: fieldValues.dietNotes ?? '',
              validation: required(yup.string()),
            },
          }
        : {
            fastFood: {
              defaultValue: fieldValues.fastFood ?? '',
              validation: required(yup.string()),
            },
            snacks: {
              defaultValue: fieldValues.snacks ?? '',
              validation: required(yup.string()),
            },
            desserts: {
              defaultValue: fieldValues.desserts ?? '',
              validation: required(yup.string()),
            },
            regularSoda: {
              defaultValue: fieldValues.regularSoda ?? '',
              validation: required(yup.string()),
            },
            sweetTea: {
              defaultValue: fieldValues.sweetTea ?? '',
              validation: required(yup.string()),
            },
            waterIntake: {
              defaultValue: fieldValues.waterIntake ?? '',
              validation: required(yup.string()),
            },
            saltIntake: {
              defaultValue: fieldValues.saltIntake ?? '',
              validation: required(yup.string()),
            },
            exercise: {
              defaultValue: fieldValues.exercise ?? '',
              validation: required(yup.string()),
            },
          }),
      ccmAppointment: {
        defaultValue: fieldValues.ccmAppointment ?? '',
        validation: required(yup.string()),
      },
      providerAppointment: {
        defaultValue: fieldValues.providerAppointment ?? '',
        validation: required(yup.string()),
      },
      surgicalHistories: {
        defaultValue: fieldValues.surgicalHistories ?? [
          { ...EMPTY_PAST_SURGICAL_HISTORY_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            details: yup.string(),
            diagnosis: yup.string(),
          }),
        ),
      },
      specialists: {
        defaultValue: fieldValues.specialists ?? [
          { ...EMPTY_SPECIALISTS_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            name: yup.string(),
            specialty: yup.string(),
            lastVisit: yup.string(),
            nextVisit: yup.string(),
          }),
        ),
      },
      medsNotReviewed: {
        defaultValue: fieldValues.medsNotReviewed ?? false,
        validation: yup.boolean(),
      },
      medicationAllergies: {
        defaultValue: fieldValues.medicationAllergies ?? [
          { ...EMPTY_ALLERGIES_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            medication: required(yup.string()),
            reaction: yup.string(),
          }),
        ),
      },
      emergencyVisits: {
        defaultValue: fieldValues.emergencyVisits ?? [
          { ...EMPTY_EMERGENCY_VISIT_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            hospital: required(yup.string()),
            date: required(date()),
            relatedDiagnosis: required(yup.string()),
          }),
        ),
      },
      goalsAndInterventions: {
        defaultValue: fieldValues.goalsAndInterventions?.length
          ? fieldValues.goalsAndInterventions
          : getDefaultGoalsAndInterventions(
              bestThreeConditions,
              initialConditions,
            ),
        validation: yup
          .array()
          .of(
            yup.object().shape({
              condition: required(
                enumType({ source: Condition, pluck: 'values' }),
              ).test(
                'unique-condition',
                intl.formatMessage({
                  defaultMessage: 'Condition must be unique',
                }),
                (value: string | undefined, context: TestContext) => {
                  // https://github.com/jquense/yup/issues/398#issuecomment-916693907
                  const options = context.options as InternalOptions;
                  const { from: parents } = options;
                  const allConditions = (
                    parents?.[1].value as CCMCarePlan['carePlan']
                  )?.goalsAndInterventions
                    ?.map((item) => item.condition)
                    .filter(Boolean);

                  const conditionOccurrences =
                    allConditions?.filter((cond) => value && cond === value)
                      .length || 0;

                  return conditionOccurrences <= 1;
                },
              ),
              prognosis: required(
                enumType({ source: CCMPrognosis, pluck: 'values' }),
              ),
              treatmentGoal: required(yup.string()),
              healthGoal: required(yup.string()),
              actionSteps: required(yup.string()),
              confidence: number({ min: 1, max: 10 }).nullable(),
              coordination: required(yup.string()),
              acceptsCare: yup.boolean(),
              prognosisNotes: yup.string(),
            }),
          )
          .min(
            2,
            intl.formatMessage({
              defaultMessage: 'At least two conditions are required',
            }),
          ),
      },
      ...getPreventativeCareFields(
        intl,
        fieldValues,
        shouldShowCarePlanV1Changes,
        patientDemographics,
      ),
      ...getAssessmentFields(intl, fieldValues),
      culturalPreferences: {
        validation: required(yup.string()),
        defaultValue: fieldValues.culturalPreferences,
      },
      culturalCommunityResources: {
        validation: required(yup.string()),
        defaultValue: fieldValues.culturalCommunityResources,
      },
      ...(shouldShowCarePlanV1Changes
        ? getAssessmentFieldsV2(intl, fieldValues)
        : {}),
    },
  };
}

export function getPreventativeCareFieldNames(baseName: PreventativeCareName) {
  const baseFieldName = `preventativeCare-${baseName}`;

  return {
    checked: `${baseFieldName}-checked` as keyof PreventativeCareFields,
    date: `${baseFieldName}-date` as keyof PreventativeCareFields,
    nextSteps: `${baseFieldName}-nextSteps` as keyof PreventativeCareFields,
    notApplicable:
      `${baseFieldName}-notApplicable` as keyof PreventativeCareFields,
    status: `${baseFieldName}-status` as keyof PreventativeCareFields,
  };
}

function getPreventativeCareFields(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
  shouldShowCarePlanV1Changes: boolean,
  patientDemographics?: PatientDemographics,
): FieldsMap {
  const { required } = validators(intl);

  return PREVENTATIVE_CARE_OPTIONS.reduce((fields, { name, notApplicable }) => {
    const {
      checked: checkedFieldName,
      date: dateFieldName,
      nextSteps: nextStepsFieldName,
      notApplicable: notApplicableFieldName,
      status: statusFieldName,
    } = getPreventativeCareFieldNames(name);

    const isNotApplicable = Boolean(
      patientDemographics && notApplicable?.(patientDemographics),
    );

    return {
      ...fields,
      ...(shouldShowCarePlanV1Changes
        ? {
            [dateFieldName]: {
              defaultValue: fieldValues[dateFieldName] ?? '',
              validation: yup.string().when(statusFieldName, {
                is: PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE,
                then: (schema) => required(schema),
              }),
            },
            [statusFieldName]: {
              defaultValue:
                fieldValues[statusFieldName] &&
                fieldValues[statusFieldName] !==
                  PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UNSPECIFIED
                  ? fieldValues[statusFieldName]
                  : getDefaultPreventativeCareStatus(
                      isNotApplicable,
                      fieldValues[dateFieldName] as string,
                      name,
                    ),
            },
            [notApplicableFieldName]: {
              defaultValue: isNotApplicable,
            },
            [nextStepsFieldName]: {
              defaultValue: fieldValues[nextStepsFieldName] ?? '',
              validation: yup
                .string()
                .trim()
                .when(statusFieldName, {
                  is: PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED,
                  then: (schema) => required(schema),
                }),
            },
          }
        : {
            [checkedFieldName]: {
              defaultValue: fieldValues[checkedFieldName] ?? false,
            },
            [dateFieldName]: {
              defaultValue: fieldValues[dateFieldName] ?? '',
              validation: yup.string().when(checkedFieldName, {
                is: true,
                then: (schema) => required(schema),
              }),
            },
            [notApplicableFieldName]: {
              defaultValue: fieldValues[notApplicableFieldName] ?? false,
            },
            [nextStepsFieldName]: {
              defaultValue: fieldValues[nextStepsFieldName] ?? '',
            },
          }),
    };
  }, {});
}

export function getAssessmentFieldNames(baseName: AssessmentName) {
  const baseFieldName = `assessment-${baseName}`;

  return {
    checked: `${baseFieldName}-checked` as keyof AssessmentFields,
    description: `${baseFieldName}-description` as keyof AssessmentFields,
  };
}

function getAssessmentFields(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
): FieldsMap {
  const { required } = validators(intl);

  return PSYCHOSOCIAL_ASSESSMENT_OPTIONS.reduce((fields, { name }) => {
    const { checked: checkedFieldName, description: descriptionFieldName } =
      getAssessmentFieldNames(name);

    return {
      ...fields,
      [checkedFieldName]: {
        defaultValue: fieldValues[checkedFieldName] ?? false,
      },
      [descriptionFieldName]: {
        defaultValue: fieldValues[descriptionFieldName] ?? '',
        validation: yup
          .string()
          .trim()
          .when(checkedFieldName, {
            is: true,
            then: (schema) => required(schema),
          }),
      },
    };
  }, {});
}

function getAssessmentFieldsV2(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
): FieldsMap {
  const { required, enumType } = validators(intl);

  return {
    housingStabilityChoice: {
      defaultValue: fieldValues.housingStabilityChoice,
      validation: required(
        enumType({ source: HousingStabilityChoice, pluck: 'values' }),
      ),
    },
    housingStabilityDetail: {
      defaultValue: fieldValues.housingStabilityDetail,
      validation: yup.string().when('housingStabilityChoice', {
        is: HousingStabilityChoice.Yes,
        then: enumType({ source: HousingStabilityDetail, pluck: 'values' }),
      }),
    },
    housingConcernsChoice: {
      defaultValue: fieldValues.housingConcernsChoice,
      validation: required(
        enumType({ source: HousingConcernsChoice, pluck: 'values' }),
      ),
    },
    housingConcerns: {
      defaultValue: fieldValues.housingConcerns ?? [],
      validation: yup.array().when('housingConcernsChoice', {
        is: HousingConcernsChoice.Yes,
        then: required(
          yup.array().of(enumType({ source: HousingConcern, pluck: 'values' })),
        ),
      }),
    },
    livingSituationNotes: {
      defaultValue: fieldValues.livingSituationNotes ?? '',
      validation: yup.string().nullable(),
    },
    foodSecurityChoice: {
      defaultValue: fieldValues.foodSecurityChoice,
      validation: required(
        enumType({ source: FoodSecurityChoice, pluck: 'values' }),
      ),
    },
    foodWorryFrequency: {
      defaultValue: fieldValues.foodWorryFrequency,
      validation: yup.string().when('foodSecurityChoice', {
        is: FoodSecurityChoice.Yes,
        then: required(
          enumType({ source: FoodFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    foodRunOutFrequency: {
      defaultValue: fieldValues.foodRunOutFrequency,
      validation: yup.string().when('foodSecurityChoice', {
        is: FoodSecurityChoice.Yes,
        then: required(
          enumType({ source: FoodFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    foodSecurityNotes: {
      defaultValue: fieldValues.foodSecurityNotes ?? '',
      validation: yup.string().nullable(),
    },
    transportationSecurityChoice: {
      defaultValue: fieldValues.transportationSecurityChoice,
      validation: required(
        enumType({ source: TransportationSecurityChoice, pluck: 'values' }),
      ),
    },
    transportationSecurityNotes: {
      defaultValue: fieldValues.transportationSecurityNotes ?? '',
      validation: yup.string().nullable(),
    },
    utilitySecurityChoice: {
      defaultValue: fieldValues.utilitySecurityChoice,
      validation: required(
        enumType({ source: UtilitySecurityChoice, pluck: 'values' }),
      ),
    },
    utilityStatus: {
      defaultValue: fieldValues.utilityStatus,
      validation: yup.string().when('utilitySecurityChoice', {
        is: UtilitySecurityChoice.Yes,
        then: required(enumType({ source: UtilityStatus, pluck: 'values' })),
      }),
    },
    utilitySecurityNotes: {
      defaultValue: fieldValues.utilitySecurityNotes ?? '',
      validation: yup.string().nullable(),
    },
    safetyChoice: {
      defaultValue: fieldValues.safetyChoice,
      validation: required(enumType({ source: SafetyChoice, pluck: 'values' })),
    },
    safetyNotes: {
      defaultValue: fieldValues.safetyNotes ?? '',
      validation: yup.string().nullable(),
    },
    physicalHarmFrequency: {
      defaultValue: fieldValues.physicalHarmFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    threatFrequency: {
      defaultValue: fieldValues.threatFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    verbalAbuseFrequency: {
      defaultValue: fieldValues.verbalAbuseFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    insultFrequency: {
      defaultValue: fieldValues.insultFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    exerciseDaysPerWeek: {
      defaultValue: fieldValues.exerciseDaysPerWeek,
      validation: yup.number().oneOf([0, 1, 2, 3, 4, 5, 6, 7]).required(),
    },
    exerciseMinutesPerDay: {
      defaultValue: fieldValues.exerciseMinutesPerDay,
      validation: yup.number().when('exerciseDaysPerWeek', {
        is: (val: number) => val !== 0,
        then: yup
          .number()
          .oneOf([0, 10, 20, 30, 40, 50, 60, 90, 120, 150])
          .required(),
      }),
    },
  };
}

export function getDefaultGoalsAndInterventions(
  bestThreeConditions: Maybe<ConditionEntry[]>,
  initialConditions: Maybe<InitialCarePlanConditions>,
): GoalsAndInterventions[] {
  let goals: GoalsAndInterventions[] = [];
  if (initialConditions) {
    goals = Array.from(initialConditions)
      .map((c) => ({
        ...c,
        condition: convertToCcmCondition(c.condition),
      }))
      .filter(
        (c): c is { condition: CcmCondition; prognosis: CCMPrognosis } =>
          !!c.condition,
      )
      .map((c) => ({
        ...EMPTY_GOALS_SECTION,
        condition: c.condition,
        prognosis: c.prognosis,
        treatmentGoal: c.condition
          ? CCM_CONDITION_RESOURCES[c.condition].goal
          : '',
      }));
  } else {
    const conditions = bestThreeConditions
      ?.map((ce) =>
        ce.conditionType ? convertToCcmCondition(ce.conditionType) : null,
      )
      .filter((c): c is CcmCondition => !!c);

    goals =
      conditions?.map((condition) => ({
        ...EMPTY_GOALS_SECTION,
        condition,
        treatmentGoal: condition ? CCM_CONDITION_RESOURCES[condition].goal : '',
      })) || [];
  }
  return ensureMinimumGoals(goals, 2);
}

// Helper function to ensure a minimum number of goals by padding with EMPTY_GOALS_SECTION
function ensureMinimumGoals(
  goals: GoalsAndInterventions[],
  min: number,
): GoalsAndInterventions[] {
  const missingCount = Math.max(0, min - goals.length);
  return concat(
    goals,
    times(missingCount, () => EMPTY_GOALS_SECTION),
  );
}
