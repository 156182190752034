{{! this template is used in both initialCnVisit/t2d and initialCnVisit/t2dAndHtn }}
{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>

{{> header val="Product Support" }}
<ul>
  <li>Questions related to enrollment and devices: </li>
  <li>Patient confirms their contact information, emergency contact is correct: </li>
</ul>
<br/>

{{> header val="Problem List" }}
{{> problem_list }}
<br/>

{{#if flags.autopopulateEncounterLabs}}
{{> header val="Lab Results" }}
{{> latest_labs }}
<br/>
{{else}}
{{> header val="Lab Results" }}
{{! t2d, t2d+htn only }}
{{> subheader val="Last A1C, Basic Metabolic Panel (BMP) and Lipid Panel" }}
{{> free_text_space }}
{{! /t2d, t2d+htn only }}
{{/if}}

{{> subheader val="Medication allergies" }}
{{> med_allergies }}

{{> subheader val="Patient preferred pharmacy" }}
{{> free_text_space }}
