{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>

{{> subheader val="Problem List" }}
{{> problem_list }}
<br/>

{{#if flags.autopopulateEncounterLabs}}
{{> header val="Lab Results" }}
{{> latest_labs }}
<br/>
{{else}}
{{! T2d / T2d+Hypertension Only Section }}
{{> subheader val="Last A1C, Basic Metabolic Panel (BMP), urinary studies, and Lipid Panel" }}
{{> free_text_space }}
{{! End T2d / T2d+Hypertension Only Section }}
{{/if}}

{{> subheader val="Medication allergies" }}
{{> med_allergies }}
