import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box } from '@/deprecated/mui';
import { LabelledCheckbox } from '@/shared/common/LabelledCheckbox';
import { TextArea } from '@/shared/common/TextArea';
import {
  useInstance,
  usePatientDetails,
  usePatientHospitals,
} from '@/shared/hooks/queries';
import { flexSection } from '@/shared/jsStyle';
import { color } from '@/shared/tempo/theme';
import { EHR } from '@/shared/types/ehr.types';
import type { RouteParam } from '@/shared/types/route.types';

import { NoteCareProviderSearchBar } from '../NoteCareProviderSearchBar';
import { ErrorMessage } from './ErrorMessage';

export const NoteEditorFooter = ({
  actionRequired,
  urgent,
  externalProviderId,
  escalationMessage,
  clearNoteExternalProviderId,
  onActionRequiredChange,
  onUrgentChange,
  setNoteExternalProviderId,
  onEscalationMessageChange,
  hasError,
}: {
  actionRequired: boolean;
  urgent: boolean;
  externalProviderId?: string;
  escalationMessage?: Nullable<string>;
  clearNoteExternalProviderId: () => void;
  onActionRequiredChange: (value: boolean) => void;
  onUrgentChange: (value: boolean) => void;
  setNoteExternalProviderId: (id: string) => void;
  onEscalationMessageChange: (value: string) => void;
  hasError: boolean;
}) => {
  const { patientId }: RouteParam = useParams();
  const intl = useIntl();
  const { data: patientDetails } = usePatientDetails(patientId, false);
  const isEpicPatient = patientDetails?.ehr_information?.ehr === EHR.Epic;

  const { data: hospitalsResponse } = usePatientHospitals(
    {
      patientId,
      pageSize: 1,
      page: 1,
    },
    {
      enabled: !!patientId && isEpicPatient,
    },
  );
  const instanceId =
    hospitalsResponse?.hospitals?.[0]?.ehr_information?.instance_id;
  const { data: instance } = useInstance(instanceId || '', {
    enabled: !!instanceId && isEpicPatient,
  });

  const epicHasAssignedProvider =
    instance?.epicConfig?.actionableNotesAssignedProvider;

  const providerSearchDisabled =
    !actionRequired || (isEpicPatient && !epicHasAssignedProvider);

  const showEscalationMessage = !(isEpicPatient && providerSearchDisabled);

  const renderProviderSearchMessage = () => {
    const messageColor = externalProviderId ? 'green' : 'red';

    let defaultMessage;
    if (externalProviderId) {
      defaultMessage = (
        <FormattedMessage defaultMessage="Provider selection saved" />
      );
    } else if (isEpicPatient && !epicHasAssignedProvider) {
      defaultMessage = (
        <FormattedMessage defaultMessage="Provider selection disabled for Epic patients." />
      );
    } else {
      defaultMessage = <ErrorMessage hasError={hasError} />;
    }

    return (
      <Box
        sx={{
          ...flexSection('row', 'flex-start', 'center'),
          fontSize: '13px',
          color: messageColor,
        }}
      >
        {defaultMessage}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...flexSection('column', 'center', 'center'),
        width: '100%',
      }}
    >
      <Box
        sx={{
          ...flexSection('column', 'flex-end', 'center'),
          width: '100%',
          ...(hasError && {
            fieldset: { borderColor: color.Theme.Light.Danger },
          }),
        }}
      >
        <Box
          sx={{
            minWidth: '100%',
            opacity: !providerSearchDisabled ? '1' : '0.5',
          }}
        >
          <NoteCareProviderSearchBar
            patientId={patientId}
            disabled={providerSearchDisabled}
            onClose={clearNoteExternalProviderId}
            onSelect={setNoteExternalProviderId}
            selectedValue={externalProviderId}
          />
          {(actionRequired && renderProviderSearchMessage()) || <div />}
        </Box>
        {showEscalationMessage && (
          <Box
            sx={{
              minWidth: '100%',
              opacity: !providerSearchDisabled ? '1' : '0.5',
              marginTop: '10px',
            }}
          >
            <TextArea
              expand="auto"
              placeholder={intl.formatMessage({
                defaultMessage:
                  'Include an escalation message for the provider.',
              })}
              value={escalationMessage ?? undefined}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onEscalationMessageChange(e.target.value)
              }
              disabled={providerSearchDisabled}
              required={actionRequired}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ...flexSection('column', 'flex-end', 'flex-end', 'inline-flex'),
          width: '100%',
        }}
      >
        <Box
          sx={{
            ...flexSection('row', 'flex-end', 'center'),
            width: '100%',
            marginTop: '3px',
          }}
        >
          <div>
            <LabelledCheckbox
              checked={actionRequired}
              label={<FormattedMessage defaultMessage="Action required" />}
              id="add-note-form-checkbox"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onActionRequiredChange(e.target.checked)
              }
              fontSize="12px"
            />
            {actionRequired && (
              <>
                <LabelledCheckbox
                  checked={Boolean(urgent)}
                  label={<FormattedMessage defaultMessage="Urgent" />}
                  id="add-note-form-urgent-checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onUrgentChange(e.target.checked)
                  }
                  fontSize="12px"
                />
              </>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
};
