import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';

import type { FormFields } from '../../formConfig';
import {
  actionNeededFill,
  actionNeededLabel,
  actionNeededStroke,
  declinedFill,
  declinedLabel,
  declinedStroke,
  radioContainer,
  radioLabel,
  upToDateFill,
  upToDateLabel,
  upToDateStroke,
} from '../../radioStyles.css';

type Props = {
  name: keyof FormFields;
  isDisabled?: boolean;
};

export function PreventativeCareStatusField({
  name,
  isDisabled,
}: Props): ReactElement {
  return (
    <Form.RadioGroup
      name={name}
      orientation="horizontal"
      size={12}
      isDisabled={isDisabled}
      classes={{ root: radioContainer }}
    >
      <Form.Radio
        value={PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED}
        classes={{
          label: radioLabel,
          selectedLabel: actionNeededLabel,
          selectedDot: actionNeededFill,
          selectedCircle: actionNeededStroke,
        }}
      >
        <FormattedMessage defaultMessage="Action Required" />
      </Form.Radio>
      <Form.Radio
        value={PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_DECLINED}
        classes={{
          label: radioLabel,
          selectedLabel: declinedLabel,
          selectedDot: declinedFill,
          selectedCircle: declinedStroke,
        }}
      >
        <FormattedMessage defaultMessage="Declined" />
      </Form.Radio>
      <Form.Radio
        value={PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE}
        classes={{
          label: radioLabel,
          selectedLabel: upToDateLabel,
          selectedDot: upToDateFill,
          selectedCircle: upToDateStroke,
        }}
      >
        <FormattedMessage defaultMessage="Up-to-Date" />
      </Form.Radio>
    </Form.RadioGroup>
  );
}
