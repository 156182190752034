export enum SafetyChoice {
  No = 'NO',
  Yes = 'YES',
}

export enum SafetyFrequencyChoice {
  Never = 'NEVER',
  Rarely = 'RARELY',
  Sometimes = 'SOMETIMES',
  Frequently = 'FREQUENTLY',
  FairlyOften = 'FAIRLY_OFTEN',
}
