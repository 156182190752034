import { convertToRaw } from 'draft-js';
import Handlebars from 'handlebars';

import type { TNoteBodyRTF } from '@/shared/types/note.types';

import { convertHtmlToDraftJsEditorState } from '../../utils/noteConverters';
import type { CarePlanContext } from '../VisitLayout/CarePlanButton/hooks/useCarePlanTemplateContext';
import { CarePlanTemplateHelpers } from './carePlanTemplateHelpers';
import {
  freeTextSpacePartial,
  headerPartial,
  problemListPartial,
  subheaderPartial,
} from './files/partials';
import type { TemplateDelegate } from './types';

export const CARE_PLAN_TEMPLATE_PARTIALS = {
  problem_list: textFieldTemplate(problemListPartial),
  free_text_space: textFieldTemplate(freeTextSpacePartial),

  // Helper partials
  header: textFieldTemplate(headerPartial),
  subheader: textFieldTemplate(subheaderPartial),
};

const careTemplateEngine = (() => {
  const instance = Handlebars.create();
  instance.registerHelper(CarePlanTemplateHelpers);
  instance.registerPartial(CARE_PLAN_TEMPLATE_PARTIALS);
  return instance;
})();

export function carePlanDraftJsFieldTemplate(
  template: TemplateSpecification,
): TemplateDelegate<TNoteBodyRTF, CarePlanContext> {
  return (context: CarePlanContext) => {
    const templateText = careTemplateEngine.template(template)(context);

    return convertToRaw(
      convertHtmlToDraftJsEditorState(templateText).getCurrentContent(),
    );
  };
}

function textFieldTemplate(
  template: TemplateSpecification,
): TemplateDelegate<string, CarePlanContext> {
  return (context: CarePlanContext) =>
    careTemplateEngine.template(template)(context);
}
