import omitBy from 'lodash/omitBy';
import type { UseInfiniteQueryOptions } from 'react-query';
import { useInfiniteQuery, useMutation } from 'react-query';

import type { SortState } from '@/shared/common/Table';
import { useTableSort } from '@/shared/common/Table';
import { useFlags } from '@/shared/hooks';
import type { ProspectivePatientsSortField } from '@/shared/hooks/queries';
import {
  useEplProspectivePatients,
  useInvalidateProspectivePatients,
  useProspectivePatients,
} from '@/shared/hooks/queries';
import type { ClinicalProfile } from '@/shared/types/clinicalprofile.types';
import type { ConditionProgram } from '@/shared/types/condition.types';
import type { ProblemCodeset } from '@/shared/types/featureFlags.types';
import type { Hospital } from '@/shared/types/hospital.types';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type {
  EplProspectivePatient,
  IdType,
  PatientStatus,
  ProspectivePatient,
  ProspectivePatientClinicalProfile,
  StatusChangeReason,
} from '@/shared/types/patient.types';
import Session from '@/shared/utils/session';

import type { PresetPatientView } from './filters';

export type StatusNoteUpdatePayload = {
  reasons: StatusChangeReason[];
  reason_description?: string;
  status_change_date?: string;
};

export type StatusUpdatePayload = {
  status: PatientStatus;
  status_note: StatusNoteUpdatePayload;
};

export function useUpdateStatus(patientId: string) {
  const invalidateProspectivePatients = useInvalidateProspectivePatients();
  const { enforcePatientStatusTransitions } = useFlags();
  const queryStr = !enforcePatientStatusTransitions ? '?force_status=true' : '';
  return useMutation(
    (payload: StatusUpdatePayload) =>
      Session.Api.patch<ProspectivePatient>(
        `/pms/api/v1/patients/${patientId}${queryStr}`,
        payload,
      ),
    {
      onSuccess: async () => {
        await invalidateProspectivePatients();
      },
    },
  );
}

export function useSetPatientBoolField(
  patientId: string,
  field: 'rpm_ordered' | 'is_prepped',
) {
  const invalidateProspectivePatients = useInvalidateProspectivePatients();
  return useMutation(
    (value: boolean) =>
      Session.Api.patch<ProspectivePatient>(
        `/pms/api/v1/patients/${patientId}`,
        {
          [field]: value,
        },
      ),
    {
      onSuccess: async () => {
        await invalidateProspectivePatients();
      },
    },
  );
}

export function useUpdateClinicalProfile(patientId: string) {
  const invalidateProspectivePatients = useInvalidateProspectivePatients();
  return useMutation(
    (payload: ClinicalProfile | ProspectivePatientClinicalProfile) =>
      Session.Api.put(
        `/pms/api/v1/patients/${patientId}/clinical-profile`,
        payload,
      ),
    {
      onSuccess: async () => {
        await invalidateProspectivePatients();
      },
    },
  );
}

export enum AcuityTierThreshold {
  Tier1 = 'TIER_1',
  Tier2 = 'TIER_2',
  Tier3 = 'TIER_3',
  AllPatients = 'ALL_PATIENTS',
}

export enum ConditionConfidencePreference {
  HighOnly = 'HIGH_ONLY',
  AllPatients = 'ALL_PATIENTS',
}

export type ProspectivePatientsFilter = {
  fnormrn?: string; // Full name or MRN to search for
  hospital_id?: string[];
  status?: PatientStatus | PatientStatus[] | '';
  status_reason?: StatusChangeReason | '';
  status_date_start?: string;
  status_date_end?: string;
  appt_from?: string;
  appt_to?: string;
  last_clinic_appt_from?: string;
  last_clinic_appt_to?: string;
  condition?: ConditionProgram[];
  npi_id?: string[];
  usual_provider_name?: string[];
  care_provider_id?: string[]; // clinic appt provider
  identification_type?: IdType | '';
  preset_patient_view?: PresetPatientView;
  identification_codeset?: ProblemCodeset[];
  include_ai_identified?: boolean;
  id?: string;
  only_has_secondary_insurance?: boolean;
  acuity_tier?: AcuityTierThreshold;
  condition_confidence?: ConditionConfidencePreference;
  address_region?: AddressRegion[];
  scheduling_care_provider_name?: string;
  last_clinic_appt_provider_name?: string;
  identified_provider_npi_id?: number[];
};

export type AddressRegion =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY';

export function cleanProspectivePatientsFilter(
  filter: ProspectivePatientsFilter,
) {
  return omitBy(filter, (value, key) => {
    // when searching by fnormrn or id, we're disabling date range filters in the UI,
    // so we exclude those from the filters
    if (
      (filter.fnormrn?.length || filter.id?.length) &&
      [
        'status_date_start',
        'status_date_end',
        'last_clinic_appt_from',
        'last_clinic_appt_to',
        'appt_from',
        'appt_to',
      ].includes(key)
    ) {
      return true;
    }

    // This filter option is used to display preset filter views but does not
    // need to be sent to the BE
    if (key === 'preset_patient_view') {
      return true;
    }

    return (
      typeof value === 'undefined' ||
      (typeof value !== 'boolean' && !value?.length)
    );
  });
}

export function useTrackerProspectivePatientCountForStatus(
  status: PatientStatus,
) {
  const { petIdentificationCodesetFilter } = useFlags();
  const { data } = useTrackerProspectivePatients({
    status,
    identification_codeset: petIdentificationCodesetFilter,
  });
  return data?.pages?.[0].metadata.total;
}

export function useTrackerProspectivePatients(
  filter: ProspectivePatientsFilter,
  sort: SortState<ProspectivePatientsSortField> = {},
) {
  return useProspectivePatients({
    ...(sort.sortKey && { sort_by: sort.sortKey }),
    ...(sort.sortDir && { order_by: sort.sortDir }),
    ...cleanProspectivePatientsFilter(filter),
  });
}

export function useHospitals() {
  return useInfiniteQuery<PaginatedData<Hospital, 'hospitals'>>([
    '/pms/api/v1/hospitals',
    { sort_by: 'name', order_by: 'asc' },
    'infinite',
  ]);
}

export function useEplPatients(
  eplId: string,
  config?: UseInfiniteQueryOptions<PaginatedData<EplProspectivePatient>>,
) {
  const { sortState: patientSort, handleSort: onSortChange } =
    useTableSort<ProspectivePatientsSortField>({
      sortKey: ['upcoming_appointment_date'],
      sortDir: ['asc'],
    });
  return {
    ...useEplProspectivePatients(
      {
        nab_list_id: eplId,
        ...(patientSort.sortKey && { sort_by: patientSort.sortKey }),
        ...(patientSort.sortDir && { order_by: patientSort.sortDir }),
      },
      config,
    ),
    patientSort,
    onSortChange,
  };
}
