import {
  EncounterModuleId,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { draftJsFieldTemplate } from '../../../../template.utils';
import { endEarlyTemplates } from './endEarly';
import patientNotesBody from './patient_notes.hbs';

const SHARED_MODULES = {
  [EncounterModuleId.PatientNotes]: {
    notes_body: draftJsFieldTemplate(patientNotesBody),
    endEarly: endEarlyTemplates,
  },
};

export const dischargeFollowup = {
  [TypeOfEncounter.DISCHARGE_FOLLOWUP]: {
    [ConditionProgram.CCM]: { ...SHARED_MODULES },
    [ConditionProgram.APCM]: { ...SHARED_MODULES },
  },
};
