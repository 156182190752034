import { useIntl } from 'react-intl';

import type { ChangeEndEncounterState } from '../NoteEditorHeaderAndDialogs';
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';

type Props = {
  state: ChangeEndEncounterState;
};

export function EndEarlyNotesConfirmationDialog({ state }: Props) {
  const intl = useIntl();
  if (!state.modalOpen) {
    return null;
  }
  const { modalOpen: isOpen, onConfirm, onCancel } = state;

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      dialogTitle={intl.formatMessage({
        defaultMessage: 'End encounter notes',
      })}
      dialogDescription={intl.formatMessage({
        defaultMessage:
          "The end reason you've selected will automatically update the patient notes with a new template. Are you sure you want to end the encounter?",
      })}
      cancelButtonText={intl.formatMessage({
        defaultMessage: 'Close',
      })}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'End encounter',
      })}
    />
  );
}
