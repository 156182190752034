import { EndEncounterReason } from '@/shared/types/note.types';

import { draftJsFieldTemplate } from '../../../../../template.utils';
import disenrollmentTemplate from './disenrollment.hbs';
import hospitalizedTemplate from './hospitalized.hbs';
import requestedCallbackTemplate from './requested_callback.hbs';
import unableToReachTemplate from './unable_to_reach.hbs';

export const endEarlyTemplates = {
  [EndEncounterReason.UnabletoReachPatient]: {
    notes_body: draftJsFieldTemplate(unableToReachTemplate),
  },
  [EndEncounterReason.PatientRequestedCallback]: {
    notes_body: draftJsFieldTemplate(requestedCallbackTemplate),
  },
  [EndEncounterReason.PatientHospitalized]: {
    notes_body: draftJsFieldTemplate(hospitalizedTemplate),
  },
  [EndEncounterReason.Disenrollment]: {
    notes_body: draftJsFieldTemplate(disenrollmentTemplate),
  },
};
