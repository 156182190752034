import { EndEncounterType } from '@/shared/types/note.types';

import {
  EncounterModuleId,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
} from '../../Notes.types';
import type { EndFormValues } from '../noteFormState';

/**
 * Sets endEncounter data for the EncounterType module.
 * @param encounterModuleInstances The current list of encounter module instances.
 * @param onChangeInstance Function to update a module instance.
 * @param endEncounter The new data to set.
 */
export function setEndEncounter(
  endEncounter: Nullable<EndFormValues>,
  encounterModuleInstances: Array<EncounterModuleInstance<EncounterTypeInputs>>,
  onChangeInstance: (
    instance: EncounterModuleInstance<EncounterTypeInputs>,
  ) => void,
) {
  const instance = encounterModuleInstances.find(
    (i) => i.encounter_module_id === EncounterModuleId.EncounterType,
  );

  if (instance) {
    const updatedInstance: EncounterModuleInstance<EncounterTypeInputs> = {
      ...instance,
      inputs: {
        ...instance.inputs,
        patient_no_show: endEncounter?.endType === EndEncounterType.NoShow, // maintain for backwards compatability ... we should update this to a "no_contact" though.
        end_encounter_type: endEncounter?.endType ?? undefined,
        end_encounter_reason: endEncounter?.endReason ?? undefined,
      },
    };
    onChangeInstance(updatedInstance);
  }
}
