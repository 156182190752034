// TODO: Delete this file once RPM program status is stable
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  ModalParams,
  ModalTypes,
} from '@/pages/adminPanel/patient-profile/RoutedModals';
import { EllipseIcon } from '@/shared/assets/icons/Ellipse';
import {
  type PatientDetails,
  PatientStatusEnum,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  grpcPatientStatusToEnum,
  usePatientStatusMap,
} from '@/shared/patient/patientStatus';
import {
  isDisenrolledFromAllPrograms,
  isParticipatingInProgramType,
} from '@/shared/patient/programs.utils';
import { getProgramTypeI18n } from '@/shared/patient/useProgramI18n';
import { Badge } from '@/shared/tempo/atom/Badge';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import {
  badge as badgeCss,
  dot,
  label,
  marginRightXxs,
} from './ProgramStatusTag.css';
import {
  type ProgramTagVariant,
  getConditionBadges,
  getProspectiveStatusVariant,
  isEnrolledNotActivated,
  isSupportedProspectiveStatus,
} from './programStatusTag.utils';

type Props = {
  program: ProgramType;
  patientDetails: PatientDetails;
  className?: string;
  view?: 'default' | 'admin';
};

export function TempRpmProgramStatusTag({
  program,
  className,
  patientDetails,
  view = 'default',
}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const isAdminView = view === 'admin';
  const patientStatus =
    patientDetails.patient?.status ?? PatientStatusEnum.NOT_ELIGIBLE;
  const isTopLevelEnrolled = patientStatus === PatientStatusEnum.ENROLLED;

  const handleModalOpen = () => {
    if (patientDetails?.name) {
      const modalParams = new URLSearchParams({
        [ModalParams.Modal]: ModalTypes.StatusChange,
        [ModalParams.PatientId]: patientDetails.name,
      });
      history.push({ search: modalParams.toString() });
    }
  };

  const showProspectiveProgram =
    isAdminView && isSupportedProspectiveStatus(patientStatus);

  const { isParticipating, isDisenrolled, conditions } =
    isParticipatingInProgramType(patientDetails, program);

  const tooltip = useTooltipContent(program, patientDetails, isAdminView);
  if (patientStatus === PatientStatusEnum.NOT_ELIGIBLE) {
    return null;
  }
  // We need this because:
  // 1. We don't support per program disenrollment yet and still derive disenrollment status
  // 2. We need to show the tag for prospective programs in admin view
  if (!isParticipating && !isDisenrolled && !showProspectiveProgram) {
    return null;
  }

  const programTypeI18n = getProgramTypeI18n(intl);
  const programName = programTypeI18n[program];
  const badges = getConditionBadges(intl, program, conditions);
  const variant = getVariant(
    patientStatus,
    patientDetails,
    isDisenrolled,
    isAdminView,
  );
  const showStatusChangeModal =
    !isTopLevelEnrolled ||
    patientStatus === PatientStatusEnum.ENROLLED ||
    isDisenrolled;

  return (
    <Tooltip size="small" content={tooltip}>
      <Tag
        className={className}
        variant={variant}
        onClick={showStatusChangeModal ? handleModalOpen : undefined}
      >
        {variant !== 'default' && (
          <Tag.Icon>
            <EllipseIcon className={dot[variant]} />
          </Tag.Icon>
        )}
        <span
          className={cx(label[variant], {
            [marginRightXxs]: !!badges.length,
          })}
        >
          {programName}
        </span>
        {badges.map((badge) => (
          <Badge variant={variant} key={badge} className={badgeCss}>
            {badge}
          </Badge>
        ))}
      </Tag>
    </Tooltip>
  );
}

function useTooltipContent(
  program: ProgramType,
  patientDetails: PatientDetails,
  isAdminView: boolean,
) {
  const intl = useIntl();
  const patientStatusI18n = usePatientStatusMap();

  if (isAdminView) {
    const status = patientDetails.patient?.status;
    const statusEnum = grpcPatientStatusToEnum(status);
    return statusEnum ? patientStatusI18n[statusEnum] : undefined;
  }

  if (isDisenrolledFromAllPrograms(patientDetails)) {
    return intl.formatMessage({
      defaultMessage: 'Patient is disenrolled',
    });
  }

  if (program === ProgramType.RPM && isEnrolledNotActivated(patientDetails)) {
    return intl.formatMessage({
      defaultMessage: 'Patient has not sent their first reading',
    });
  }

  return undefined;
}

function getVariant(
  programStatus: PatientStatusEnum,
  patientDetails: PatientDetails,
  isDisenrolled: boolean,
  isAdminView: boolean,
): ProgramTagVariant {
  // Admin view should also show prospective statuses
  const prospectiveStatusVariant = getProspectiveStatusVariant(programStatus);
  if (isAdminView && prospectiveStatusVariant) {
    return prospectiveStatusVariant;
  }

  if (isDisenrolled || isDisenrolledFromAllPrograms(patientDetails)) {
    return 'danger';
  }

  if (!isAdminView && isEnrolledNotActivated(patientDetails)) {
    return 'warning';
  }

  return 'success';
}
