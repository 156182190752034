import { useAllProgramConditions } from '@/apps/shared/useProgramConditions';
import { displayGoogleDateAge } from '@/shared/patient/patientInfoUtils';

import {
  type Condition,
  type Gender,
} from '../generated/grpc/go/pms/pkg/patient/pms.pb';
import { usePatientDetails } from './queries';

export type PatientDemographics = {
  age?: number;
  gender?: Gender;
  conditions?: Condition[];
};

export function usePatientDemographics(
  patientId: string,
  enabled = true,
): PatientDemographics & { isLoading: boolean } {
  const { data, isLoading } = usePatientDetails(patientId, true, enabled);
  const dob = data?.patient?.dob;
  const gender = data?.patient?.gender;
  const { conditions, isLoading: isLoadingConditions } =
    useAllProgramConditions(patientId, enabled);

  if (isLoading || !dob || isLoadingConditions) {
    return { age: undefined, gender: undefined, conditions: [], isLoading };
  }

  return {
    age: displayGoogleDateAge(dob),
    gender,
    conditions,
    isLoading: false,
  };
}
