import type { IntlShape } from 'react-intl';

import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';

export function getPatientGoalOptions(
  condition: CcmCondition,
  intl: IntlShape,
) {
  if (condition === Condition.Hypertension) {
    return [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain blood pressure',
        }),
        value: 'maintain blood pressure',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Reduce blood pressure' }),
        value: 'reduce blood pressure',
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Monitor blood pressure more regularly',
        }),
        value: 'monitor blood pressure',
      },
    ];
  }

  return undefined;
}
