import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { LoadingId } from '@/pages/patients/PatientProfile/PatientNotesSidebarPanel';
import { useNotesLoadingStateContext } from '@/pages/patients/PatientProfile/PatientNotesSidebarPanel/NotesLoadingStateContext';
import { getUiSchemaFromLabels } from '@/shared/common/@deprecated/SchemaDrivenForm/';
import { useFlags } from '@/shared/hooks';
import { CareModelVersion } from '@/shared/types/featureFlags.types';
import type { RouteParam } from '@/shared/types/route.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import type {
  EncounterModuleInstance,
  EncounterTypeInputs,
  TimeEntry,
} from '../../Notes.types';
import { EncounterModuleId, TypeOfEncounter } from '../../Notes.types';
import { useEncounterModule } from '../../note.queries';
import { useEncounterTypePropsToOmit } from '../../utils/encounterTypeUtils';
import { NotesSchemaDrivenForm } from '../@deprecated/NotesSchemaDrivenForm';
import {
  ENCOUNTER_TYPE_FORMATTED_MESSAGES,
  PATIENT_FORMATTED_MESSAGES,
  TYPE_OF_ENCOUNTER_LABELS,
} from '../i18nMappings';
import type { EndFormValues } from '../noteFormState';
import { useTemplateContext } from '../templates/hooks';
import type { TemplateContext } from '../templates/types';
import { ConfirmEncounterTypeChangeDialog } from './ConfirmEncounterTypeChangeDialog';

export type ChangeEncounterTypeState =
  | { modalOpen: false }
  | {
      type: 'default';
      modalOpen: true;
      noteId: number | undefined;
      prevEncounterType: TypeOfEncounter;
      onConfirm: () => void;
    }
  | {
      type: 'cn';
      modalOpen: true;
      onConfirm: () => void;
    };

function useHiddenOptions() {
  const {
    careModelVersion,
    asyncTitrations,
    apcmEncounterTypes,
    showDischargeEncounter,
  } = useFlags();

  let hiddenOptions: TypeOfEncounter[] = [
    TypeOfEncounter.WELCOME_CALL,
    TypeOfEncounter.PATIENT_ENROLLMENT,
    TypeOfEncounter.VIRTUAL_ENROLLMENT,
    TypeOfEncounter.CN_VISIT,
    TypeOfEncounter.INITIAL_CN_VISIT,
  ];

  // Care model 3.0 removes RN Visits
  if (careModelVersion === CareModelVersion.V3) {
    hiddenOptions = [
      ...hiddenOptions,
      TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED,
      TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED,
    ];
  }

  if (!asyncTitrations) {
    hiddenOptions = [
      ...hiddenOptions,
      TypeOfEncounter.ASYNC_REVIEW,
      TypeOfEncounter.TITRATION_OUTREACH,
    ];
  }

  if (!apcmEncounterTypes) {
    hiddenOptions = [
      ...hiddenOptions,
      TypeOfEncounter.APCM_CARE_PLAN,
      TypeOfEncounter.APCM_VISIT,
    ];
  }

  if (!showDischargeEncounter) {
    hiddenOptions = [...hiddenOptions, TypeOfEncounter.DISCHARGE_FOLLOWUP];
  }

  return hiddenOptions;
}

type Props = {
  timeEntry: Partial<TimeEntry>;
  initialValues?: EncounterTypeInputs;
  onChange: (encounterModuleInstance: EncounterModuleInstance) => void;
  onEncounterTypeConfirmChange: (
    type: TypeOfEncounter | undefined,
    context: TemplateContext,
    endReason: EndFormValues | undefined,
  ) => void;
  shouldShowValidation: boolean;
};

export function EncounterTypeForm({
  timeEntry,
  initialValues,
  onChange,
  onEncounterTypeConfirmChange,
  shouldShowValidation,
}: Props) {
  const intl = useIntl();
  const hiddenOptions = useHiddenOptions();
  const { patientId }: RouteParam = useParams();
  const { context, isLoading: isLoadingContext } =
    useTemplateContext(patientId);
  const { editingNote } = useNoteEditorContext();

  const { useWatchNoteLoadingState } = useNotesLoadingStateContext();
  useWatchNoteLoadingState({
    id: LoadingId.TemplateContext,
    isLoading: isLoadingContext,
  });

  const [changeEncounterTypeState, setChangeEncounterTypeState] =
    useState<ChangeEncounterTypeState>({ modalOpen: false });
  const { encounterModule, isLoading } = useEncounterModule(
    EncounterModuleId.EncounterType,
  );

  // If the encounterModule schema does not have sms or phone_number anymore, omit
  // them from the UI schema and from the initialValues
  const fieldsToOmit = useEncounterTypePropsToOmit();

  return (
    <>
      {!isLoading && !isLoadingContext && encounterModule && (
        <NotesSchemaDrivenForm
          shouldShowValidation={shouldShowValidation}
          shouldLiveValidate={
            // We cannot use this prop with a secure CSP until we move
            // away from rjsf or https://github.com/rjsf-team/react-jsonschema-form/issues/2693
            //
            // TODO: Implement our own rjsf `liveValidate` by passing errors to
            // individuals widgets through formContext, and showing errors next
            // to the widgets
            false
          }
          initialFormData={omit(
            initialValues,
            // Omit fields removed from encounter module schema
            // TODO: Can remove once we migrate all draft notes to delete properties removed from the schema
            // https://cadencerpm.atlassian.net/browse/PLAT-4152
            fieldsToOmit,
          )}
          schema={{
            ...encounterModule.schema,
            // Remove `then` schema that contains a nested if/then that our rjsf implementation can't handle as a quick fix for https://cadencerpm.atlassian.net/browse/PLAT-4587 (See transformSchemaForRjsf for description of root issue).
            // We can do this because the schema in the `then` clause are only used for validation and not for property defintions. validateJsonSchemaData() will still use the full schema
            then: {},
          }}
          shouldChange={(
            inputs: EncounterTypeInputs,
            prevInputs: EncounterTypeInputs | undefined,
          ) => {
            const encounterType = inputs?.type_of_encounter;
            const {
              type_of_encounter: prevEncounterType,
              end_encounter_type: prevEndEncounterType, // ensure's propagation for end-encounter specific template rendering
              end_encounter_reason: prevEndEncounterReason, // same
            } = prevInputs || {};
            const isChangingEncounterType = prevEncounterType !== encounterType;

            if (isChangingEncounterType) {
              if (prevEncounterType) {
                // Begin confirmation of change
                setChangeEncounterTypeState({
                  type: 'default',
                  modalOpen: true,
                  prevEncounterType,
                  noteId: editingNote?.note?.id,
                  onConfirm: () => {
                    onEncounterTypeConfirmChange(
                      inputs.type_of_encounter,
                      context,
                      {
                        endType: prevEndEncounterType,
                        endReason: prevEndEncounterReason,
                      },
                    );
                    setChangeEncounterTypeState({ modalOpen: false });
                  },
                });
              } else {
                onEncounterTypeConfirmChange(
                  inputs.type_of_encounter,
                  context,
                  {
                    endType: prevEndEncounterType,
                    endReason: prevEndEncounterReason,
                  },
                );
              }
              // Shouldn't update, because onEncounterTypeConfirmChange will update
              // with the templated values
              return false;
            }
            // Always update all other fields
            return true;
          }}
          onChange={(inputs: EncounterTypeInputs) =>
            onChange({ encounter_module_id: encounterModule.id, inputs })
          }
          uiSchema={omit(
            merge(
              {
                'ui:order': [
                  'type_of_encounter',
                  'patient_no_show',
                  'patient_outreach_follow_up',
                  'sms',
                  'phone_call',
                  'additional_information',
                  '*',
                ],
                type_of_encounter: {
                  'ui:widget': 'radio',
                  'ui:divider': true,
                  'ui:grid': true,
                  'ui:hiddenOptions': hiddenOptions,
                  enumLabels: TYPE_OF_ENCOUNTER_LABELS,
                },
                patient_no_show: {
                  // We will set patient_no_show automatically in EndEarlyConfirmationDialog
                  'ui:widget': 'hidden',
                  'ui:options': {
                    ...((timeEntry.interactive_duration || 0) > 0 && {
                      isDisabled: true,
                      tooltip: intl.formatMessage({
                        defaultMessage:
                          'Remove interactive minutes in time tracking to enable',
                      }),
                    }),
                  },
                },
                // Hide because it is being rendered manually in Medications EncounterModule container
                has_meds_to_report: {
                  'ui:widget': 'hidden',
                },
                // Hide because it is being rendered manually in Symptoms EncounterModule container
                has_symptoms_to_report: {
                  'ui:widget': 'hidden',
                },
                // Hide because it is being rendered manually in Emergency Visits EncounterModule container
                has_emergency_visits_to_report: {
                  'ui:widget': 'hidden',
                },
                // Hide because it's a feature flag field that we are populating on initialization
                visit_layout: {
                  'ui:widget': 'hidden',
                },
              },
              getUiSchemaFromLabels(PATIENT_FORMATTED_MESSAGES, {
                inline: true,
              }),
              getUiSchemaFromLabels(ENCOUNTER_TYPE_FORMATTED_MESSAGES),
            ),
            // Omit fields removed from encounter module schema
            // TODO: Can remove once we migrate all draft notes to delete properties removed from the schema
            // https://cadencerpm.atlassian.net/browse/PLAT-4152
            fieldsToOmit,
          )}
        />
      )}
      <ConfirmEncounterTypeChangeDialog
        state={changeEncounterTypeState}
        onClose={() => setChangeEncounterTypeState({ modalOpen: false })}
      />
    </>
  );
}
