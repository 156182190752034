import { GoalMeasure } from '../../../Goals/goals.types';
import { type PreventativeCareName } from '../../formConfig';

export const PREVENTATIVE_CARE_GOAL_MEASURE_MAP: Record<
  PreventativeCareName,
  GoalMeasure
> = {
  breastCancerScreening: GoalMeasure.BreastCancerScreening,
  colonoscopy: GoalMeasure.Colonoscopy,
  diabeticEyeExam: GoalMeasure.DiabeticEyeExam,
  influenza: GoalMeasure.Influenza,
  pneumovax: GoalMeasure.Pneumovax,
  tdap: GoalMeasure.Tdap,
  shingrix: GoalMeasure.Shingrix,
};
