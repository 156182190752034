import cx from 'classnames';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';

import { Table } from '@/shared/common/Table';

import { patientTableRow } from './PatientTableRow.css';

export const PatientTableRow = forwardRef(
  (
    { children, className }: { children: ReactNode[]; className?: string },
    ref: ForwardedRef<HTMLTableRowElement> | null,
  ) => (
    <Table.Row className={cx(patientTableRow, className)} ref={ref}>
      {children}
    </Table.Row>
  ),
);
