import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import type { RouteParam } from '@/shared/types/route.types';
import { displayBloodPressure } from '@/shared/utils/helpers';

import {
  fieldContainer,
  fieldLabel,
  fieldValue,
} from './GoalsAndInterventions.css';
import { usePatientConditionData } from './hooks/usePatientConditionData.hook';

type Props = {
  condition: CcmCondition;
};
export function ConditionPrognosisVitals({ condition }: Props) {
  const intl = useIntl();

  const { patientId }: RouteParam = useParams();
  const {
    data: { a1c, avgDiastolic, avgSystolic, totalCholesterol, bmi },
  } = usePatientConditionData(patientId);

  if (!condition) return null;

  if (condition === Condition.TypeTwoDiabetes) {
    return (
      <div className={fieldContainer}>
        <div className={fieldLabel}>
          {intl.formatMessage({
            defaultMessage: 'A1c:',
          })}
        </div>
        <div className={fieldValue}>{a1c ? `${a1c}%` : '-'}</div>
      </div>
    );
  }

  if (condition === Condition.Hypertension) {
    return (
      <div className={fieldContainer}>
        <div className={fieldLabel}>
          {intl.formatMessage({
            defaultMessage: 'Avg BP:',
          })}
        </div>
        <div className={fieldValue}>
          {displayBloodPressure(avgSystolic, avgDiastolic)}
        </div>
      </div>
    );
  }

  if (condition === Condition.Hyperlipidemia) {
    return (
      <div className={fieldContainer}>
        <div className={fieldLabel}>
          {intl.formatMessage({
            defaultMessage: 'Total Cholesterol:',
          })}
        </div>
        <div className={fieldValue}>
          {totalCholesterol ? `${totalCholesterol} mg/dL` : '-'}
        </div>
      </div>
    );
  }

  if (condition === Condition.MorbidObesity) {
    return (
      <div className={fieldContainer}>
        <div className={fieldLabel}>
          {intl.formatMessage({ defaultMessage: 'BMI:' })}
        </div>
        <div className={fieldValue}>{bmi || '-'}</div>
      </div>
    );
  }

  return null;
}
