import { type CCMCarePlan } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { useFlags } from '@/shared/hooks/useFlags';

export function useCarePlanV1Changes(
  carePlan: Maybe<CCMCarePlan['carePlan']>,
): boolean {
  const { carePlanVisitFlowImprovements } = useFlags();

  if (carePlan?.dietNotes) {
    return true;
  }

  return Boolean(
    carePlanVisitFlowImprovements &&
      carePlan &&
      !carePlan.exercise &&
      !carePlan.diet?.fastFood &&
      !carePlan.diet?.snacks &&
      !carePlan.diet?.desserts &&
      !carePlan.diet?.regularSoda &&
      !carePlan.diet?.sweetTea &&
      !carePlan.diet?.waterIntake &&
      !carePlan.diet?.saltIntake,
  );
}
