import { type PreventativeCare } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';

import {
  formatEmergencyVisit,
  formatPreventativeCare,
} from '../../../tabs/CarePlan/CarePlanDetail/CarePlanPreview/formatters';
import { PREVENTATIVE_CARE_OPTIONS } from '../../../tabs/CarePlan/CarePlanForm/sections/preventativeCareOptions';

export const CarePlanTemplateHelpers = {
  formatEmergencyVisit,
  formatPreventativeCare,
  addOne(index: number) {
    return index + 1;
  },
};

export function addPreventativeCareLabels(
  preventativeCare: PreventativeCare[],
) {
  return preventativeCare.map((item: PreventativeCare) => {
    const option = PREVENTATIVE_CARE_OPTIONS.find(
      (opt) => opt.name === item.name,
    );
    return {
      ...item,
      label: option?.label || item.name,
    };
  });
}
