import { createCtx } from '@/shared/hooks/createCtx';

export type EplFilterContext = {
  usual_provider_name: {
    showWarning: boolean;
    callback: () => void;
  };
};

export const [useEplFilterContext, EplFilterContextProvider] =
  createCtx<EplFilterContext>('EplFilterContext');
