import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { useOnMount } from '@/shared/hooks/useOnMount';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';

import { patientConditionRow } from './GoalsAndInterventions.css';
import { getPatientGoalOptions } from './goalUtils';

type Props = {
  index: number;
  prognosis: CCMPrognosis;
  condition: CcmCondition;
};

export function PatientConditionGoal({ index, prognosis, condition }: Props) {
  const intl = useIntl();
  const form = useFormContext();

  useOnMount(() => {
    if (condition === Condition.Hypertension) {
      const defaultGoal =
        prognosis === CCMPrognosis.GOOD
          ? 'maintain blood pressure'
          : 'reduce blood pressure';
      form.setValue(`goalsAndInterventions.${index}.healthGoal`, defaultGoal);
    }
  });

  const patientGoalOptions = getPatientGoalOptions(condition, intl);

  if (patientGoalOptions) {
    return (
      <Form.Row rowSpacing={1} className={patientConditionRow}>
        <Form.Select
          required
          size={11}
          label={intl.formatMessage({
            defaultMessage: 'Patient Goal',
          })}
          name={`goalsAndInterventions.${index}.healthGoal`}
        >
          {patientGoalOptions.map((option) => (
            <Form.Select.Option key={option.value}>
              {option.label}
            </Form.Select.Option>
          ))}
        </Form.Select>
      </Form.Row>
    );
  }

  return (
    <Form.Row rowSpacing={1} className={patientConditionRow}>
      <Form.TextField
        required
        size={11}
        label={intl.formatMessage({
          defaultMessage: 'Patient Goal',
        })}
        name={`goalsAndInterventions.${index}.healthGoal`}
      />
    </Form.Row>
  );
}
