import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { OverlayTriggerState } from 'react-stately';

import { useFlags, usePrevious } from '@/shared/hooks';
import { useCareProvider } from '@/shared/hooks/queries';
import { useTaskCountByType } from '@/shared/hooks/queries/tasks.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import type { ParentView } from '@/shared/tasking/TaskList';
import type { Task } from '@/shared/tasking/types';
import { Tabs } from '@/shared/tempo/@labs/atom/Tabs';
import type { NotificationBadgeProps } from '@/shared/tempo/atom/NotificationBadge';
import { NotificationBadge } from '@/shared/tempo/atom/NotificationBadge';
import { ProviderTeamRole } from '@/shared/types/provider.types';

import { startOfTodayUTC } from '../../shared/tasking/utils';
import { TabKey } from './TabKey';
import { useTaskFilterContext } from './TaskFilterContext';
import { tabLine, tabTitle, tabTitleContainer } from './TaskHub.css';
import { tabPanel } from './TaskHubBody.css';
import {
  AssignedAndTeamTasksList,
  AssignedTasksList,
  FollowedTasksList,
  ResolvedTasksList,
  TeamTasksList,
} from './TaskList';

type Props = {
  parentView: ParentView;
  state?: OverlayTriggerState;
  activeTaskId?: string;
  onOpenTaskDetail?: (task: Task) => void;
  selectedTab?: TabKey;
  onSelectedTabChange?: (tab: TabKey) => void;
};

export function TaskHubBody({
  state,
  parentView,
  activeTaskId,
  onOpenTaskDetail,
  selectedTab,
  onSelectedTabChange,
}: Props) {
  const { getFilter } = useTaskFilterContext();
  const { healthSystemIds, hasAssignee } = getFilter(TabKey.Team);
  const { enableScheduledTasks, tasksWeightedSorting } = useFlags();
  const { currentUserId: providerId } = useCurrentUser();
  const { data: careProvider, isLoading: isLoadingCareProvider } =
    useCareProvider(providerId, {
      staleTime: Infinity,
    });
  const { data: countsByType, isLoading } = useTaskCountByType(
    healthSystemIds,
    hasAssignee,
    enableScheduledTasks ? startOfTodayUTC() : undefined,
  );
  const openTaskCount = getTotalTaskCount(countsByType?.assigned);
  const teamTasksCount = getTotalTaskCount(countsByType?.team);
  const followingTaskCount = getTotalTaskCount(countsByType?.following);
  const allTeamTasksCount = getTotalTaskCount(countsByType?.allTeam);

  if (isLoadingCareProvider) {
    return null;
  }

  const tabs = [
    ...(careProvider?.team_role === ProviderTeamRole.NP && tasksWeightedSorting
      ? [
          <Tabs.Tab
            keepMounted
            className={tabPanel.compact}
            key={TabKey.CombinedOpenAndTeam}
            title={
              <TabTitle
                badgeVariant="default"
                taskCount={allTeamTasksCount}
                isLoadingCount={isLoading}
              >
                <FormattedMessage defaultMessage="Tasks" />
              </TabTitle>
            }
          >
            <AssignedAndTeamTasksList
              taskHubOverlayState={state}
              parentView={parentView}
              activeTaskId={activeTaskId}
              onOpenTaskDetail={onOpenTaskDetail}
            />
          </Tabs.Tab>,
        ]
      : [
          <Tabs.Tab
            keepMounted
            className={tabPanel.compact}
            key={TabKey.Open}
            title={
              <TabTitle
                badgeVariant="default"
                taskCount={openTaskCount}
                isLoadingCount={isLoading}
              >
                <FormattedMessage defaultMessage="My tasks" />
              </TabTitle>
            }
          >
            <AssignedTasksList
              taskHubOverlayState={state}
              parentView={parentView}
              activeTaskId={activeTaskId}
              onOpenTaskDetail={onOpenTaskDetail}
            />
          </Tabs.Tab>,
          <Tabs.Tab
            keepMounted
            className={tabPanel.compact}
            key={TabKey.Team}
            title={
              <TabTitle
                badgeVariant="muted"
                taskCount={teamTasksCount}
                isLoadingCount={isLoading}
              >
                <FormattedMessage defaultMessage="Team tasks" />
              </TabTitle>
            }
          >
            <TeamTasksList
              taskHubOverlayState={state}
              parentView={parentView}
              activeTaskId={activeTaskId}
              onOpenTaskDetail={onOpenTaskDetail}
            />
          </Tabs.Tab>,
        ]),
    <Tabs.Tab
      keepMounted
      className={tabPanel.compact}
      key={TabKey.Following}
      title={
        <TabTitle
          badgeVariant="muted"
          taskCount={followingTaskCount}
          isLoadingCount={isLoading}
        >
          <FormattedMessage defaultMessage="Following" />
        </TabTitle>
      }
    >
      <FollowedTasksList
        taskHubOverlayState={state}
        parentView={parentView}
        activeTaskId={activeTaskId}
        onOpenTaskDetail={onOpenTaskDetail}
      />
    </Tabs.Tab>,
    <Tabs.Tab
      keepMounted
      className={tabPanel.compact}
      key={TabKey.Resolved}
      title={
        <TabTitle>
          <FormattedMessage defaultMessage="Resolved" />
        </TabTitle>
      }
    >
      <ResolvedTasksList
        taskHubOverlayState={state}
        parentView={parentView}
        activeTaskId={activeTaskId}
        onOpenTaskDetail={onOpenTaskDetail}
      />
    </Tabs.Tab>,
  ];

  return (
    <Tabs
      className={tabLine}
      items={tabs}
      selectedKey={selectedTab}
      onSelectionChange={(key) => onSelectedTabChange?.(key as TabKey)}
    >
      {(tab) => tab}
    </Tabs>
  );
}

type TabTitleProps = {
  badgeVariant?: NotificationBadgeProps['variant'];
  children: ReactNode;
  isLoadingCount?: boolean;
  taskCount?: number;
};

function TabTitle({
  badgeVariant,
  children,
  isLoadingCount,
  taskCount = 0,
}: TabTitleProps) {
  // using this to prevent jumping around in the UI when
  // changing our filter params. it will get replaced as
  // soon as `isLoadingCount` is falsy
  const previousTaskCount = usePrevious(taskCount);

  return (
    <div className={tabTitleContainer}>
      <span className={tabTitle}>{children}</span>
      {!isLoadingCount && badgeVariant && taskCount > 0 && (
        <NotificationBadge count={taskCount} variant={badgeVariant} />
      )}
      {isLoadingCount && badgeVariant && !!previousTaskCount && (
        <NotificationBadge count={previousTaskCount} variant={badgeVariant} />
      )}
    </div>
  );
}

function getTotalTaskCount(countByType: Record<string, string> = {}) {
  return Object.values(countByType).reduce(
    (acc, count) => acc + parseInt(count, 10),
    0,
  );
}
