import {
  type DataObject,
  SchemaDrivenForm,
  type Props as SchemaDrivenFormProps,
} from '@/shared/common/@deprecated/SchemaDrivenForm/SchemaDrivenForm';
import { useFlags } from '@/shared/hooks';

import {
  getEncounterType,
  getEncounterTypeInstance,
} from '../../utils/encounterTypeUtils';
import { useEncounterModuleInstances } from '../hooks/useEncounterModuleInstances.hook';

/** @deprecated Use our `Form` components instead */
export function NotesSchemaDrivenForm<T extends DataObject>(
  props: SchemaDrivenFormProps<T>,
) {
  // We need to re-mount the schema driven form whenever the encounter type / end-state (no_show)
  // changes, or the RichTextEditorWidgets will have stale internal state
  const { useEndEarlyModuleTemplates } = useFlags();
  const { encounterModuleInstances } = useEncounterModuleInstances();
  const encounterTypeInstance = getEncounterTypeInstance(
    encounterModuleInstances,
  );

  const vals = [
    getEncounterType(encounterModuleInstances),
    String(encounterTypeInstance?.inputs.visit_layout),
  ];

  if (useEndEarlyModuleTemplates) {
    // Always push these values, converting undefined to empty string for consistency
    vals.push(encounterTypeInstance?.inputs.end_encounter_type || '');
    vals.push(encounterTypeInstance?.inputs.end_encounter_reason || '');
  }

  const key = vals.join(':');

  return <SchemaDrivenForm {...props} key={key} />;
}
