import { TypeOfEncounter } from '../Notes.types';

// explicitly not using i18n here because these go to the EHR
export const DEFAULT_NOTE_TITLE: { [K in TypeOfEncounter]?: string } = {
  [TypeOfEncounter.INITIAL_NP_VISIT]: 'Initial NP visit',
  [TypeOfEncounter.NP_VISIT]: 'Regular NP visit',
  [TypeOfEncounter.PATIENT_INBOUND]: 'Patient inbound',
  [TypeOfEncounter.CCM_CARE_PLAN]: 'CCM Care Plan',
  [TypeOfEncounter.CCM_VISIT]: 'Regular CCM visit',
  [TypeOfEncounter.APCM_CARE_PLAN]: 'APCM Care Plan',
  [TypeOfEncounter.APCM_VISIT]: 'Regular APCM visit',
  [TypeOfEncounter.DISENROLLMENT]: 'Disenrollment',
  [TypeOfEncounter.ENROLLMENT_CALL]: 'Enrollment call',
  [TypeOfEncounter.ASYNC_REVIEW]: 'Medication review',
  [TypeOfEncounter.TITRATION_OUTREACH]: 'Titration outreach',
  [TypeOfEncounter.DISCHARGE_FOLLOWUP]: 'Discharge follow-up',
};
