/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CadenceModelsV1Models from "../../../../../cadence/models/models.pb"
import * as GoogleProtobufEmpty from "../../../../../google/protobuf/empty.pb"
import * as GoEhrPkgEmr_adapterEmr_adapter from "../../../../ehr/pkg/emr_adapter/emr_adapter.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum EhrSyncTaskRequestPatientType {
  PATIENT_TYPE_UNSPECIFIED = "PATIENT_TYPE_UNSPECIFIED",
  PROSPECTIVE = "PROSPECTIVE",
  ENROLLED = "ENROLLED",
}

export enum EhrSyncTaskRequestScope {
  SCOPE_UNDEFINED = "SCOPE_UNDEFINED",
  APPOINTMENTS = "APPOINTMENTS",
  DEMOGRAPHICS = "DEMOGRAPHICS",
  VITALS = "VITALS",
  INSURANCES = "INSURANCES",
  PROBLEM_CODES = "PROBLEM_CODES",
  LABS = "LABS",
  MEDICATIONS = "MEDICATIONS",
  ORDERS = "ORDERS",
  CLINICAL_DOCUMENTS = "CLINICAL_DOCUMENTS",
  CLINICAL_HISTORY_DOCUMENTS = "CLINICAL_HISTORY_DOCUMENTS",
  PREVENTATIVE_CARE_MEASURES = "PREVENTATIVE_CARE_MEASURES",
  ENCOUNTER_DOCUMENTS = "ENCOUNTER_DOCUMENTS",
  ALLERGIES = "ALLERGIES",
}

export type SynchronizePatientRequest = {
  name?: string
  scopes?: EhrSyncTaskRequestScope[]
}

export type SynchronizePatientsRequest = {
  scopes?: EhrSyncTaskRequestScope[]
  patientIds?: string[]
  patientType?: EhrSyncTaskRequestPatientType
  statuses?: CadenceModelsV1Models.PatientStatus[]
  ehr?: CadenceModelsV1Models.EHR
  contextIds?: string[]
  healthSystemIds?: number[]
  npiIds?: number[]
  programTypes?: CadenceModelsV1Models.ProgramType[]
  maxLastSyncDays?: number
  maxPatients?: number
  recentlySyncedLastSyncDays?: number
}

export type SynchronizePatientsResponse = {
  patientCount?: number
  queuedPatientCount?: number
  failedPatientCount?: number
  skippedPatientCount?: number
  maxPatientLimit?: number
}

export type EhrSyncTaskRequest = {
  mrn?: string
  cadenceId?: string
  hospitalEhrInfo?: CadenceModelsV1Models.HospitalEhrInfo
  patientType?: EhrSyncTaskRequestPatientType
  scopes?: EhrSyncTaskRequestScope[]
  healthSystemId?: number
}

export type PatientEMRSync = {
  syncTaskRequest?: EhrSyncTaskRequest
  appointments?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientAppointmentInfo
  demographics?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatient
  conditions?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientConditionInfo
  insurances?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientInsuranceInfo
  labs?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientLabsInfo
  vitals?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientVitalsInfo
  medications?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientMedicationInfo
  orders?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientOrderInfo
  clinicalDocuments?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientClinicalDocumentsInfo
  clinicalHistoryDocuments?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientClinicalHistoryDocumentsInfo
  preventativeCareMeasures?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientPreventativeCareInfo
  encounterDocuments?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientEncounterDocumentsInfo
  successfullyFetchedScopes?: EhrSyncTaskRequestScope[]
  allergies?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientAllergiesInfo
}

export class SynchronizationService {
  static SynchronizePatient(req: SynchronizePatientRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<SynchronizePatientRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/patients/${req["name"]}:synchronize`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SynchronizePatients(req: SynchronizePatientsRequest, initReq?: fm.InitReq): Promise<SynchronizePatientsResponse> {
    return fm.fetchReq<SynchronizePatientsRequest, SynchronizePatientsResponse>(`/rpm/v1/patients:synchronize`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SynchronizePatientsCheckCount(req: SynchronizePatientsRequest, initReq?: fm.InitReq): Promise<SynchronizePatientsResponse> {
    return fm.fetchReq<SynchronizePatientsRequest, SynchronizePatientsResponse>(`/rpm/v1/patients:synchronize-check-count`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}