import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import type { GoalsAndInterventions } from '../../formConfig';
import {
  actionNeededFill,
  actionNeededLabel,
  actionNeededStroke,
  radioContainer,
  radioLabel,
  selectedLabel,
  upToDateFill,
  upToDateLabel,
  upToDateStroke,
} from '../../radioStyles.css';
import { ConditionPrognosisVitals } from './ConditionPrognosisVitals';
import {
  additionalNotesField,
  notesField,
  patientAcceptsCareToggle,
  patientConditionRow,
  patientConditionSection,
  patientConditionSectionContainer,
} from './GoalsAndInterventions.css';
import { PatientConditionGoal } from './PatientConditionGoal';
import { showGoalSetting } from './prognosisUtils';

type Props = {
  index: number;
  condition: CcmCondition;
};

export function PrognosisAndPlan({ index, condition }: Props) {
  const intl = useIntl();
  const form = useFormContext();
  const prognosis = form.watch(
    `goalsAndInterventions.${index}.prognosis`,
  ) as CCMPrognosis;

  const allConditions = form.watch(
    `goalsAndInterventions`,
  ) as GoalsAndInterventions[];

  const acceptsCare = form.watch(
    `goalsAndInterventions.${index}.acceptsCare`,
  ) as boolean;

  const shouldShowGoalSetting = showGoalSetting(
    condition,
    prognosis,
    allConditions,
    index,
  );

  return (
    <div className={patientConditionSection}>
      <div className={patientConditionSectionContainer}>
        <Form.Row rowSpacing={1}>
          {/* Note: Using existing prognosis values for now before we user test */}
          <PrognosisField
            name={`goalsAndInterventions.${index}.prognosis`}
            index={index}
          />
        </Form.Row>
        <ConditionPrognosisVitals condition={condition} />
        <Form.Row rowSpacing={1} className={additionalNotesField}>
          <Form.TextField
            inputClass={notesField}
            required
            size={11}
            placeholder={intl.formatMessage({
              defaultMessage: 'Additional notes',
            })}
            name={`goalsAndInterventions.${index}.prognosisNotes`}
          />
        </Form.Row>
      </div>
      {shouldShowGoalSetting &&
        prognosis !== CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED && (
          <Form.Toggle
            classes={{ root: patientAcceptsCareToggle }}
            label={intl.formatMessage({
              defaultMessage: 'Set condition goals',
            })}
            name={`goalsAndInterventions.${index}.acceptsCare`}
          />
        )}
      {!shouldShowGoalSetting &&
        prognosis !== CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED && (
          <Form.Toggle
            size={11}
            label={intl.formatMessage({
              defaultMessage:
                'Patient still wants to make this condition a part of their Care Plan',
            })}
            name={`goalsAndInterventions.${index}.enableGoalSetting`}
          />
        )}

      {acceptsCare === true && (
        <>
          <PatientConditionGoal
            index={index}
            prognosis={prognosis}
            condition={condition}
          />
          <Form.Row rowSpacing={1} className={patientConditionRow}>
            <Form.TextArea
              required
              size={11}
              rows={2}
              label={intl.formatMessage({
                defaultMessage: 'Patient Action Steps',
              })}
              name={`goalsAndInterventions.${index}.actionSteps`}
            />
          </Form.Row>
          <Form.Row rowSpacing={1} className={patientConditionRow}>
            <Form.TextArea
              required
              size={11}
              rows={2}
              label={intl.formatMessage({
                defaultMessage: 'Coordination of Care/Resources',
              })}
              name={`goalsAndInterventions.${index}.coordination`}
            />
          </Form.Row>
        </>
      )}
    </div>
  );
}

function PrognosisField({ name, index }: { name: string; index: number }) {
  const intl = useIntl();
  const form = useFormContext();
  return (
    <Form.RadioGroup
      name={name}
      label={intl.formatMessage({ defaultMessage: 'Prognosis' })}
      orientation="horizontal"
      size={12}
      classes={{ root: radioContainer }}
      required
      onChange={(value: CCMPrognosis) => {
        const defaultAcceptsCare = value === CCMPrognosis.FAIR;
        form.setValue(
          `goalsAndInterventions.${index}.acceptsCare`,
          defaultAcceptsCare,
        );
      }}
    >
      <Form.Radio
        value={CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED}
        classes={{
          label: selectedLabel,
        }}
      >
        <FormattedMessage defaultMessage="Undetermined" />
      </Form.Radio>
      <Form.Radio
        value={CCMPrognosis.FAIR}
        classes={{
          label: radioLabel,
          selectedLabel: actionNeededLabel,
          selectedDot: actionNeededFill,
          selectedCircle: actionNeededStroke,
        }}
      >
        <FormattedMessage defaultMessage="Suboptimal" />
      </Form.Radio>

      <Form.Radio
        value={CCMPrognosis.GOOD}
        classes={{
          label: radioLabel,
          selectedLabel: upToDateLabel,
          selectedDot: upToDateFill,
          selectedCircle: upToDateStroke,
        }}
      >
        <FormattedMessage defaultMessage="Well-controlled" />
      </Form.Radio>
    </Form.RadioGroup>
  );
}
