import { type ReactElement, useEffect } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { type FormFields, getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import * as styles from './LivingSituationForm.css';
import {
  HousingConcern,
  HousingConcernsChoice,
  HousingStabilityChoice,
  HousingStabilityDetail,
} from './livingSituation.types';

type Props = {
  form: UseFormReturn<FormFields>;
  hasGap: (fields: FormFields) => boolean;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
};

export function LivingSituationForm({
  form,
  createGoalWithMeasure,
  hasGap,
}: Props): ReactElement {
  const intl = useIntl();
  const housingStabilityChoice = form.watch('housingStabilityChoice');
  const housingConcernsChoice = form.watch('housingConcernsChoice');

  const showNotes =
    housingStabilityChoice === HousingStabilityChoice.Yes ||
    housingConcernsChoice === HousingConcernsChoice.Yes;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('livingSituation');

  const hasGapVal = hasGap(form.getValues());

  useEffect(() => {
    form.setValue(checkedFieldName, hasGapVal, { shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGapVal]);

  const housingConcernOptions = [
    {
      value: HousingConcern.Pests,
      label: <FormattedMessage defaultMessage="Pests" />,
    },
    {
      value: HousingConcern.Mold,
      label: <FormattedMessage defaultMessage="Mold" />,
    },
    {
      value: HousingConcern.Lead,
      label: <FormattedMessage defaultMessage="Lead" />,
    },
    {
      value: HousingConcern.NoHeat,
      label: <FormattedMessage defaultMessage="Heat" />,
    },
    {
      value: HousingConcern.BrokenAppliances,
      label: <FormattedMessage defaultMessage="Oven or stove" />,
    },
    {
      value: HousingConcern.MissingDetectors,
      label: <FormattedMessage defaultMessage="Smoke detectors" />,
    },
    {
      value: HousingConcern.WaterLeaks,
      label: <FormattedMessage defaultMessage="Water leaks" />,
    },
  ];

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="housingStabilityChoice"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Living Situation" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="Do you have any concerns with the stability of your home environment?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
          onChange={(value) => {
            if (value === HousingStabilityChoice.No) {
              form.setValue('housingStabilityDetail', undefined, {
                shouldValidate: true,
              });
            }
          }}
        >
          <Form.Radio value={HousingStabilityChoice.No}>
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
          <Form.Radio value={HousingStabilityChoice.Yes}>
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
        </Form.RadioGroup>

        {housingStabilityChoice === HousingStabilityChoice.Yes && (
          <div className={styles.detailContainer}>
            <Form.RadioGroup
              name="housingStabilityDetail"
              label="Patient surfaced:"
              size={11}
            >
              <Form.Radio value={HousingStabilityDetail.FutureWorries}>
                <FormattedMessage defaultMessage="Worried about losing place in the future" />
              </Form.Radio>
              <Form.Radio value={HousingStabilityDetail.Unstable}>
                <FormattedMessage defaultMessage="Does not have a steady place to live" />
              </Form.Radio>
            </Form.RadioGroup>
          </div>
        )}

        <div className={styles.concernsContainer}>
          <Form.RadioGroup
            name="housingConcernsChoice"
            label={
              <>
                <Tag variant="default" className={styles.sectionTag}>
                  <FormattedMessage defaultMessage="Living Situation" />
                </Tag>{' '}
                <FormattedMessage defaultMessage="Any concerns with pests, mold, lead, lack of heat, a broken oven or stove, missing or broken smoke detectors, or water leaks?" />
              </>
            }
            orientation="horizontal"
            size={11}
            required
            onChange={(value) => {
              if (value === HousingConcernsChoice.No) {
                form.setValue('housingConcerns', [], { shouldValidate: true });
              }
            }}
          >
            <Form.Radio value={HousingConcernsChoice.No}>
              <FormattedMessage defaultMessage="No" />
            </Form.Radio>
            <Form.Radio value={HousingConcernsChoice.Yes}>
              <FormattedMessage defaultMessage="Yes" />
            </Form.Radio>
          </Form.RadioGroup>

          {housingConcernsChoice === HousingConcernsChoice.Yes && (
            <div className={styles.detailContainer}>
              <Form.CheckboxGroup
                name="housingConcerns"
                label="Patient surfaced issue with:"
                options={housingConcernOptions}
                size={11}
                compact
                required
              />
            </div>
          )}
        </div>

        {hasGapVal && (
          <div className={styles.gapMessage}>
            <FormattedMessage defaultMessage="Patient has a housing need according to CMS guidelines" />
          </div>
        )}
      </div>

      {showNotes && (
        <div className={styles.rightColumn}>
          <GapWidget
            fieldName={descriptionFieldName}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter notes about living situation',
            })}
            showGoalButton={hasGapVal}
            onCreateGoal={createGoalWithMeasure}
            measure={GoalMeasure.LivingSituation}
            form={form}
            requireDescription={hasGapVal}
          />
        </div>
      )}
    </>
  );
}
