import cx from 'classnames';
import last from 'lodash/last';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFlatPages } from '@/reactQuery';
import { CurlyBrackets } from '@/shared/assets/svgs';
import { Divider } from '@/shared/common/Divider';
import { UserAvatar } from '@/shared/common/UserAvatar';
import type {
  TaskType,
  WeightValues,
} from '@/shared/generated/grpc/go/pms/pkg/task/task.pb';
import { useFlags } from '@/shared/hooks';
import {
  useCreateComment,
  useSetTaskState,
  useTaskTypesInfinite,
  useUpdateTask,
} from '@/shared/hooks/queries/tasks.queries';
import { flexContainer, flexJustify } from '@/shared/jsStyle/flex.css';
import { Button } from '@/shared/tempo/atom/Button';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import type { Task } from '../types';
import { TaskState } from '../types';
import { CardSection } from './CardSection';
import { CardTitle } from './CardTitle';
import { ScheduleDate } from './ScheduleDate/ScheduleDate';
import { changeTaskType, dividerTop, snippetText } from './TaskCard.css';
import { TaskHeader } from './TaskHeader';
import { TimestampWithContext } from './Timestamp/TimestampWithContext';
import { UnassignedAvatar } from './UnassignedAvatar';
import { isUnassigned, lastActivityAt } from './utils';

type Props = {
  task: Task;
  hideSnippet?: boolean;
  onSelfAssignTask?: () => void;
  showScheduleDatePicker?: boolean;
};

const SNIPPET_MAX_LENGTH = 56;
const PATIENT_CASE_FOLLOW_UP_TASK_TYPE = 'Patient Case Follow-up';
const AUTOMATED_PATIENT_CASE_FOLLOW_UP_TASK_TYPE =
  'Automated Patient Case Follow-up';

export function TaskCardCompactContent({
  task,
  onSelfAssignTask,
  hideSnippet = false,
  showScheduleDatePicker = false,
}: Props) {
  const { assignee } = task;
  const intl = useIntl();
  const { toaster } = useToaster();
  const {
    enableScheduledTasks,
    tasksWeightedSorting,
    tasksWeightedSortingDebug,
  } = useFlags();
  const { mutate: setTaskState } = useSetTaskState(task, {
    onSuccess: (newState) => {
      toaster.success(
        newState === TaskState.CLOSED
          ? intl.formatMessage({
              defaultMessage: 'Task resolved. Nice work! 🎉',
            })
          : intl.formatMessage({ defaultMessage: 'Task reopened' }),
      );
    },
    onError: () => {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to update task',
        }),
      );
    },
  });
  const snippet = task.comments.length
    ? last(task.comments)?.body
    : task.description;
  const truncatedSnippet =
    snippet && snippet.length > SNIPPET_MAX_LENGTH
      ? `${snippet.slice(0, SNIPPET_MAX_LENGTH)}...`
      : snippet;

  const allTaskTypes = useFlatPages<TaskType, 'data'>(useTaskTypesInfinite({}));
  const patientCaseFollowUpTaskType = allTaskTypes.find(
    (tt) => tt.typeName === PATIENT_CASE_FOLLOW_UP_TASK_TYPE,
  );
  const automatedPatientCaseFollowUpTaskType = allTaskTypes.find(
    (tt) => tt.typeName === AUTOMATED_PATIENT_CASE_FOLLOW_UP_TASK_TYPE,
  );

  const { mutate: postComment } = useCreateComment(task.name);

  const { isLoading: isUpdating, mutate: updateTask } = useUpdateTask(task, {
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to update task' }),
      ),
    onSuccess: () => {
      toaster.success(
        intl.formatMessage({ defaultMessage: 'Successfully updated task' }),
      );
    },
  });

  const onDoNotAutomateTask = () => {
    updateTask({
      task: {
        taskTypeId: patientCaseFollowUpTaskType?.name,
      },
    });
    postComment({
      body: intl.formatMessage({
        defaultMessage: 'Task type manually changed to Patient Case Follow-up',
      }),
    });
  };

  return (
    <CardSection>
      <CardSection.LeftColumn>
        {!isUnassigned(task) && assignee ? (
          <UserAvatar
            firstName={assignee.givenName}
            lastName={assignee.familyName}
          />
        ) : (
          <UnassignedAvatar task={task} />
        )}
      </CardSection.LeftColumn>
      <CardSection.RightColumn>
        <TaskHeader
          task={task}
          onResolve={() => setTaskState(TaskState.CLOSED)}
          onReopen={() => setTaskState(TaskState.OPENED)}
          onSelfAssignTask={onSelfAssignTask}
        />
        <CardTitle task={task} />
        {!hideSnippet && <div className={snippetText}>{truncatedSnippet}</div>}
        <div className={cx(flexContainer.row, flexJustify.spaceBetween)}>
          <TimestampWithContext
            ts={lastActivityAt(task)}
            numMessages={task.comments.length}
          />
          {tasksWeightedSorting &&
            tasksWeightedSortingDebug &&
            task.weightValues && (
              <Tooltip
                content={<WeightedSumDebug task={task} />}
                placement="bottom"
                isDisabled={false}
              >
                <CurlyBrackets width={16} />
              </Tooltip>
            )}
        </div>
        {enableScheduledTasks && showScheduleDatePicker && (
          <>
            <Divider className={dividerTop} />
            <ScheduleDate task={task} />
            {task.taskTypeId === automatedPatientCaseFollowUpTaskType?.name && (
              <div className={changeTaskType}>
                <Button
                  size="small"
                  isDisabled={isUpdating}
                  isProcessing={isUpdating}
                  onPress={onDoNotAutomateTask}
                >
                  <FormattedMessage defaultMessage="Do not automate" />
                </Button>
              </div>
            )}
            <Divider />
          </>
        )}
      </CardSection.RightColumn>
    </CardSection>
  );
}

function WeightedSumDebug({ task }: { task: Task }) {
  return (
    <table style={{ textAlign: 'left' }}>
      <thead>
        <tr>
          <th>
            <FormattedMessage defaultMessage="Dimension" />
          </th>
          <th>
            <FormattedMessage defaultMessage="Value" />
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(task.weightValues).map((dimension) => (
          <tr key={dimension}>
            <td>{dimension}</td>
            <td>{task.weightValues[dimension as keyof WeightValues]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
