import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { type FormFields, getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import * as styles from './shared.css';
import { TransportationSecurityChoice } from './transportationSecurity.types';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
  hasGap: (fields: FormFields) => boolean;
};

export function TransportationSecurity({
  form,
  createGoalWithMeasure,
  hasGap,
}: Props): ReactElement {
  const intl = useIntl();

  const transportationSecurityChoice = form.watch(
    'transportationSecurityChoice',
  );
  const showNotes =
    transportationSecurityChoice === TransportationSecurityChoice.Yes;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('transportation');

  const hasGapVal = hasGap(form.getValues());
  useEffect(() => {
    form.setValue(checkedFieldName, hasGapVal, {
      shouldValidate: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGapVal]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="transportationSecurityChoice"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Transportation" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="In the past 12 months, has lack of reliable transportation kept you from medical appointments, work, or from getting things you need for daily living?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
        >
          <Form.Radio value={TransportationSecurityChoice.No}>
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
          <Form.Radio value={TransportationSecurityChoice.Yes}>
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
        </Form.RadioGroup>

        {hasGapVal && (
          <div className={styles.gapMessage}>
            <FormattedMessage defaultMessage="Patient has a transportation need according to CMS guidelines" />
          </div>
        )}
      </div>

      {showNotes && (
        <div className={styles.rightColumn}>
          <GapWidget
            fieldName={descriptionFieldName}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter notes about transportation security',
            })}
            showGoalButton={hasGapVal}
            onCreateGoal={createGoalWithMeasure}
            measure={GoalMeasure.Transportation}
            form={form}
            requireDescription={hasGapVal}
          />
        </div>
      )}
    </>
  );
}
