import omit from 'lodash/omit';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { FormLabel } from '@/deprecated/mui';
import { CounterBox } from '@/shared/common/CounterBox';
import { Option, Select } from '@/shared/common/Select';
import { useFlags } from '@/shared/hooks';
import { usePatientStatusMap } from '@/shared/patient/patientStatus';
import { PatientStatus } from '@/shared/types/patient.types';

import { useTrackerProspectivePatientCountForStatus } from '../../enrollment.queries';
import { formLabel, input } from '../styles.css';
import { dropdownOption } from './EnrollmentStatusFilter.css';

export function EnrollmentStatusFilter({
  status,
  onStatus,
  label = <FormattedMessage defaultMessage="Enrollment status" />,
}: {
  status?: PatientStatus | '';
  onStatus: (status: PatientStatus | '') => void;
  label?: ReactNode;
}) {
  const statusMap = usePatientStatusMap();
  const { petWithGrpc } = useFlags();
  const { pathname } = useLocation();

  // Remove ENROLLED and DISENROLLED from the statusMap object as prospective patients are never in those statuses
  const prospectiveStatusesMap =
    petWithGrpc && pathname.endsWith('/pending-enrollments')
      ? omit(statusMap, [PatientStatus.Enrolled, PatientStatus.Disenrolled])
      : statusMap;

  const { hasPatientsForStatusWithBadge, patientCountByStatusesWithBadge } =
    useBadgeCounts();
  return (
    <>
      <FormLabel className={formLabel} htmlFor="status-selector">
        {label}
      </FormLabel>
      <Select
        id="status-selector"
        className={input}
        defaultValue={status ?? ''}
        onChange={(event) => onStatus(event.target.value as PatientStatus | '')}
        displayEmpty
      >
        <Option value="">
          <FormattedMessage defaultMessage="All" />
        </Option>
        {(
          Object.entries(prospectiveStatusesMap) as Array<
            [PatientStatus, string]
          >
        ).map(([value, statusLabel]) => (
          <Option key={`status-${value}`} value={value}>
            <div className={dropdownOption}>
              <div>{statusLabel}</div>
              {hasPatientsForStatusWithBadge(value) && (
                <CounterBox variant="red">
                  {patientCountByStatusesWithBadge[value]}
                </CounterBox>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </>
  );
}

function useBadgeCounts() {
  const patientCountByStatusesWithBadge = {
    [PatientStatus.NeedsReview]: useTrackerProspectivePatientCountForStatus(
      PatientStatus.NeedsReview,
    ),
  } as Record<PatientStatus, number | undefined>;

  function hasPatientsForStatusWithBadge(status: PatientStatus) {
    return Boolean(patientCountByStatusesWithBadge?.[status]);
  }

  return {
    patientCountByStatusesWithBadge,
    hasPatientsForStatusWithBadge,
  };
}
