import { useRouteMatch } from 'react-router-dom';

import { getProblemList } from '@/pages/adminPanel/patient-enrollment-tracker/ui/shared/icd10CodeUtils';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { lastStructuredChange } from '@/pages/patients/PatientMedications/utils/medChangeUtils';
import { compareMedChanges } from '@/pages/patients/PatientMedications/utils/sortMeds';
import {
  addPsychosocialAssessmentLabels,
  getPreventativeCareGaps,
} from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanDetail/CarePlanPreview/formatters';
import { GRPC_CONDITION_TO_FALCON_CONDITION } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/dataTransformation';
import type {
  CCMCondition,
  CCMPrognosis,
  CarePlan,
  EmergencyVisit,
  FollowUp,
  GeneralAssessmentDiet,
  GoalsAndInterventions,
  MedicationAllergy,
  PreventativeCare,
  PsychosocialAssessment,
  Specialist,
  SurgicalHistory,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import {
  type MedicationChange,
  MedicationChangeStatus,
  type PatientMedications,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useFlags } from '@/shared/hooks';
import { useRouteParamPatientDetails } from '@/shared/hooks/queries';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';

import { useCcmConditionI18n } from '../../../../../tabs/CarePlan/CarePlanForm/conditions';
import { useCcmPrognosisI18n } from '../../../../../tabs/CarePlan/CarePlanForm/prognosis';
import { addPreventativeCareLabels } from '../../../templates/carePlanTemplateHelpers';

export type CarePlanContext = {
  flags: FeatureFlagSet;
  problemList: string[];
  surgicalHistories: SurgicalHistory[];
  specialists: Specialist[];
  emergencyVisits: EmergencyVisit[];
  medsNotReviewed: boolean;
  medAllergies: MedicationAllergy[];
  preventativeCare: (PreventativeCare & {
    label?: string;
  })[];
  preventativeCareGaps: (PreventativeCare & {
    label?: string;
  })[];
  socialHistory: {
    tobaccoUse?: string;
  };
  dietNotes?: string;
  diet: GeneralAssessmentDiet;
  exercise?: string;
  psychosocialAssessments: (PsychosocialAssessment & { label?: string })[];
  disabledPsychosocialAssessments: (PsychosocialAssessment & {
    label?: string;
  })[];
  goalsAndInterventions: (Omit<
    GoalsAndInterventions,
    'condition' | 'prognosis'
  > & {
    condition?: string;
    prognosis?: string;
  })[];
  culturalPreferences?: string;
  culturalCommunityResources?: string;
  followUp: FollowUp;
  medications: Med[];
  identifiedCareGaps: string;
};

type Med = {
  medName: string;
  unstructuredSig?: string;
  isInactive?: boolean;
};

export function useCarePlanTemplateContext(carePlan: Maybe<CarePlan>) {
  const { data: patient } = useRouteParamPatientDetails();
  const { params } = useRouteMatch<{ patientId: string }>();
  const { data: patientMeds } = usePatientMedications(params.patientId);
  const flags = useFlags();

  const ccmConditionI18n = useCcmConditionI18n();
  const ccmPrognosisI18n = useCcmPrognosisI18n();

  const preventativeCare = addPreventativeCareLabels(
    carePlan?.preventativeCare || [],
  );
  const assessments = addPsychosocialAssessmentLabels(
    carePlan?.psychosocialAssessments || [],
  );
  const preventativeCareGaps = getPreventativeCareGaps(preventativeCare);

  const identifiedCareGaps = [
    ...preventativeCareGaps.map(({ label }) => label),
    ...assessments.filter(({ enabled }) => enabled).map(({ label }) => label),
  ].join(', ');

  const templateContext: CarePlanContext = {
    flags,
    problemList: patient ? getProblemList(patient.diagnosis_codes) : [],
    surgicalHistories: carePlan?.surgicalHistories || [],
    specialists: carePlan?.specialists || [],
    emergencyVisits: carePlan?.emergencyVisits || [],
    medsNotReviewed: carePlan?.medsNotReviewed || false,
    medAllergies: carePlan?.medicationAllergies || [],
    preventativeCare,
    preventativeCareGaps,
    socialHistory: {
      tobaccoUse: carePlan?.socialHistory?.tobaccoUse,
    },
    dietNotes: carePlan?.dietNotes,
    diet: carePlan?.diet || {},
    exercise: carePlan?.exercise,
    psychosocialAssessments: assessments,
    disabledPsychosocialAssessments: assessments.filter(
      ({ enabled }) => !enabled,
    ),
    goalsAndInterventions: (carePlan?.goalsAndInterventions || []).map(
      (goal) => ({
        ...goal,
        ...(goal.condition && {
          condition:
            ccmConditionI18n[
              GRPC_CONDITION_TO_FALCON_CONDITION[goal.condition as CCMCondition]
            ],
        }),
        prognosis: ccmPrognosisI18n[goal.prognosis as CCMPrognosis],
      }),
    ),
    culturalPreferences: carePlan?.culturalPreferences,
    culturalCommunityResources: carePlan?.culturalCommunityResources,
    followUp: carePlan?.followUp || {},
    medications: formatMedicationsForTemplate(patientMeds),
    identifiedCareGaps: `Care Gaps Identified: ${identifiedCareGaps}`,
  };
  return templateContext;
}

function getLatestMedChanges(medications: PatientMedications) {
  const unreferencedMeds = medications.unreferencedMedications;
  const referencedMeds = medications.referencedMedications
    ?.map((med) => lastStructuredChange(med))
    .filter((med) => med !== undefined && med !== null);

  return [
    ...(unreferencedMeds || []),
    ...(referencedMeds || []),
  ] as MedicationChange[];
}

function formatMedicationsForTemplate(
  patientMeds: Maybe<PatientMedications>,
): Med[] {
  const medChanges = patientMeds ? getLatestMedChanges(patientMeds) : [];
  const sortedMedChanges = medChanges.sort((a, b) =>
    compareMedChanges(a, b, false),
  );

  return (
    sortedMedChanges
      .map((med) => ({
        medName: med.ehrMedication?.medName,
        unstructuredSig: med.ehrMedication?.unstructuredsig,
        isInactive: med.status === MedicationChangeStatus.INACTIVE,
      }))
      // Only show meds that have an ehrMedication as a simplification since this is just initial med review, so all meds shown should be pulled from the EHR
      .filter(
        (med) =>
          med?.medName !== undefined &&
          med?.medName !== '' &&
          med?.medName !== null,
      ) as Med[]
  );
}
