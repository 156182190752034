/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleLongrunningOperations from "../../../../google/longrunning/operations.pb"
import * as GoogleProtobufEmpty from "../../../../google/protobuf/empty.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "../../../../google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "../../../../google/type/date.pb"
import * as GoogleTypeDatetime from "../../../../google/type/datetime.pb"
import * as GoPmsPkgCare_planCare_plan from "../care_plan/care_plan.pb"
import * as GoPmsPkgNoteNote from "../note/note.pb"
import * as GoPmsPkgPatientMedicationMedication from "../patient/medication/medication.pb"
import * as GoPmsPkgPatientPms from "../patient/pms.pb"
import * as GoPmsPkgPatient_outreachPatient_outreach from "../patient_outreach/patient_outreach.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum State {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  DRAFT = "DRAFT",
  APPROVED = "APPROVED",
  ARCHIVED_APPROVED = "ARCHIVED_APPROVED",
  UNASSIGNED_APPROVAL = "UNASSIGNED_APPROVAL",
  CLINICALDOC_NOT_FOUND = "CLINICALDOC_NOT_FOUND",
}

export enum CCMCondition {
  CCM_CONDITION_UNSPECIFIED = "CCM_CONDITION_UNSPECIFIED",
  CHF = "CHF",
  HYPERTENSION = "HYPERTENSION",
  COPD = "COPD",
  TYPE2_DIABETES = "TYPE2_DIABETES",
  ASTHMA = "ASTHMA",
  AFIB_AND_FLUTTER = "AFIB_AND_FLUTTER",
  CHRONIC_KIDNEY_DISEASE = "CHRONIC_KIDNEY_DISEASE",
  HYPERLIPIDEMIA = "HYPERLIPIDEMIA",
  HYPOTHYROIDISM = "HYPOTHYROIDISM",
  ISCHEMIC_HEART_DISEASE = "ISCHEMIC_HEART_DISEASE",
  MORBID_OBESITY = "MORBID_OBESITY",
  OBSTRUCTIVE_SLEEP_APNEA = "OBSTRUCTIVE_SLEEP_APNEA",
  OSTEOARTHRITIS = "OSTEOARTHRITIS",
  PERIPHERAL_ARTERY_DISEASE = "PERIPHERAL_ARTERY_DISEASE",
  DEMENTIA = "DEMENTIA",
  GENERIC = "GENERIC",
}

export enum CCMPrognosis {
  CCM_PROGNOSIS_UNSPECIFIED = "CCM_PROGNOSIS_UNSPECIFIED",
  GOOD = "GOOD",
  FAIR = "FAIR",
  POOR = "POOR",
}

export type CreateCCMCarePlanRequest = {
  ccmCarePlan?: CCMCarePlan
}

export type GetCCMCarePlanRequest = {
  name?: string
}

export type GetCCMCarePlanNoteInfoRequest = {
  name?: string
}

export type ListCCMCarePlansRequest = {
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type SaveCCMCarePlanRequest = {
  patient?: string
  noteId?: number
  carePlan?: CarePlan
}

export type MarkCCMCarePlanApprovedRequest = {
  name?: string
  approvalDate?: GoogleTypeDatetime.DateTime
  ehrProviderActionNote?: string
}

export type DeleteCCMCarePlanRequest = {
  name?: string
}

export type ListCCMCarePlansResponse = {
  ccmCarePlans?: CCMCarePlan[]
  nextPageToken?: string
  totalSize?: number
}

export type GetCCMCarePlanPdfRequest = {
  name?: string
}

export type SendCCMCarePlanRequest = {
  name?: string
}

export type OperationMetadata = {
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
}

export type CCMCarePlanPdf = {
  pdfUri?: string
}

export type CCMCarePlanSendStatus = {
  letterId?: string
  letterStatus?: GoPmsPkgPatient_outreachPatient_outreach.LobLetterLetterStatus
  sendDate?: GoogleTypeDatetime.DateTime
  expectedDeliveryDate?: GoogleTypeDate.Date
}

export type CCMCarePlan = {
  name?: string
  patientId?: string
  noteId?: number
  approvalDate?: GoogleTypeDatetime.DateTime
  carePlan?: CarePlan
  state?: State
  sends?: GoPmsPkgPatient_outreachPatient_outreach.LobLetter[]
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  ehrProviderActionNote?: string
}

export type CCMCarePlanNoteInfo = {
  name?: string
  patientId?: string
  noteId?: number
  noteEmrSyncTime?: GoogleProtobufTimestamp.Timestamp
  noteStatus?: GoPmsPkgNoteNote.NoteStatus
  noteEmrSyncStatus?: GoPmsPkgNoteNote.NoteNoteSyncStatus
  approvalDate?: GoogleTypeDatetime.DateTime
  carePlan?: CarePlan
  state?: State
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
}

export type CCMCarePlanNoteInfoForPdf = {
  name?: string
  patientId?: string
  noteId?: number
  noteEmrSyncTime?: GoogleTypeDatetime.DateTime
  noteStatus?: GoPmsPkgNoteNote.NoteStatus
  noteEmrSyncStatus?: GoPmsPkgNoteNote.NoteNoteSyncStatus
  approvalDate?: GoogleTypeDatetime.DateTime
  carePlan?: CarePlan
  state?: State
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
}

export type CarePlan = {
  socialHistory?: GeneralAssessmentSocialHistory
  diet?: GeneralAssessmentDiet
  exercise?: string
  medsNotReviewed?: boolean
  medicationAllergies?: MedicationAllergy[]
  emergencyVisits?: EmergencyVisit[]
  goalsAndInterventions?: GoalsAndInterventions[]
  preventativeCare?: PreventativeCare[]
  psychosocialAssessments?: PsychosocialAssessment[]
  followUp?: FollowUp
  surgicalHistories?: SurgicalHistory[]
  specialists?: Specialist[]
  culturalPreferences?: string
  culturalCommunityResources?: string
  psychosocialAssessmentData?: GoPmsPkgCare_planCare_plan.PsychosocialAssessmentData
  dietNotes?: string
}

export type GeneralAssessmentSocialHistory = {
  tobaccoUse?: string
}

export type GeneralAssessmentDiet = {
  fastFood?: string
  snacks?: string
  desserts?: string
  regularSoda?: string
  sweetTea?: string
  waterIntake?: string
  saltIntake?: string
}

export type GeneralAssessment = {
  socialHistory?: GeneralAssessmentSocialHistory
  diet?: GeneralAssessmentDiet
  exercise?: string
  dietNotes?: string
}

export type MedicationAllergy = {
  medication?: string
  reaction?: string
}

export type SurgicalHistory = {
  diagnosis?: string
  details?: string
}

export type Specialist = {
  name?: string
  specialty?: string
  lastVisit?: string
  nextVisit?: string
}

export type EmergencyVisit = {
  hospital?: string
  date?: GoogleTypeDate.Date
  relatedDiagnosis?: string
}

export type GoalsAndInterventions = {
  condition?: CCMCondition
  prognosis?: CCMPrognosis
  treatmentGoal?: string
  healthGoal?: string
  actionSteps?: string
  confidence?: GoogleProtobufWrappers.Int32Value
  coordination?: string
  acceptsCare?: boolean
  prognosisNotes?: string
}

export type PreventativeCare = {
  name?: string
  enabled?: boolean
  date?: string
  nextSteps?: string
  notApplicable?: boolean
  status?: GoPmsPkgCare_planCare_plan.PreventativeCareStatus
}

export type PsychosocialAssessment = {
  name?: string
  enabled?: boolean
  description?: string
}

export type FollowUp = {
  ccmAppointment?: string
  providerAppointment?: string
}

export type CCMCarePlanPdfWorkerArgs = {
  ccmCarePlan?: CCMCarePlanNoteInfoForPdf
  operation?: GoogleLongrunningOperations.Operation
  operationMetadata?: OperationMetadata
  medications?: Medication[]
  patientInfo?: CarePlanPdfPatientInfo
}

export type CCMCarePlanLobWorkerArgs = {
  ccmCarePlan?: CCMCarePlanNoteInfoForPdf
  operation?: GoogleLongrunningOperations.Operation
  operationMetadata?: OperationMetadata
  medications?: Medication[]
  patientInfo?: CarePlanPdfPatientInfo
}

export type Medication = {
  name?: string
  doseQuantities?: number[]
  rxnorm?: GoPmsPkgPatientMedicationMedication.RxNorm
  frequencies?: GoPmsPkgPatientMedicationMedication.MedicationChangeFrequency[]
  unstructuredSig?: string
}

export type DiagnosisCode = {
  code?: string
  codeDescription?: string
  category?: string
  etiology?: string
}

export type CarePlanPdfPatientInfo = {
  id?: string
  givenName?: string
  familyName?: string
  dob?: GoogleTypeDate.Date
  email?: string
  timeZone?: string
  diagnosisCodes?: {[key: string]: DiagnosisCode}
  mobilePhone?: string
  homePhone?: string
  emergencyPhone?: string
  emergencyContactName?: string
  referringProviderFirstName?: string
  referringProviderLastName?: string
  referringProviderRole?: string
  referringProviderStatus?: string
  ccmManagerFirstName?: string
  ccmManagerLastName?: string
  ccmRole?: string
  accommodations?: GoPmsPkgPatientPms.Accommodation[]
  accommodationsOther?: string
  primaryLanguage?: string
}

export type CarePlanTemplateData = {
  patientInfo?: CarePlanPdfPatientInfo
  generalAssessment?: GeneralAssessment
  problemList?: string[]
  medsNotReviewed?: boolean
  medicationAllergies?: MedicationAllergy[]
  medications?: Medication[]
  emergencyVisits?: EmergencyVisit[]
  preventativeCare?: PreventativeCare[]
  psychosocialAssessment?: PsychosocialAssessment[]
  goalsAndInterventions?: GoalsAndInterventions[]
  surgicalHistories?: SurgicalHistory[]
  specialists?: Specialist[]
  followUp?: FollowUp
  ccmPlanApprovedTime?: GoogleTypeDatetime.DateTime
  noteEhrSyncTime?: GoogleTypeDatetime.DateTime
  currentTime?: GoogleTypeDatetime.DateTime
  culturalPreferences?: string
  culturalCommunityResources?: string
}

export class CCMCarePlanService {
  static CreateCCMCarePlan(req: CreateCCMCarePlanRequest, initReq?: fm.InitReq): Promise<CCMCarePlan> {
    return fm.fetchReq<CreateCCMCarePlanRequest, CCMCarePlan>(`/rpm/v1/ccmCarePlan`, {...initReq, method: "POST", body: JSON.stringify(req["ccm_care_plan"], fm.replacer)})
  }
  static GetCCMCarePlan(req: GetCCMCarePlanRequest, initReq?: fm.InitReq): Promise<CCMCarePlan> {
    return fm.fetchReq<GetCCMCarePlanRequest, CCMCarePlan>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCCMCarePlanNoteInfo(req: GetCCMCarePlanNoteInfoRequest, initReq?: fm.InitReq): Promise<CCMCarePlanNoteInfo> {
    return fm.fetchReq<GetCCMCarePlanNoteInfoRequest, CCMCarePlanNoteInfo>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListCCMCarePlans(req: ListCCMCarePlansRequest, initReq?: fm.InitReq): Promise<ListCCMCarePlansResponse> {
    return fm.fetchReq<ListCCMCarePlansRequest, ListCCMCarePlansResponse>(`/rpm/v1/ccmCarePlan?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static SaveCCMCarePlan(req: SaveCCMCarePlanRequest, initReq?: fm.InitReq): Promise<CCMCarePlan> {
    return fm.fetchReq<SaveCCMCarePlanRequest, CCMCarePlan>(`/rpm/v1/${req["patient"]}:saveCcmCarePlan`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static MarkCCMCarePlanApproved(req: MarkCCMCarePlanApprovedRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<MarkCCMCarePlanApprovedRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}:markCcmCarePlanApproved`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteCCMCarePlan(req: DeleteCCMCarePlanRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteCCMCarePlanRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "DELETE"})
  }
  static GenerateCCMCarePlanPdf(req: GetCCMCarePlanPdfRequest, initReq?: fm.InitReq): Promise<GoogleLongrunningOperations.Operation> {
    return fm.fetchReq<GetCCMCarePlanPdfRequest, GoogleLongrunningOperations.Operation>(`/rpm/v1/${req["name"]}/pdf?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCCMCarePlanPdf(req: GoogleLongrunningOperations.GetOperationRequest, initReq?: fm.InitReq): Promise<CCMCarePlanPdf> {
    return fm.fetchReq<GoogleLongrunningOperations.GetOperationRequest, CCMCarePlanPdf>(`/rpm/v1/${req["name"]}/operation?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static SendCCMCarePlan(req: SendCCMCarePlanRequest, initReq?: fm.InitReq): Promise<GoogleLongrunningOperations.Operation> {
    return fm.fetchReq<SendCCMCarePlanRequest, GoogleLongrunningOperations.Operation>(`/rpm/v1/${req["name"]}:send`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetCCMCarePlanSendStatus(req: GoogleLongrunningOperations.GetOperationRequest, initReq?: fm.InitReq): Promise<CCMCarePlanSendStatus> {
    return fm.fetchReq<GoogleLongrunningOperations.GetOperationRequest, CCMCarePlanSendStatus>(`/rpm/v1/${req["name"]}/operation/send?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
}