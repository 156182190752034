import { logger } from '@/logger';

import type {
  EncounterModuleId,
  EncounterModuleInstance,
} from '../../Notes.types';
import { useEncounterModuleInstances } from './useEncounterModuleInstances.hook';

type ResultsGetter<T> = (inputs: T) => {
  error?: string;
  newInputs?: T;
};

export function useUpdateInstance<T>(
  encounterModuleId: EncounterModuleId,
  getResults: ResultsGetter<T>,
) {
  const { onChangeInstance, encounterModuleInstances } =
    useEncounterModuleInstances();

  const instance = encounterModuleInstances.find(
    (i) => i.encounter_module_id === encounterModuleId,
  ) as EncounterModuleInstance<T>;

  return () => {
    if (!instance) {
      logger.error(`Instance not found, id:  ${encounterModuleId}`);
      return 'No instance found';
    }

    const { error, newInputs } = getResults(instance.inputs);
    if (error) {
      return error;
    }

    if (!newInputs) {
      return 'No new inputs';
    }

    const updatedInstance: EncounterModuleInstance<T> = {
      ...instance,
      inputs: newInputs,
    };

    onChangeInstance(updatedInstance as EncounterModuleInstance);
    return undefined;
  };
}
