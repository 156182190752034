<p>1. Has the ordering provider recommended a new care plan? No</p>
<p>2. Has the patient had any significant changes in functional status? *functional status refers to the ability to physically perform activities such as self-care, being mobile, and independence at home or in the community No</p>
<p>3. Does the patient have a desire to change the conditions that are focused in the care plan? No</p>
<p>4. Has the patient been admitted to the hospital at least 3 times in the last 12 months for a disease-specific admission? No</p>
<br>
<p>Is patient currently receiving Home Health services? (If yes, please do not proceed with call and reschedule visit after HH services have ended)</p>
<br>
<p>Is patient eligible for RPM? No (If yes, please pend RPM order if not already pending)</p>
<br>

{{> subheader val="Health Status Update (since last visit)" }}
<br/>
<p><strong>Patient's overall comments on health since last visit:</strong></p>
{{> free_text_space }}
<p><strong>Any recent or upcoming appointments/health interventions:</strong></p>
{{> free_text_space }}

{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{#ifContains programTypes "RPM"}}
{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>
{{/ifContains}}

{{> subheader val="Medication allergies" }}
{{> med_allergies }}

{{> subheader val="Problem List" }}
{{> problem_list }}
<br/>

{{> subheader val="Preventative Maintenance follow-up" }}
<br/>
<p><strong>Pneumovax:</strong> (at or after age 65) </p>
<p><strong>Influenza:</strong> (yearly during flu season) </p>
<p><strong>Tdap:</strong> (every 10 yrs or if injury) </p>
<p><strong>Shingrix:</strong> (adults age 50+) </p>
<p><strong>Colorectal Cancer Screening:</strong> (adults age 45-75) </p>
<p><strong>Breast Cancer Screening:</strong> (women 40 yrs +) </p>
<p><strong>Diabetes Eye Exam:</strong> </p>
<p><strong>Diabetes Foot Exam:</strong> </p>
<br/>
