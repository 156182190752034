import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';

import { ContextualGoalButton } from '../../Goals/ContextualGoalButton';
import { GoalMeasure } from '../../Goals/goals.types';
import {
  type AssessmentName,
  type FormFields,
  getAssessmentFieldNames,
} from '../formConfig';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './PsychosocialAssessment/psychoSocialAssessmentOptions';
import { PsychosocialAssessmentV2 } from './PsychosocialAssessmentV2';
import {
  instructions,
  sectionTitle,
  toggleRowLabel,
} from './carePlanSections.css';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  shouldShowCarePlanV1Changes: boolean;
};

export function PsychosocialAssessment({
  form,
  createGoalWithMeasure,
  shouldShowCarePlanV1Changes,
}: Props) {
  const intl = useIntl();

  return (
    <Form.Section
      title={intl.formatMessage({
        defaultMessage: 'Psychosocial Assessment/Social Determinants of Need',
      })}
      classes={{ title: sectionTitle }}
    >
      {shouldShowCarePlanV1Changes ? (
        <PsychosocialAssessmentV2
          form={form}
          createGoalWithMeasure={createGoalWithMeasure}
        />
      ) : (
        <PsychosocialAssessmentV1
          form={form}
          createGoalWithMeasure={createGoalWithMeasure}
          shouldShowCarePlanV1Changes={shouldShowCarePlanV1Changes}
        />
      )}
    </Form.Section>
  );
}
function PsychosocialAssessmentV1({
  form,
  createGoalWithMeasure,
  shouldShowCarePlanV1Changes,
}: Props) {
  return (
    <>
      <p className={instructions.body}>
        <FormattedMessage
          defaultMessage="Complete the <link>assessment</link> with the patient and then document the results below."
          values={{
            link: (text) => (
              <a
                className={instructions.link}
                href="https://forms.gle/zGUyvZppW53nULwv7"
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
          }}
        />
      </p>
      {PSYCHOSOCIAL_ASSESSMENT_OPTIONS.map(({ name, label }) => (
        <AssessmentRow
          key={name}
          name={name}
          label={label}
          form={form}
          createGoalWithMeasure={createGoalWithMeasure}
          shouldShowCarePlanV1Changes={shouldShowCarePlanV1Changes}
        />
      ))}
    </>
  );
}

function AssessmentRow({
  name,
  label,
  form,
  createGoalWithMeasure,
}: {
  name: AssessmentName;
  label: string;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
} & Props) {
  const intl = useIntl();
  const { checked: checkedFieldName, description: descriptionFieldName } =
    getAssessmentFieldNames(name);
  const checked = form.getValues(checkedFieldName);

  return (
    <Form.Row>
      <Form.Toggle
        required={!!checked}
        name={checkedFieldName}
        label={label}
        labelPlacement="start"
        labelClassName={toggleRowLabel}
        size={6}
        onChange={(isChecked) => {
          if (!isChecked) {
            form.setValue(descriptionFieldName, '');
          }
        }}
      />
      <Form.TextArea
        size={5}
        name={descriptionFieldName}
        placeholder={
          checked
            ? intl.formatMessage({
                defaultMessage: 'Resource provided',
              })
            : undefined
        }
        isDisabled={!checked}
      />
      <ContextualGoalButton
        type="form"
        onPress={() => createGoalWithMeasure(SDOH_GOAL_MEASURE_MAP[name])}
      />
    </Form.Row>
  );
}
const SDOH_GOAL_MEASURE_MAP: Record<AssessmentName, GoalMeasure> = {
  food: GoalMeasure.Food,
  livingSituation: GoalMeasure.LivingSituation,
  physicalActivity: GoalMeasure.PhysicalActivity,
  safety: GoalMeasure.Safety,
  transportation: GoalMeasure.Transportation,
  utilities: GoalMeasure.Utilities,
};
